import { useState } from "react";
import { Flex, Input, Button } from '@chakra-ui/react';
import useSuppliers from "../../hooks/useSuppliers";
import { useRecipeContext } from "../../contexts/RecipeContext";
import EditSupplierModal from "./EditSupplierModal";

const ManageSuppliers = () => {
  
  const { suppliers } = useRecipeContext();
  const { confirmDeleteSupplier, isEditing, selectedSupplier, setSelectedSupplier, openSupplierModal, closeSupplierModal } = useSuppliers();


  const [searchSuppliers, setSearchSuppliers] = useState('');
  
  const filteredSuppliers = suppliers.filter(supplier => 
    supplier.name.toLowerCase().includes(searchSuppliers.toLowerCase())
  );

  const deleteSupplier = (supplierId) => {
    confirmDeleteSupplier(supplierId);
    setSelectedSupplier(null);
  };
  
    

  return (
    <Flex direction="column" className="widget">
        <Flex className="widgetTitle" justifyContent="space-between">
			<h5>Suppliers</h5>
			<Button
                            size="xs"
                            className="btn secondary"
                            onClick={openSupplierModal}>
                            Add supplier
        	</Button>
			</Flex>
      
      <Flex direction="column" className="widgetBody" gap={4}>
      <Input
        type="text"
        placeholder="Search suppliers..."
        value={searchSuppliers}
        onChange={(e) => setSearchSuppliers(e.target.value)}
        className="search-input"
      />
        <Flex direction="row" flexWrap="wrap" className="tagItems">
      {filteredSuppliers?.length > 0 ? (
        filteredSuppliers.map((supplier) => (
          <Flex direction="row" key={supplier.id} onClick={() => openSupplierModal(supplier)} 
          cursor="pointer" 
          color="black"
          bg="white" 
          className="tag"
          border="1px solid var(--borders)"
          fontSize="14px" whiteSpace="nowrap" >
                {supplier.name}
          </Flex>
        ))
      ) : (
        'No suppliers found.'
      )}

            {selectedSupplier && (
              <EditSupplierModal
                supplier={selectedSupplier}
                isOpen={!!selectedSupplier || !isEditing}
                onClose={closeSupplierModal}
                onDelete={() => deleteSupplier(selectedSupplier.id)}
                isEditing={isEditing}
              />
            )}


  
      </Flex>
    </Flex>
    </Flex>
  );
};

export default ManageSuppliers;
