import { useState, useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import { Flex, Box, Image, Text, Button, Textarea, Input, Table, Thead, Tr, Td, Tbody, Tfoot } from "@chakra-ui/react";
import { useRecipeContext } from "../../contexts/RecipeContext";
import Select from "react-select";
import { X, Trash} from "lucide-react";
import { useDropzone } from "react-dropzone";
import useRecipes from "../../hooks/useRecipes";

Modal.setAppElement("#root");

const RecipeModal = ({ currentRecipe, setCurrentRecipe, onClose, isOpen, isEditing }) => {
  const { customStyles } = useModal();
  
  const { mainLanguage } =  useAppContext();
  const { ingredients, selectedIngredients, setSelectedIngredients } = useRecipeContext();
  const [recipeHasChanges, setRecipeHasChanges] = useState(false);
  const [instructions, setInstructions] = useState('');
  const [image, setImage] = useState(null);
  const { calculateTotalPrice, saveRecipe, deleteImage } = useRecipes({ instructions, image, setImage, currentRecipe });

  console.log("currentRecipe", currentRecipe)



  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {;
      const file = acceptedFiles[0];
      setImage(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    },
  });
  useEffect(
    () => () => {
      if (image) URL.revokeObjectURL(image.preview);
    },
    [image]
  );

  useEffect(() => {
    setRecipeHasChanges(true);
  }, [selectedIngredients, image]); // Dépendances du useEffect
  
  useEffect(() => {
    if (isOpen) {
      setInstructions('');
      setImage(null);
      setSelectedIngredients([{ ingredientId: "", quantity: "", calculatedPrice: "0.00" }]);
  
      if (currentRecipe && ingredients) {
        let recipeData;
        if (currentRecipe?.recipes && currentRecipe.variationId) {
          recipeData = currentRecipe.recipes.find(r => r.variationId === currentRecipe.variationId);
        } else {
          recipeData = currentRecipe?.recipes?.[0] ?? null;
        }
        
        if (recipeData) {
          setInstructions(recipeData.instructions || "");
          setImage(recipeData.photo ? { preview: recipeData.photo } : null);
          const initIngredients = recipeData.ingredients.map(ing => {
            const ingredientDetail = ingredients.find(ingredient => ingredient.id === ing.ingredientId);
            return {
              ...ing,
              calculatedPrice: ingredientDetail ? ((ing.quantity * parseFloat(ingredientDetail.price)) / 1000).toFixed(2) : "0.00"
            };
          });
          setSelectedIngredients(initIngredients.length > 0 ? initIngredients : [{ ingredientId: "", quantity: "", calculatedPrice: "0.00" }]);
        } else {
          // Si aucune recette n'est trouvée, réinitialiser les ingrédients sélectionnés
          setSelectedIngredients([{ ingredientId: "", quantity: "", calculatedPrice: "0.00" }]);
        }
      }
      setRecipeHasChanges(false);
    }
  }, [isOpen, currentRecipe, ingredients, setSelectedIngredients]);
  
  
  
  
  
  
  

  
const addIngredientLine = () => {
    setSelectedIngredients([
        ...selectedIngredients,
        { ingredientId: "", quantity: "", calculatedPrice: "0.00" },
    ]);
};


  const calculatePrice = (index, quantity) => {
  const ingredientId = selectedIngredients[index].ingredientId;
  const ingredient = ingredients.find((ing) => ing.id === ingredientId);
  if (!ingredient || !quantity) return;
  const pricePerKg = parseFloat(ingredient.price);
  const pricePerGram = pricePerKg / 1000;
  const quantityInGrams = parseFloat(quantity);
  const totalPrice = pricePerGram * quantityInGrams;
  const newIngredients = [...selectedIngredients];
  newIngredients[index].calculatedPrice = totalPrice.toFixed(2);
  setSelectedIngredients(newIngredients);
  };


const handleIngredientChange = (index, field, value) => {
  const newIngredients = [...selectedIngredients];
  if (newIngredients[index]) {
      newIngredients[index][field] = value;
      setSelectedIngredients(newIngredients);
      
      if (field === "quantity" || field === "ingredientId") {
        const quantity = field === "quantity" ? value : newIngredients[index].quantity;
        calculatePrice(index, quantity);
      }

      // Ajouter une nouvelle ligne si l'utilisateur commence à saisir dans le dernier ingrédient
      if (index === selectedIngredients.length - 1 && (field === "ingredientId" && value)) {
          addIngredientLine();
      }
  }

  setRecipeHasChanges(true);
};


  const removeIngredient = (index) => {
    const newIngredients = [...selectedIngredients];
    newIngredients.splice(index, 1);
    setSelectedIngredients(newIngredients);
  };

 


  const handleSubmit = (e) => {
    e.preventDefault();
    saveRecipe();
    onClose(); 
  };

  useEffect(() => {
    if (currentRecipe) {
      setCurrentRecipe(currentRecipe);
    }
  }, [currentRecipe, setCurrentRecipe]);
  
  

  if (!isOpen) return null;


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={"modal"}
      style={customStyles}>
      <Flex className="modal-content" w="960px">
        <div className="modalTopBar">
          <div className="closeModal">
            <span className="close" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
          <div className="modalTitle">
            {isEditing ? `Edit ${currentRecipe[`name_${mainLanguage}`]}` : "Add recipe"}
          </div>
          <div className="actionButtons">
            {recipeHasChanges && (
              <button type="submit" className="simple" onClick={handleSubmit}>
                {isEditing ? "Save changes" : "Save"}
              </button>
            )}
          </div>
        </div>

        <Flex
          className="modalInner"
          direction={{ desktop: "row", mobile: "column" }}
          gap={12}>
          <Flex direction="column" flex={1} gap={8}>
            
            <h4>{currentRecipe[`name_${mainLanguage}`]}</h4>
            <Flex direction="column" gap={2}>
              <h6>Photos</h6>
            <Flex direction="column" {...getRootProps()} border="2px dashed var(--brand)" p={4} gap={2} borderRadius={8}>
              <Input {...getInputProps()} />
              {image ? (
                  <Flex alignItems="center" gap={2} >
                  <Box position="relative">
                    <Image
                      src={image.preview}
                      borderRadius={8}
                      objectFit="cover"
                      aspectRatio="1/1"
                  />
                  <Button size="xs" 
                    onClick={(event) => { event.stopPropagation(); deleteImage();}}  position="absolute" top={2} right={2}><Trash size={14} /></Button>
                  </Box>
                </Flex> ) : (
                <Text textAlign="center" fontSize="13px">Drag 'n' drop an image file or click to select a file</Text>
              )}
            </Flex>
            </Flex>
            <Flex direction="column" gap={2}>
              <h6>Instructions</h6>
            <Textarea
            flex="initial"
              h="200px"
              name="instructions"
              id="instructions"
              value={instructions} 
              onChange={(e) => setInstructions(e.target.value)}
              placeholder="Recipe instructions"
            />
            </Flex>
          </Flex>

          <Flex direction="column" flex={1} gap={4}>
            <h6>Cost price</h6>
            
            <Table fontSize="15px">
            <Thead>
              <Tr fontSize="13px" color="var(--grey400)">
                <Td p={1}>Ingredient</Td>
                <Td p={1}>Qty (gr)</Td>
                <Td p={1}>Cost</Td>
                <Td p={1}></Td>
              </Tr>
            </Thead>
            <Tbody>
            {selectedIngredients.map((ingredient, index) => (
              <Tr>
                <Td w="50%" p={1} fontWeight="500">
                  <Select
                  className="ingSelectContainer"
                  classNamePrefix="guddenSelect"
                    value={
                      selectedIngredients[index].ingredientId
                        ? {
                            value: selectedIngredients[index].ingredientId,
                            label: ingredients.find(
                              (ing) =>
                                ing.id ===
                                selectedIngredients[index].ingredientId
                            )?.name,
                          }
                        : null
                    }
                    onChange={(selectedOption) =>
                      handleIngredientChange(
                        index,
                        "ingredientId",
                        selectedOption ? selectedOption.value : ""
                      )
                    }
                    options={ingredients.map((ing) => ({
                      value: ing.id,
                      label: ing.name,
                    }))}
                    isSearchable={true}
                    placeholder="Select ingredient"
                  />
                </Td>
                <Td p={1}>
          
                  <Input
                    w="68px"
                    type="number"
                    placeholder="Qty (gr)"
                    value={ingredient.quantity}
                    onChange={(e) =>
                      handleIngredientChange(index, "quantity", e.target.value)
                    }
                  />
                </Td>

                  <Td p={1}>
                    {selectedIngredients[index].calculatedPrice || "0,00"}€
                  </Td>
                  <Td p={1}>
                  {ingredient.ingredientId && (
                  <Button size="xs" onClick={() => removeIngredient(index)}>
                    <X size={16} />
                  </Button>
                  )}
                  </Td>
              </Tr>
            ))}
           
            </Tbody>
            <Tfoot >
            <Tr>
                <Td p={1}></Td>
                <Td p={1} color="var(--grey500)" textAlign="right">Total</Td>
                <Td pl={1} pr={1} fontWeight="600" color="black">{calculateTotalPrice()}€</Td>
                <Td p={1}></Td>
              </Tr>
            </Tfoot>
           </Table>
          </Flex>
        </Flex>

       
      </Flex>
    </Modal>
  );
};

export default RecipeModal;
