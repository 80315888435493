import React, { useState, useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import { Tooltip } from 'react-tooltip';
import { Flex, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, Button } from "@chakra-ui/react";
import { CircleHelp } from 'lucide-react';

Modal.setAppElement("#root");

const SubCategoryModal = ({
  isOpen,
  onDelete,
  onClose,
  category,
  onSave,
  isEditing,
  subCategoryName,
  setSubCategoryName,
  subCategoryDescription,
  setSubCategoryDescription,
  setSubCategoryStyle,
  subCategoryStyle,
  subCategoryFormula,
  setSubCategoryFormula,
  subCategoryFormulaPrice,
  setSubCategoryFormulaPrice,
  subCategoryPrintPageBreak,
  setSubCategoryPrintPageBreak,
  subCategoryPrintColumns,
  setSubCategoryPrintColumns,
}) => {
  const { toggleSubMenu, openSubMenu, closeSubMenu, showOverlay, settings } = useAppContext();
  const [subCategoryHasChanges, setSubCategoryHasChanges] = useState(false);
  const { customStyles } = useModal();
  
  const [selectedLanguage, setSelectedLanguage] = useState(settings.language[0].value); // Initial language for name

  const handleNameChange = (lang, value) => {
    setSubCategoryName((prev) => ({ ...prev, [lang]: value }));
    setSubCategoryHasChanges(true);
  };

  const handleDescriptionChange = (lang, value) => {
    setSubCategoryDescription((prev) => ({ ...prev, [lang]: value }));
    setSubCategoryHasChanges(true);
  };

  const handleStyleChange = (e) => {
    setSubCategoryStyle(e.target.value);
    setSubCategoryHasChanges(true);
  };

  const handleFormulaChange = (e) => {
    setSubCategoryFormula(e.target.value);
    setSubCategoryHasChanges(true);
  };

  const handleFormulaPriceChange = (e) => {
    setSubCategoryFormulaPrice(e.target.value);
    setSubCategoryHasChanges(true);
  };

  const handlePageBreakChange = (e) => {
    setSubCategoryPrintPageBreak(e.target.value);
    setSubCategoryHasChanges(true);
  };

  const handleColumnsChange = (e) => {
    setSubCategoryPrintColumns(e.target.value);
    setSubCategoryHasChanges(true);
  };

  const handleDelete = () => {
    if (category && window.confirm("Are you sure you want to delete this category?")) {
      onDelete(category);
      onClose(); 
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (isEditing && category) {
        const initialNameState = {};
        const initialDescriptionState = {};
        const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
        allLanguages.forEach((lang) => {
          const nameKey = `name_${lang.value}`;
          const descriptionKey = `description_${lang.value}`;
          initialNameState[lang.value] = category[nameKey] || "";
          initialDescriptionState[lang.value] = category[descriptionKey] || "";
        });

        setSubCategoryName(initialNameState);
        setSubCategoryDescription(initialDescriptionState);
        setSubCategoryStyle(category.style || "List");
        setSubCategoryFormula(category.formula ? "yes" : "no");
        setSubCategoryFormulaPrice(category.formulaPrice ? String(category.formulaPrice) : "");
        setSubCategoryPrintPageBreak(category.pageBreak || "none");
        setSubCategoryPrintColumns(category?.columns || "1");
      } else {
        setSubCategoryName({});
        setSubCategoryDescription({});
        setSubCategoryStyle("List");
        setSubCategoryFormula("no");
        setSubCategoryFormulaPrice("");
        setSubCategoryPrintColumns("1");
      }
      setSubCategoryHasChanges(false);
    }
  }, [isOpen, isEditing, category, settings, setSubCategoryName, setSubCategoryDescription, setSubCategoryStyle, setSubCategoryFormula, setSubCategoryFormulaPrice, setSubCategoryPrintPageBreak, setSubCategoryPrintColumns]);

  const renderNameFields = () => {
    const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
    return (
      <Flex className="field" gap={2}>
        <Flex justifyContent={"space-between"} alignItems={"flex-end"}>
          <label>Name</label>
          <Flex className="languageButtons" gap={1}>
            {allLanguages.map((lang) => (
              <Button key={lang.value} onClick={() => setSelectedLanguage(lang.value)} p={0} height="unset" minWidth="unset" borderRadius={"16px"} overflow={"hidden"} className={selectedLanguage === lang.value ? "active" : ""} opacity='.4' title={lang.label}>
                <img src={`/images/flags/${lang.value}.svg`} alt={lang.label} width="18px" height="18px" />
              </Button>
            ))}
          </Flex>
        </Flex>
        <input
          type="text"
          name={`name_${selectedLanguage}`}
          id={`name_${selectedLanguage}`}
          className="nameInput"
          placeholder={`Category name (${selectedLanguage})`}
          value={subCategoryName[selectedLanguage] || ""}
          onChange={(e) => handleNameChange(selectedLanguage, e.target.value)}
        />
      </Flex>
    );
  };

  const renderDescriptionFields = () => {
    const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
    return (
      <Flex className="field" gap={2}>
        <Flex justifyContent={"space-between"} alignItems={"flex-end"}>
          <label>Description</label>
          <Flex className="languageButtons" gap={1}>
            {allLanguages.map((lang) => (
              <Button key={lang.value} onClick={() => setSelectedLanguage(lang.value)} p={0} height="unset" minWidth="unset" borderRadius={"16px"} overflow={"hidden"} className={selectedLanguage === lang.value ? "active" : ""} opacity='.4' title={lang.label}>
                <img src={`/images/flags/${lang.value}.svg`} alt={lang.label} width="18px" height="18px" />
              </Button>
            ))}
          </Flex>
        </Flex>
        <textarea
          name={`description_${selectedLanguage}`}
          id={`description_${selectedLanguage}`}
          className="descriptionInput"
          placeholder={`Category description (${selectedLanguage})`}
          value={subCategoryDescription[selectedLanguage] || ""}
          onChange={(e) => handleDescriptionChange(selectedLanguage, e.target.value)}
        />
      </Flex>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={"modal"}
      style={customStyles}>
      <div className="modal-content">
        <form onSubmit={onSave}>
          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <div className="modalTitle">
              {isEditing ? "Edit category" : "Add category"}
            </div>

            <div className="actionButtons">
              {subCategoryHasChanges && (
                <button type="submit" className="simple">
                  {isEditing ? "Save changes" : "Save"}
                </button>
              )}

              {isEditing && (
                <div
                  className="btn simple itemActions subMenuToggle"
                  onClick={toggleSubMenu("subCategoryActions")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z"
                      fill="black"
                    />
                  </svg>

                  {openSubMenu === "subCategoryActions" && (
                    <div className="submenu">
                      <ul>
                        <li
                          onClick={() => category && handleDelete(category)}
                          style={{ color: "var(--error)" }}>
                          Delete category
                        </li>
                      </ul>
                      <button onClick={closeSubMenu} className="closeSubmenu">
                        Cancel
                      </button>
                    </div>
                  )}
                  {showOverlay && openSubMenu === "subCategoryActions" && (
                    <div className="overlay" onClick={closeSubMenu}></div>
                  )}
                </div>
              )}
            </div>
          </div>

          <Tabs variant='unstyled' >
            <TabList bg="white" borderBottom={"1px solid var(--borders)"}>
              <Tab color={"var(--grey500)"} _selected={{ color: 'var(--brand)', pointerEvents: 'none' }} _hover={{ color: 'var(--grey700)' }}>Info</Tab>
              <Tab color={"var(--grey500)"} _selected={{ color: 'var(--brand)', pointerEvents: 'none' }} _hover={{ color: 'var(--grey700)' }}>Style</Tab>
              <Tab color={"var(--grey500)"} _selected={{ color: 'var(--brand)', pointerEvents: 'none' }} _hover={{ color: 'var(--grey700)' }}>Print</Tab>
            </TabList>

            <TabIndicator mt='-1.5px' height='2px' bg='var(--brand)' borderRadius='1px' />


            <Flex className="modalInner">
              <TabPanels>
                <TabPanel>
                  <Flex direction="column" gap={4}>
                    {renderNameFields()}
                    {renderDescriptionFields()}
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Flex direction="column" gap={4}>
                    <div className="field">
                      <label>Style</label>
                      <select value={subCategoryStyle} onChange={handleStyleChange}>
                        <option value="List">List</option>
                        <option value="Cards">Cards</option>
                      </select>
                    </div>
                    <div className="field">
                      <label>Formule</label>
                      <select value={subCategoryFormula} onChange={handleFormulaChange}>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                      </select>
                    </div>
                    {subCategoryFormula === "yes" && (
                      <div className="field">
                        <label>Formula price</label>
                        <input
                          type="number"
                          value={subCategoryFormulaPrice}
                          onChange={handleFormulaPriceChange}
                          placeholder="Enter price"
                        />
                      </div>
                    )}
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Flex direction="column" gap={4}>
                    <div className="field">
                      <Flex direction="row" gap={2}>
                        <label>Page break</label>
                        <CircleHelp color='var(--grey500)' size={16} data-tooltip-id="pageBreakTooltip" data-tooltip-content="To force page break before the category, choose Before." data-tooltip-place="top" />  
                        <Tooltip id="pageBreakTooltip" className="toolTip"></Tooltip>
                      </Flex>
                      <select value={subCategoryPrintPageBreak} onChange={handlePageBreakChange}>
                        <option value="none">None</option>
                        <option value="before">Before</option>
                      </select>
                    </div>
                    <div className="field">
                      <label>Columns</label>
                      <select value={subCategoryPrintColumns} onChange={handleColumnsChange}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </select>
                    </div>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Flex>
          </Tabs>
        </form>
      </div>
    </Modal>
  );
};

export default SubCategoryModal;
