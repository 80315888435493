import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import Menu from "./pages/Menu";
import Orders from "./pages/Orders";
import Customers from "./pages/Customers";
import Recipes from "./pages/Recipes";
import Settings from "./pages/Settings";
import { AppProvider } from "./contexts/AppContext";
import UserAuthHandler from "./services/userAuthHandler";
import "./App.css";
import Spinner from "./components/icons/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { auth, db } from "./firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import BackendLayout from "./pages/BackendLayout";
import ShopLayout from "./pages/ShopLayout";
import Invoicing from "./pages/Invoicing";
import { SelectedRestaurantProvider } from "./contexts/SelectedRestaurantContext";
import useBreakpoints from "./hooks/useBreakpoints";
import MenuLayout from "./pages/MenuLayout";
import RecipesLayout from "./pages/RecipesLayout";
import Stock from "./pages/Stock";
import Dashboard from "./pages/Dashboard";
import Reservations from "./pages/Reservations";
import ReservationsLayout from "./pages/ReservationsLayout";
import TablePlanProvider from "./components/reservations/TablePlanProvider";

function App() {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const { theme } = useBreakpoints();

  const getUserRole = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        return userDoc.data().role;
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      const role = await getUserRole();
      setUserRole(role);
    };

    if (isLoggedIn) {
      fetchUserRole();
    }
  }, [isLoggedIn]);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  /*
  
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    window.addEventListener("load", () => {
      navigator.serviceWorker.register("/firebase-messaging-sw.js").then(
        (registration) => {
          console.log("Service Worker enregistré avec succès : ", registration.scope);
        },
        (err) => {
          console.log("L'enregistrement du Service Worker a échoué : ", err);
        }
      );
    });
  } else {
    console.log("Ce navigateur ne supporte pas les notifications push ou les service workers.");
  }
  */


  

  return (
    <ChakraProvider theme={theme}>
      <AppProvider userRole={userRole} setUserRole={setUserRole}>
        <Router>
          <SelectedRestaurantProvider>
            <div className="App">
              <UserAuthHandler
                setIsLoggedIn={setIsLoggedIn}
                setLoading={setLoading}
              />

              <ToastContainer
                autoClose={3000}
                hideProgressBar={true}
                closeOnClick
                theme="colored"
                position="bottom-right"
              />
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <Routes>
                    <Route
                      path="/login"
                      element={<Login onLoginSuccess={handleLoginSuccess} />}
                    />
                    <Route
                      path="/"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <MenuLayout>
                              <Menu />
                            </MenuLayout>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                  <Route
                      path="/:restaurantId/dashboard"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <Dashboard />
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                    <Route
                      path="/:restaurantId"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <MenuLayout>
                              <Menu />
                            </MenuLayout>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                    <Route
                      path="/:restaurantId/reservations"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <ReservationsLayout>
                              <Reservations />
                            </ReservationsLayout>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                    <Route
                      path="/:restaurantId/reservations/tableplan"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <ReservationsLayout>
                              <TablePlanProvider />
                            </ReservationsLayout>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                    <Route
                      path="/:restaurantId/shop/orders"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <ShopLayout>
                              <Orders />
                            </ShopLayout>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                    <Route
                      path="/:restaurantId/shop/customers"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <ShopLayout>
                              <Customers />
                            </ShopLayout>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                    <Route
                      path="/:restaurantId/shop/invoicing"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <ShopLayout>
                              <Invoicing />
                            </ShopLayout>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                    <Route
                      path="/:restaurantId/recipes"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <RecipesLayout>
                            <Recipes />
                            </RecipesLayout>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                    <Route
                      path="/:restaurantId/recipes/stock"
                      element={
                        isLoggedIn ? (
                          <BackendLayout>
                            <RecipesLayout>
                            <Stock />
                            </RecipesLayout>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />

                    <Route
                      path="/:restaurantId/settings"
                      element={
                        isLoggedIn && userRole === "superAdmin" ? (
                          <BackendLayout>
                            <ProtectedRoute
                              isAllowed={
                                isLoggedIn && userRole === "superAdmin"
                              }
                              redirectPath="/">
                              <Settings userRole={userRole} />
                            </ProtectedRoute>
                          </BackendLayout>
                        ) : (
                          <Navigate replace to="/login" />
                        )
                      }
                    />
                  </Routes>
                </>
              )}
            </div>
          </SelectedRestaurantProvider>
        </Router>
      </AppProvider>
    </ChakraProvider>
  );
}

export default App;
