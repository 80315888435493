import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAppContext } from '../contexts/AppContext';

const UserAuthHandler = ({ setIsLoggedIn, setLoading }) => {
  const { setSelectedRestaurantId } = useAppContext();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if (userData.role === 'restaurantOwner') {
            setSelectedRestaurantId(userData.restaurantId);
          }

          await updateDoc(userDocRef, {
            lastLogin: new Date()
          });
        }

        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false);
    });
  }, [setSelectedRestaurantId, setIsLoggedIn, setLoading]);

  return null;
};

export default UserAuthHandler;
