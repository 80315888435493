import { useAppContext } from "../../contexts/AppContext";
import useLogout from "../../hooks/useLogout";
import { Flex, Link } from "@chakra-ui/react";


function UserProfile () {
    const {
        openSubMenu,
        showOverlay,
        toggleSubMenu,
        closeSubMenu,
        userData,
      } = useAppContext();
    const handleLogout = useLogout();



  return (
    <>
    <Link onClick={toggleSubMenu("profileDesktopActions")} className="userProfile">
    <Flex direction="row" alignItems="center" gap={2} className="profileImage userProfileImage" >
              {userData.imageUrl ? (
                <img src={userData.imageUrl} alt={userData.name} />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                  role="presentation"
                  focusable="false"
                  style={{
                    display: "block",
                    height: "100%",
                    width: "100%",
                    fill: "var(--grey500)",
                  }}>
                  <path d="M16 .7C7.56.7.7 7.56.7 16S7.56 31.3 16 31.3 31.3 24.44 31.3 16 24.44.7 16 .7zm0 28c-4.02 0-7.6-1.88-9.93-4.81a12.43 12.43 0 0 1 6.45-4.4A6.5 6.5 0 0 1 9.5 14a6.5 6.5 0 0 1 13 0 6.51 6.51 0 0 1-3.02 5.5 12.42 12.42 0 0 1 6.45 4.4A12.67 12.67 0 0 1 16 28.7z"></path>
                </svg>
              )}

            {openSubMenu === "profileDesktopActions" && (
            <div className="submenu">
              <ul>
                <li>Profile</li>
                
                <div className="separator"></div>
                <li onClick={handleLogout} className="logout">
                  Logout
                </li>
              </ul>
              <button onClick={closeSubMenu} className="closeSubmenu">
                Cancel
              </button>
            </div>
          )}
                    </Flex>
          </Link>


          {showOverlay && openSubMenu === "profileDesktopActions" && (
            <div className="overlay" onClick={closeSubMenu}></div>
          )}
    </>

  );
}

export default UserProfile;
