import React, { useState, useEffect} from 'react';
import Select from 'react-select';
import { Flex, useDisclosure } from '@chakra-ui/react';
import CancellationModal from './CancellationModal';
import useOrders from '../../hooks/useOrders';

const StatusSelector = ({ orderId, currentStatus, onUpdateStatus, deliveryMode }) => {
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedValue, setSelectedValue] = useState(null);
    const { cancellationNote, setCancellationNote } = useOrders();
    
    const allOptions = [
        { value: 'cancelled', label: 'Cancelled', color: 'var(--error)' },
        { value: 'pending', label: 'Pending', color: 'var(--warning)' },
        { value: 'confirmed', label: 'Processing', color: 'var(--orange)' },
        { value: 'transit', label: 'Sent', color: 'var(--blue400)' },
        { value: 'completed', label: 'Completed', color: 'var(--success)' },
    ];

    const options = deliveryMode === 'pickup' 
        ? allOptions.filter(option => option.value !== 'transit') 
        : allOptions;


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
    };

    const formatOptionLabel = ({ label, color }) => (
        <Flex className='statusLabel' direction="row" backgroundColor={color} color="white" pr={2} pl={2} pt={1} pb={1} borderRadius={8} fontSize={13} fontWeight="500" textTransform="initial" whiteSpace="nowrap">
            {label}
        </Flex>
    );
    
    const handleChange = selectedOption => {
        if (selectedOption.value === 'cancelled') {
            onOpen();
        } else {
            onUpdateStatus(orderId, selectedOption.value);
        }
    };

    const handleConfirmCancellation = () => {
        onUpdateStatus(orderId, 'cancelled', null, cancellationNote); // Ajout de la cancellationNote comme paramètre distinct
        setCancellationNote(''); 
        onClose();
    };
    


    useEffect(() => {
        setSelectedValue(options.find(option => option.value === currentStatus));
    }, [currentStatus, options ]);
      


    return (
       <> <Select
            value={selectedValue}
            onChange={handleChange}
            options={options}
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
            className="statusSelectorContainer"
            classNamePrefix="statusSelector"
            isSearchable={false}
        />
        <CancellationModal 
            isOpen={isOpen} 
            onClose={onClose}
            cancellationNote={cancellationNote}
            setCancellationNote={setCancellationNote}
            handleConfirmCancellation={handleConfirmCancellation}
        />
        
    
        </>
    );
};

export default StatusSelector;
