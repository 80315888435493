// useCategoryData.js

import { useState } from "react";
import { db } from "../firebaseConfig";
import {  updateDoc, doc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from 'react-toastify';




const useUsers = (selectedRestaurantId) => {
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(null); 
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const functions = getFunctions();
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const [userHasChanges, setUserHasChanges] = useState(false);

    
    const addUserFromCloud = async (newUserData) => {
        setIsAddingUser(true);
        const addUserFunction = httpsCallable(functions, 'addUser');

        try {
            await addUserFunction({
                email: newUserData.email,
                password: newUserData.password,
                name: newUserData.name,
                lastName: newUserData.lastName,
                restaurantIds: newUserData.restaurantIds
            });
            setIsUserModalOpen(false);
            setIsAddingUser(false);
			toast.success('User added successfully');
        } catch (error) {
            console.error("Error adding user:", error);
            setIsAddingUser(false);
			toast.error('Error adding user');
        }
    };

    const handleUserEdit = (user) => {
        setCurrentUser(user);
        setIsUserModalOpen(true);
    };
    

    const handleSaveUser = async (editedUser) => {
        const userRef = doc(db, 'users', editedUser.id);
        await updateDoc(userRef, {
            ...editedUser,
            restaurantIds: editedUser.restaurantIds || [] // Assurez-vous de gérer les restaurantIds correctement
        });
        setUsers(users.map(u => u.id === editedUser.id ? editedUser : u));
        setIsUserModalOpen(false); // Fermeture du modal après la sauvegarde
    };
    

    const deleteUserFromCloud = async (userId) => {
        setIsDeletingUser(true);
        const functions = getFunctions();
        const deleteUserFunction = httpsCallable(functions, 'deleteUser');
    
        try {
            await deleteUserFunction({ uid: userId });
            setUsers(users.filter(user => user.id !== userId));
            setIsUserModalOpen(false);
            setIsDeletingUser(false);
			toast.success('User deleted successfully');
        } catch (error) {
            console.error("Error deleting user with cloud function:", error);
            setIsDeletingUser(false);
			toast.error('Error deleting user');
        }
    };

    const closeUserModal = () => {
        setIsUserModalOpen(false);
        setUserHasChanges(false);
      };

    
    
    

	return {
	
        users,
        setUsers,
        currentUser,
        setCurrentUser,
        isUserModalOpen,
        addUserFromCloud,
        closeUserModal,
        deleteUserFromCloud,
        handleUserEdit,
        handleSaveUser,
        setIsUserModalOpen,
        isAddingUser,
        setIsAddingUser,
        isDeletingUser,
        setIsDeletingUser,
        userHasChanges,
        setUserHasChanges
	};
};

export default useUsers;
