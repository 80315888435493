import React, { useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import { Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from "@chakra-ui/react";
import ModalTopBar from "../modal/ModalTopBar";

Modal.setAppElement("#root");

const StripeKeysModal = ({ isOpen, onClose, onChange, onSave }) => {
  const { customStyles } = useModal();
  const { selectedRestaurantId, stripeKeys, setStripeKeys, loadStripeKeys } = useAppContext();

  const handleStripeKeyChange = (event) => {
    const { name, value } = event.target;
    setStripeKeys((prevKeys) => ({
        ...prevKeys,
        [name]: value, // Met à jour la clé Stripe correspondante
    }));
  };


  const submitStripeKeys = (event) => {
  event.preventDefault();
  onSave();
  };

  useEffect(() => {
    if (isOpen) {
      loadStripeKeys();
    }
  }, [isOpen, selectedRestaurantId]);


  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={"modal"}
      style={customStyles}>
      <div className="modal-content">
        <form>
          <ModalTopBar title="Set up Stripe API keys" onClose={onClose} onSave={submitStripeKeys} />
            <Tabs variant="unstyled">
              <TabList>
                <Tab>Live</Tab>
                <Tab>Test</Tab>
              </TabList>
              <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="var(--brand)"
              borderRadius="1px"
            />

              <div className="modalInner">
              <TabPanels>
                <TabPanel>
                  <div className="field">
                    <label htmlFor="PublicLive">
                      Stripe API Publishable Live key
                    </label>
                    <input
                      type="password"
                      name="PublicLive"
                      value={stripeKeys.PublicLive || ""}
                      onChange={handleStripeKeyChange}
                      placeholder="Enter your Stripe publishable Live key"
                      required
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="SecretLive">
                      Stripe API Secret Live Key
                    </label>
                    <input
                      type="password"
                      name="SecretLive"
                      value={stripeKeys.SecretLive || ""}
                      onChange={handleStripeKeyChange}
                      placeholder="Enter your Stripe secret Live key"
                      required
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="field">
                    <label htmlFor="PublicTest">
                      Stripe API Publishable Key Test
                    </label>
                    <input
                      type="password"
                      name="PublicTest"
                      value={stripeKeys.PublicTest || ""}
                      onChange={handleStripeKeyChange}
                      placeholder="Enter your Stripe publishable Test key"
                      required
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="SecretTest">
                      Stripe API Secret Test key
                    </label>
                    <input
                      type="password"
                      name="SecretTest"
                      value={stripeKeys.SecretTest || ""}
                      onChange={handleStripeKeyChange}
                      placeholder="Enter your Stripe secret Test key"
                      required
                    />
                  </div>
                </TabPanel>
              </TabPanels>
              </div>

            </Tabs>
        </form>
      </div>
    </Modal>
  );
};

export default StripeKeysModal;
