import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useStrictModeDroppable from "../../hooks/useStrictModeDroppable";
import { useAppContext } from "../../contexts/AppContext";
import { Flex, Button, Text } from '@chakra-ui/react';
import { GripVertical, Settings } from 'lucide-react';



const StrictModeDroppableComponent = ({ droppableId, children }) => {
	const { enabled } = useStrictModeDroppable();
	if (!enabled) {
	  return null;
	}
	return (
	  <Droppable droppableId={droppableId}>
		{(provided, snapshot) => children(provided, snapshot)}
	  </Droppable>
	);
  };

const ManageCategories = ({ categories, setCategories, onOrderChange, itemsByCategory, openSubCategoryModal }) => {
	
	
	const { mainLanguage } = useAppContext();

	const handleOnDragEnd = (result) => {
		if (!result.destination) return;
		const itemsArray = Array.from(categories);
		const [reorderedItem] = itemsArray.splice(result.source.index, 1);
		itemsArray.splice(result.destination.index, 0, reorderedItem);
		setCategories(itemsArray);
		onOrderChange(itemsArray);
	};

	const handleLinkClick = (anchorId) => {
        // Prévenir le comportement par défaut si vous utilisez un élément <a>
        // e.preventDefault();
        const anchorElement = document.getElementById(anchorId);
        if (anchorElement) {
            const offsetTop = anchorElement.offsetTop + 78; // 72px est l'offset désiré
            window.scrollTo({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    };

	
	return (
		<>
		<DragDropContext onDragEnd={handleOnDragEnd}>
		<Flex direction="column" className="widget manage-categories" position={{ desktop: "sticky", mobile: "relative" }} top={{ desktop: "72px", mobile: "0" }} zIndex={1}>
			<Flex className="widgetTitle" justifyContent="space-between">
			<h5>Categories</h5>
			<Button
                            size="xs"
                            className="btn secondary"
                            onClick={() => openSubCategoryModal()}>
                            Add category
        	</Button>
			</Flex>
			<Flex className="categoriesScroll" >
			<StrictModeDroppableComponent droppableId="categories" flex={1}>
				{(provided) => (
					<Flex direction="column"
						{...provided.droppableProps}
						ref={provided.innerRef}
						className="order-categories"
						flex={1}
					>
						{categories.filter(cat => cat.id !== 'uncategorized').map((category, index) => {
							const itemCount =
								itemsByCategory[category.id]?.length || 0;

							return (
								<Draggable
									key={category.id}
									draggableId={category.id.toString()}
									index={index}
								>
									{(provided) => (
										<Flex direction="row"
											flex={1}
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="category-item"
											onClick={() => handleLinkClick(category.id)}
										>
										
												<Flex gap={2} alignItems="center">
													
													<GripVertical size={16} color="var(--grey500)" style={{ minWidth: "16px" }} />
													<Text whiteSpace="nowrap" color="var(--grey700)" maxWidth="160px" overflow="hidden" textOverflow={"ellipsis"}>{category[`name_${mainLanguage}`]}</Text>
													<span>({itemCount})</span>
												</Flex>
												<Settings 
													size={16}
													style={{ minWidth: "16px" }}
													onClick={(event) => { event.stopPropagation(); openSubCategoryModal(category);}}
												/>
										</Flex>
									)}
								</Draggable>
							);
						})}
						{provided.placeholder}
					</Flex>
				)}
			</StrictModeDroppableComponent>
			</Flex>
			</Flex>
		</DragDropContext>


</>
	);
};

export default ManageCategories;
