// useLogout.js
import { getAuth, signOut } from 'firebase/auth';

const useLogout = () => {
  
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        window.location.href = "/login";
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion :", error);
      });
  };

  return handleLogout;
}

export default useLogout;