import EditExtrasGroup from './EditExtrasGroup';
import useExtras from '../../hooks/useExtras';
import { Flex, Button, Heading } from '@chakra-ui/react';
import { Settings } from 'lucide-react';


const ManageExtras = ({ categories, items }) => {
        
    const { 
        extrasGroups,
        currentExtrasGroup,
        groupDetails,
        setGroupDetails,
        extraModalIsOpen,
        closeExtrasGroupModal,
        applicableTo,
        setApplicableTo,
        selectedOptions,
        setSelectedOptions, 
        saveExtrasGroup,
        deleteExtrasGroup,
        openExtrasModal,
        isEditing,
        setIsEditing
    } = useExtras();


      
    return (

		<Flex direction="column" className="widget manage-categories">
            <Flex className="widgetTitle" justifyContent="space-between">
			<h5>Options & extras</h5>
			<Button
                            size="xs"
                            className="btn secondary"
                            onClick={() => openExtrasModal()}>
                            Add extras
        	</Button>
			</Flex>
            
    
             
            <div className='widgetBody'>
            <Flex direction="column" className="tagItems" gap={0}>
                {extrasGroups.map((group, index) => (
                    <Flex direction="row" className='category-item' key={index} onClick={() => openExtrasModal(group)} cursor="pointer" p="var(--padding-xs) var(--padding-xs)" _hover={{backgroundColor: "var(--brand50)"}} borderRadius={"var(--radius-sm)"} justifyContent={"space-between"} alignItems={"center"}>
                        <Heading as={"h6"} fontSize={"1em"} fontWeight={"500"} maxWidth="180px" overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}>{group.name}</Heading>
                        <Settings size={16} style={{ minWidth: "16px" }}/>
                    </Flex>
                ))}
            </Flex>
            
        </div>

        {extraModalIsOpen && (
                <EditExtrasGroup
                    isOpen={extraModalIsOpen}
                    extrasGroup={currentExtrasGroup}
                    onClose={closeExtrasGroupModal}
                    onSave={saveExtrasGroup}
                    onDelete={deleteExtrasGroup}
                    groupDetails={groupDetails}
                    setGroupDetails={setGroupDetails}
                    categories={categories}
                    items={items}
                    applicableTo={applicableTo}
                    setApplicableTo={setApplicableTo}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    currentExtrasGroup={currentExtrasGroup}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            )}
        </Flex>
    );
};

export default ManageExtras;