import { useState, useEffect, useRef } from "react";
import { db } from "../firebaseConfig";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import Sidebar from "../components/menu/Sidebar";
import MenuList from "../components/menu/MenuList";
import ItemModal from "../components/menu/ItemModal";
import useMenuData from "../hooks/useMenuData";
import { useAppContext } from "../contexts/AppContext";
import useMainCategories from "../hooks/useMainCategories";
import MainCategoryModal from "../components/menu/MainCategoryModal";
import SubCategoryModal from "../components/menu/SubCategoryModal";
import OptionsManager from "../components/menu/OptionsManager";
import { Flex, Box, Button } from "@chakra-ui/react";
import { Eye, EyeOff } from "lucide-react";
import MenuSwitcher from "../components/menu/MenuSwitcher";
import { toast } from "react-toastify";
import { useMenuContext } from "../contexts/MenuContext";
import SkeletonLayout from "../components/menu/SkeletonLayout";
import "react-horizontal-scrolling-menu/dist/styles.css";
import SubCategoryMenu from "../components/menu/SubCategoryMenu";

const Menu = ({ productType, category }) => {
  const {
    selectedRestaurantId,
    menuAdminBarZIndex,
    toggleSubMenu,
    openSubMenu,
    closeSubMenu,
    showOverlay,
    settings,
    mainLanguage } = useAppContext();

  const {
    currentMainCategory,
    categories,
    setCategories,
    itemsByCategory,
    setItemsByCategory,
  } = useMenuContext();

  const {
    mainCategories,
    mainCategoryName,
    editMainCategory,
    isMainCategoryModalOpen,
    handleDeleteMainCategory,
    handleSaveNewMainCategory,
    handleOpenMainCategoryModal,
    handleCloseMainCategoryModal,
    mainCategoryHasChanges,
    setMainCategoryHasChanges,
  } = useMainCategories(selectedRestaurantId);

  const {
    currentItem,
    setCurrentItem,
    handleSubmit,
    duplicateItem,
    handleOrderChange,
    uncategorizedItems,
    onAddCategory,
    deleteSubCategory,
    openEditModal,
    addItemWithCategory,
    closeEditModal,
    confirmAndDelete,
    fetchData,
    handleImageDelete,
    handleImageUploadClick,
    handleImageUpload,
    handleDrop,
    handleDragOver,
    hasChanges,
    setHasChanges,
    deleteUncategorizedItems,
    handleCategoryUpdate,
    isLoading,
    toggleAvailability
  } = useMenuData(mainCategoryName, selectedRestaurantId);

  const [currentSubCategory, setCurrentSubCategory] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryDescription, setSubCategoryDescription] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [subCategoryFormula, setSubCategoryFormula] = useState(false);
  const [subCategoryFormulaPrice, setSubCategoryFormulaPrice] = useState("");
  const [subCategoryPrintPageBreak, setSubCategoryPrintPageBreak] =
    useState(false);
  const [subCategoryPrintColumns, setSubCategoryPrintColumns] = useState("1");
  const [subCategoryStyle, setSubCategoryStyle] = useState("List");
  const [isSubCategoryModalOpen, setIsSubCategoryModalOpen] = useState(false);
  

  useEffect(() => {
    if (category) {
      setSubCategoryName(category[`name${mainLanguage}`]);
      setSubCategoryStyle(category.style);
      setSubCategoryPrintPageBreak(category.pageBreak);
      setSubCategoryPrintColumns(category.columns);
    } else {
      setSubCategoryName("");
      setSubCategoryStyle("List");
      setSubCategoryPrintPageBreak("none");
      setSubCategoryPrintColumns("1");
    }
  }, [category, mainLanguage]);

  const handleSaveSubCategory = async (e) => {
    e.preventDefault();
    try {
      const isUpdate = currentSubCategory && currentSubCategory.id;
      const mainLanguage = settings.language[0].value;
      let categoryId;
      if (isUpdate) {
        categoryId = currentSubCategory.id;
      } else {
        categoryId = subCategoryName[mainLanguage]
          ? subCategoryName[mainLanguage]
              .trim()
              .toLowerCase()
              .replace(/ /g, "-")
          : "";
      }
      const categoryRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`,
        categoryId
      );
      let categoryData = {
        style: subCategoryStyle,
        formula: subCategoryFormula === "yes",
        pageBreak: subCategoryPrintPageBreak,
        columns: subCategoryPrintColumns,
      };
      if (subCategoryFormula === "yes") {
        categoryData.formulaPrice = parseFloat(subCategoryFormulaPrice) || 0;
      }
      Object.keys(subCategoryName).forEach((lang) => {
        if (typeof subCategoryName[lang] === "string") {
          categoryData[`name_${lang}`] = subCategoryName[lang].trim();
        }
      });
      Object.keys(subCategoryDescription).forEach((lang) => {
        if (typeof subCategoryDescription[lang] === "string") {
          categoryData[`description_${lang}`] =
            subCategoryDescription[lang].trim();
        }
      });
      if (isUpdate) {
        await updateDoc(categoryRef, categoryData);
        handleCategoryUpdate({ ...currentSubCategory, ...categoryData });
      } else {
        const newCategory = {
          id: categoryId,
          order: categories.length,
          ...categoryData,
        };
        await setDoc(categoryRef, newCategory);
        handleCategoryUpdate(newCategory);
      }
      fetchData();
      setIsSubCategoryModalOpen(false);
      toast.success("Category saved successfully");
      document.body.classList.remove("no-scroll");
    } catch (error) {
      console.error("Error saving category:", error);
    }
  };

  const openSubCategoryModal = (category) => {
    if (category) {
      setCurrentSubCategory(category);
      setIsEditing(true);

      const nameObject = {};
      const allLanguages = [
        settings.language[0],
        ...settings.secondaryLanguages,
      ];
      allLanguages.forEach((lang) => {
        const langKey = `name_${lang.value}`;
        nameObject[lang.value] = category[langKey] || "";
      });
      setSubCategoryName(nameObject);
      setSubCategoryStyle(category.style);
    } else {
      setCurrentSubCategory(null);
      setIsEditing(false);
      setSubCategoryName({});
      setSubCategoryStyle("List");
    }
    setIsSubCategoryModalOpen(true);
    document.body.classList.add("no-scroll");
  };

  const closeSubCategoryModal = () => {
    document.body.classList.remove("no-scroll");
    setIsSubCategoryModalOpen(false);
  };

  

  return (
    <>
      <Flex
        justifyContent="space-between"
        direction="row"
        w="100%"
        alignItems="center"
        position="sticky"
        top="0"
        bg="white"
        pl={8}
        pr={8}
        maxWidth="100vw"
        borderBottom="1px solid var(--borders)"
        minHeight="45px"
        style={{ zIndex: menuAdminBarZIndex }}>
        <MenuSwitcher />

        <Flex
          direction="row"
          alignItems={"center"}
          className="buttons"
          position="relative">
          <div>
            <Button
              size="sm"
              className="btn primary"
              onClick={toggleSubMenu("addActions")}>
              Add
            </Button>

            {openSubMenu === "addActions" && (
              <div className="submenu">
                <ul>
                  <li
                    onClick={() => {
                      handleOpenMainCategoryModal(null);
                      closeSubMenu();
                    }}>
                    Add menu
                  </li>
                  {mainCategoryName && (
                    <>
                      <li
                        onClick={() => {
                          openSubCategoryModal();
                          closeSubMenu();
                        }}>
                        Add category
                      </li>
                      <li
                        onClick={() => {
                          addItemWithCategory();
                          closeSubMenu();
                        }}>
                        Add item
                      </li>
                    </>
                  )}
                </ul>
                <button onClick={closeSubMenu} className="closeSubmenu">
                  Cancel
                </button>
              </div>
            )}
            {showOverlay && openSubMenu === "addActions" && (
              <div className="overlay" onClick={closeSubMenu}></div>
            )}
          </div>

          <OptionsManager
            categories={categories}
            itemsByCategory={itemsByCategory}
            settings={settings}
            mainLanguage={mainLanguage}
            selectedRestaurantId={selectedRestaurantId}
            currentMainCategory={currentMainCategory}
          />
        </Flex>
      </Flex>

 
     
      
      <MainCategoryModal
        isOpen={isMainCategoryModalOpen}
        onClose={handleCloseMainCategoryModal}
        onSave={handleSaveNewMainCategory}
        onDelete={handleDeleteMainCategory}
        mainCategoryName={mainCategoryName}
        mainCategoryHasChanges={mainCategoryHasChanges}
        setMainCategoryHasChanges={setMainCategoryHasChanges}
        editCategory={editMainCategory}
      />

      {isLoading ? (
        <SkeletonLayout />
      ) : (
        <Flex
          padding={{ desktop: "3vw 3vw 124px 3vw", mobile: "0 0 172px 0" }}
          maxW="1320px"
          direction={{ desktop: "row", mobile: "column" }}
          gap={8}
          flex="1">
          {categories.length > 0 ? (
            <MenuList
              categories={categories.filter((cat) => !cat.isUncategorized)}
              setCategories={setCategories}
              itemsByCategory={itemsByCategory}
              setItemsByCategory={setItemsByCategory}
              onOrderChange={handleOrderChange}
              openEditModal={openEditModal}
              onCategoryDelete={deleteSubCategory}
              onAddDish={openEditModal}
              toggleAvailability={toggleAvailability}
              onDuplicate={duplicateItem}
              confirmAndDelete={confirmAndDelete}
              mainCategoryName={mainCategoryName}
              currentItem={currentItem}
              productType={productType}
              setCurrentItem={setCurrentItem}
              selectedRestaurantId={selectedRestaurantId}
              addItemWithCategory={addItemWithCategory}
              handleSubmit={handleSubmit}
              mainCategories={mainCategories}
              currentMainCategory={currentMainCategory}
              handleOpenModal={handleOpenMainCategoryModal}
              openSubCategoryModal={openSubCategoryModal}
              isEditing={isEditing}
            />
          ) : mainCategoryName ? (
            <Flex
              direction={"column"}
              gap={6}
              justifyContent="center"
              padding={{ desktop: "0", mobile: "3vw" }}
              w="100%"
              alignItems="center">
              <Flex
                direction={"column"}
                gap={2}
                w="fit-content"
                alignItems="center"
                justifyContent="center">
                <h2>Your menu is empty.</h2>
                <p>Start by adding a category.</p>
              </Flex>
              <Button
                size="lg"
                className="btn primary"
                onClick={() => {
                  openSubCategoryModal();
                }}
                width="fit-content">
                Add category
              </Button>
            </Flex>
          ) : (
            <Flex
              direction={"column"}
              gap={6}
              justifyContent="center"
              padding={{ desktop: "0", mobile: "3vw" }}
              w="100%"
              alignItems="center">
              <Flex
                direction={"column"}
                gap={2}
                w="fit-content"
                alignItems="center"
                justifyContent="center">
                <h2>Welcome!</h2>
                <p>Start by adding a menu.</p>
              </Flex>
              <Button
                size="lg"
                className="btn primary"
                onClick={() => {
                  handleOpenMainCategoryModal();
                }}
                width="fit-content">
                Add menu
              </Button>
            </Flex>
          )}

          {categories.length > 0 ? (
            <Sidebar
              categories={categories}
              setCategories={setCategories}
              itemsByCategory={itemsByCategory}
              handleOrderChange={handleOrderChange}
              handleCategoryDeletion={deleteSubCategory}
              onAddCategory={onAddCategory}
              uncategorizedItems={uncategorizedItems}
              openEditModal={openEditModal}
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
              mainCategoryName={mainCategoryName}
              currentMainCategory={currentMainCategory}
              selectedRestaurantId={selectedRestaurantId}
              handleSubmit={handleSubmit}
              productType={productType}
              subCategoryName={subCategoryName}
              currentSubCategory={currentSubCategory}
              onDeleteUncategorized={deleteUncategorizedItems}
              openSubCategoryModal={openSubCategoryModal}
            />
          ) : (
            ""
          )}
        </Flex>
      )}

      {isSubCategoryModalOpen && (
        <SubCategoryModal
          isOpen={isSubCategoryModalOpen}
          onClose={closeSubCategoryModal}
          category={currentSubCategory}
          mainCategoryName={mainCategoryName}
          onCategoryUpdate={handleCategoryUpdate}
          currentSubCategory={currentSubCategory}
          onSave={handleSaveSubCategory}
          isEditing={isEditing}
          subCategoryName={subCategoryName}
          setSubCategoryName={setSubCategoryName}
          subCategoryDescription={subCategoryDescription}
          setSubCategoryDescription={setSubCategoryDescription}
          subCategoryStyle={subCategoryStyle}
          setSubCategoryStyle={setSubCategoryStyle}
          subCategoryFormula={subCategoryFormula}
          setSubCategoryFormula={setSubCategoryFormula}
          subCategoryFormulaPrice={subCategoryFormulaPrice}
          setSubCategoryFormulaPrice={setSubCategoryFormulaPrice}
          subCategoryPrintPageBreak={subCategoryPrintPageBreak}
          setSubCategoryPrintPageBreak={setSubCategoryPrintPageBreak}
          subCategoryPrintColumns={subCategoryPrintColumns}
          setSubCategoryPrintColumns={setSubCategoryPrintColumns}
          onDelete={deleteSubCategory}
        />
      )}

      <ItemModal
        categories={categories}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        onDuplicate={duplicateItem}
        toggleAvailability={toggleAvailability}
        handleSubmit={handleSubmit}
        handleImageDelete={handleImageDelete}
        handleImageUploadClick={handleImageUploadClick}
        handleImageUpload={handleImageUpload}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        confirmAndDelete={confirmAndDelete}
        closeEditModal={closeEditModal}
        mainCategoryName={mainCategoryName}
        currentMainCategory={currentMainCategory}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
      />

      {mainCategoryName && (
        <button
          className="btn  dark editMenu"
          onClick={() => {
            handleOpenMainCategoryModal(currentMainCategory);
          }}>
          {currentMainCategory
            ? `Edit ${currentMainCategory[`name_${mainLanguage}`]}`
            : "Loading..."}

          {currentMainCategory && (
            <>
              {currentMainCategory.published ? (
                <Eye size={16} />
              ) : (
                <EyeOff size={16} />
              )}
            </>
          )}
        </button>
      )}
    </>
  );
};
export default Menu;
