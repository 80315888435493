import { useState } from "react";
import { db } from '../firebaseConfig';
import { collection, doc, getDoc, setDoc, addDoc } from "firebase/firestore";
import { useAppContext } from "../contexts/AppContext";
import { importFromCSV, exportToCSV } from "../services/csvService";
import useMenuData from "./useMenuData";
import { useMenuContext } from "../contexts/MenuContext";



const useImportExport = () => {

    const { selectedRestaurantId, mainCategoryName, closeSubMenu, settings, mainLanguage } = useAppContext();
    const { categories, menuItems, tags, setTags } = useMenuContext();
    const { fetchData, saveTag } = useMenuData();
    const [ importedItemCount, setImportedItemCount] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [ importModalIsOpen, setImportModalIsOpen ] = useState(false);
    const [importIsLoading, setImportIsLoading] = useState(0);
    const [message, setMessage] = useState("");

    
    const handleExport = async () => {
        const { fields, data } = await fetchMenuItems();
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split("T")[0];
        const filename = `${selectedRestaurantId} - ${mainCategoryName} - ${formattedDate}.csv`;
        exportToCSV({ fields, data }, filename);
        closeSubMenu();
    };     
        
    const handleImport = async (file) => {
        if (!file) {
          alert("Please select a file to import.");
          return;
        }
        setImportIsLoading(0); // Initialize loading progress
        setImportedItemCount(0); // Initialize count of imported items
        setMessage("");
        fetchData();
        importFromCSV(file, async (data) => {
          try {
            let itemCount = 0; // Initialize item count
    
            const isValidData = data.every((item) => {
              if (item.mainCategoryName !== mainCategoryName) return false;
              if (item.price && isNaN(parseFloat(item.price.replace(",", "."))))
                return false;
              return true;
            });
    
            if (isValidData) {
              const categoriesCollectionRef = collection(
                db,
                `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`
              );
              const itemsCollectionRef = collection(
                db,
                `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`
              );
    
            let currentTags = [...tags];
    
              for (const item of data) {
                const categoryId = item.categorieId?.toLowerCase();
                const allergenes = item.allergenes
                  ? item.allergenes.split(",").map((a) => a.trim())
                  : [];
    
                let itemTags =
                  item.tags && typeof item.tags === "string"
                    ? item.tags.split(",").map((tag) => tag.trim())
                    : [];
                const displayArray = item.display
                  ? item.display.split(",").map((displayItem) => displayItem.trim())
                  : [];
    
                for (const tagName of itemTags) {
                  let tagExists = currentTags.some(
                    (t) =>
                      t[`name_${mainLanguage}`]?.toLowerCase() ===
                      tagName.toLowerCase()
                  );
                  if (!tagExists) {
                    const tagObj = {
                      [`name_${mainLanguage}`]: tagName
                    };
                    const newTag = await saveTag(tagObj, "#CCC"); // Remplacez par la couleur correcte si disponible.
                    if (newTag) {
                      currentTags.push(newTag);
                    }
                  }
                }
    
                let tagsForItem = itemTags
                  .map((tagName) => {
                    let foundTag = currentTags.find(
                      (t) =>
                        t[`name_${mainLanguage}`]?.toLowerCase() ===
                        tagName.toLowerCase()
                    );
                    return foundTag
                      ? {
                          label: foundTag[`name_${mainLanguage}`],
                          value: foundTag.id,
                        }
                      : null;
                  })
                  .filter((tag) => tag !== null);
    
                let itemData = {
                  ...item,
                  categorieId: categoryId,
                  allergenes,
                  tags: tagsForItem,
                  display: displayArray,
                  price: item.price ? formatPrice(item.price) : null,
                  availability: item.availability ? item.availability.toLowerCase() === 'true' : true, 
                };
    
                if (item.type === "variable") {
                  delete itemData.price;
                  itemData.variations =
                    item.variations && typeof item.variations === "string"
                      ? item.variations
                          .split(";")
                          .map((variation) => {
                            const parts = variation.split(":");
                            return parts.length === 2
                              ? {
                                  name: parts[0].trim(),
                                  price: formatPrice(parts[1].trim()),
                                }
                              : null;
                          })
                          .filter((v) => v != null)
                      : [];
                } else {
                  delete itemData.variations;
                }
    
                const categoryRef = doc(categoriesCollectionRef, categoryId);
                const categoryDoc = await getDoc(categoryRef);
                if (!categoryDoc.exists()) {
                  const nameField = `name_${mainLanguage}`;
                  await setDoc(categoryRef, {
                    [nameField]: categoryId,
                    style: "list",
                  });
                }
    
                if (item.id) {
                  const docRef = doc(itemsCollectionRef, item.id);
                  await setDoc(docRef, itemData);
                } else {
                  const docRef = await addDoc(itemsCollectionRef, itemData);
                  itemData.id = docRef.id;
                  await setDoc(docRef, itemData);
                }
    
                itemCount++;
                setImportIsLoading(Math.round((itemCount / data.length) * 100));
              }
    
              setTags(currentTags);
    
              setMessage(`Successful import. ${itemCount} items imported.`);
              setImportedItemCount(itemCount);
              fetchData && fetchData();
            } else {
              setMessage("Invalid data in the CSV file or wrong category");
            }
          } catch (error) {
            console.error("Error during import:", error);
            setMessage("Error during import.");
          } finally {
            setImportIsLoading(100); // Set loading complete
          }
        });
      };
    
      const fetchMenuItems = async () => {
        await fetchData();
    
        let allSortedItems = [];
    
        for (const category of categories) {
          let categoryItems = menuItems
            .filter((item) => item.categorieId === category.id)
            .sort((a, b) => a.order - b.order);
    
          allSortedItems = [...allSortedItems, ...categoryItems];
        }
    
        let fields = ["id", "mainCategoryName", "categorieId", "order"];
    
        const mainLanguage = settings.language[0].value;
        fields.push(
          `name_${mainLanguage}`,
          `description_${mainLanguage}`,
          `note_${mainLanguage}`
        );
    
        fields = fields.concat([
          "type",
          "costPrice",
          "price",
          "variations",
          "display",
          "availability",
          "tags",
          "allergenes",
          "photo",
        ]);
        for (const lang of settings.secondaryLanguages) {
          fields.push(
            `name_${lang.value}`,
            `description_${lang.value}`,
            `note_${lang.value}`
          );
        }
    
        const data = allSortedItems.map((item) => {
          if (
            Array.isArray(item.variations) &&
            item.variations.every((v) => v && v.name && v.price)
          ) {
            item.variations = item.variations
              .map((v) => `${v.name}:${v.price}`)
              .join("; ");
          } else {
            item.variations = item.variations ? "" : ""; // S'assurer que la case soit vide si `variations` n'est pas conforme
          }
    
          if (
            Array.isArray(item.tags) &&
            item.tags.every((tag) => typeof tag === "object" && tag.label)
          ) {
            item.tags = item.tags.map((tag) => tag.label).join(", ");
          } else {
            console.log(
              "Unexpected structure for tags or tags is not an array",
              item.tags
            );
            item.tags = "";
          }
          item.availability = item.availability === true ? "true" : "false"; // Convertit true en "true" et false en "false"
    
          const itemWithCategoryName = {
            ...item,
            mainCategoryName: mainCategoryName,
          };
    
          return fields.reduce((obj, field) => {
            obj[field] = itemWithCategoryName[field] || "";
            return obj;
          }, {});
        });
    
        return { fields, data };
      };



  const formatPrice = (price) => {
    let formattedPrice = price.replace(".", ",");
    if (!formattedPrice.includes(",")) {
      formattedPrice += ",00";
    } else {
      const parts = formattedPrice.split(",");
      if (parts[1].length === 1) {
        formattedPrice += "0";
      }
    }
    return formattedPrice;
  };
        

  const handleStartImport = async () => {
    if (!selectedFile) {
      alert("Please select a file to import.");
      return;
    }
    setImportIsLoading(0);
    setImportedItemCount(0);
    await handleImport(selectedFile);
    setSelectedFile(null);
  };
  
  const closeImportModal = () => {
    setImportIsLoading(0); // Réinitialiser le chargement à 0
    setSelectedFile(null); // Supprimer le fichier sélectionné
    setMessage(""); // Effacer les messages
    setImportedItemCount(0); // Réinitialiser le compteur d'items importés
    setImportModalIsOpen(false); 
    closeSubMenu(); // Fermer le sous-menu si nécessaire
  };
        
        
   


    return {     
        
        importedItemCount, setImportedItemCount,
        selectedFile, setSelectedFile,
        importModalIsOpen, setImportModalIsOpen,
        handleExport,
        handleStartImport,
        closeImportModal,
        message,
        setMessage,
        importIsLoading,
        setImportIsLoading
        
   
    };
};

export default useImportExport;