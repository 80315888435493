import React, { useState } from "react";
import useAllRestaurants from "../../hooks/useAllRestaurants";
import { useAppContext } from "../../contexts/AppContext";
import CreateRestaurantModal from "./CreateRestaurantModal";
import { useSelectedRestaurant } from "../../contexts/SelectedRestaurantContext";
import { Flex, Text } from "@chakra-ui/react";

const RestaurantSelect = () => {
  const { error } = useAllRestaurants();

  const {
    selectedRestaurantId,
    setSelectedRestaurantId,
    setMenuAdminBarZIndex,
    toggleSubMenu,
    showOverlay,
    closeSubMenu,
    openSubMenu,
    settings,
    userRole,
  } = useAppContext();
  
  const { selectedRestaurant, handleChangeRestaurant, filteredRestaurants } = useSelectedRestaurant();

  const [isCreateRestaurantModalOpen, setIsCreateRestaurantModalOpen] = useState(false);

  const handleRestaurantAdded = (newRestaurantId) => {
    setSelectedRestaurantId(newRestaurantId);
  };

  const handleOpenSubMenu = () => {
    setMenuAdminBarZIndex(1);
    toggleSubMenu("restaurantSelection")();
  };

  return (
    <>
      {error && <div>Error loading restaurants: {error.message}</div>}
      
      {selectedRestaurant && (
        <Flex
          direction="row"
          className="restaurantSelect"
          onClick={handleOpenSubMenu}>
          <Flex
          alignItems="center"
            className="profileImage"
            style={{ backgroundColor: settings.backgroundColor }}>
            {settings.logo && (
              <img src={settings.logo.menu} alt="Restaurant Logo" />
            )}
          </Flex>
          <Text className="selectedRestaurantName" color="black">
            {selectedRestaurant.name}
          </Text>
          {openSubMenu === "restaurantSelection" && (
            <div className="submenu">
              <ul className="restaurantOptions">
                {filteredRestaurants
                  .filter(
                    (restaurant) => restaurant.id !== selectedRestaurantId
                  )
                  .map((restaurant) => (
                    <li
                      key={restaurant.id}
                      onClick={() => handleChangeRestaurant(restaurant.id)}>
                      {restaurant.name}
                    </li>
                  ))}
              </ul>
              {userRole === "superAdmin" && (
                <button
                  onClick={() => setIsCreateRestaurantModalOpen(true)}
                  className="primary addRestaurant">
                  Add a restaurant
                </button>
              )}
              <button onClick={closeSubMenu} className="closeSubmenu">
                Cancel
              </button>
            </div>
          )}
          {showOverlay && openSubMenu === "restaurantSelection" && (
        <div className="overlay" onClick={closeSubMenu}></div>
      )}
        </Flex>
        
        
      )}
      
      <CreateRestaurantModal
        isOpen={isCreateRestaurantModalOpen}
        onRequestClose={() => setIsCreateRestaurantModalOpen(false)}
        onRestaurantAdded={handleRestaurantAdded}
      />
    </>
  );
};

export default RestaurantSelect;
