import { useEffect } from "react";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import { Button, Divider, Box, AbsoluteCenter, Text } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import useImportExport from "../../hooks/useImportExport";
import useIngredients from "../../hooks/useIngredients";
import Select from "react-select";
import { useRecipeContext } from "../../contexts/RecipeContext";

Modal.setAppElement("#root");

const ImportOrderModal = ({ isOpen, onClose }) => {
  const { selectedFile, setSelectedFile, message, importIsLoading } = useImportExport();
  const { customStyles } = useModal();
  const { handleOrderImport } = useIngredients();
  const { setSupplier, suppliers, supplierOptions } = useRecipeContext();

  // Trouver le fournisseur 'La Provençale'
  const defaultSupplier = supplierOptions.find(supplier => supplier.label === "La Provençale");

  // Sélectionner par défaut 'La Provençale'
  const handleSelectSupplier = (selectedOption) => {
    setSupplier({
      id: selectedOption.value,
      name: selectedOption.label
    });
  };

  // Sélectionner 'La Provençale' par défaut lors du montage du composant
  useEffect(() => {
    if (defaultSupplier) {
      handleSelectSupplier(defaultSupplier);
    }
  }, [defaultSupplier]);

  const handleStartOrderImport = async () => {
    if (!selectedFile) {
      alert("Please select a file to import.");
      return;
    }
    await handleOrderImport(selectedFile);
    setSelectedFile(null);
    onClose();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
    accept: ".csv, .xlsx, .xls",
  });

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal"
      style={customStyles}>
      <div className="modal-content">
        <div className="modalTopBar">
          <div className="closeModal">
            <span className="close" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
          <div className="modalTitle">Import order</div>
          <div className="actionButtons"></div>
        </div>

        <div className="modalInner">
        
          <div className="field">
            <label htmlFor="supplier">Supplier</label>
            <Select
              className="guddenSelectContainer"
              classNamePrefix="guddenSelect"
              options={supplierOptions}
              value={defaultSupplier} // Sélectionner par défaut 'La Provençale'
              isDisabled={true} // Désactiver le champ
            />
            <Text fontSize="12px" color="gray.500" mt={2}>
              Currently, only 'La Provençale' is supported for importing orders.
            </Text>
          </div>

          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <h6>Drag and drop a file</h6>
            <Box position="relative" padding="10">
              <Divider />
              <AbsoluteCenter bg="white" px="2" fontSize="12px">
                or
              </AbsoluteCenter>
            </Box>
            <Text textDecoration="underline" fontSize="14px">
              Click to select a file
            </Text>
          </div>
          {selectedFile && <p>File ready to import: {selectedFile.name}</p>}
          {message && <p>{message}</p>}
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${importIsLoading}%` }}></div>
          </div>
          {selectedFile && importIsLoading === 0 && (
            <Button size="lg" onClick={handleStartOrderImport} className="primary">
              Start Import
            </Button>
          )}
          {importIsLoading > 0 && importIsLoading < 100 && (
            <button disabled className="primary">
              Importing...
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ImportOrderModal;
