import { Flex } from "@chakra-ui/react";
import ManageSuppliers from "../components/recipes/ManageSuppliers";
import RecipesList from "../components/recipes/RecipesList";

const Recipes = () => {
  return (
    <>
      <Flex direction={{ desktop: "row", mobile: "column" }} gap={16} flex="1">
        <RecipesList />
      </Flex>
      <Flex
        flex={{ desktop: "0 0 320px", mobile: "1" }}
        direction="column"
        gap={12}>
        <Flex direction="column" gap={8}>
          <ManageSuppliers />
        </Flex>
      </Flex>
    </>
  );
};
export default Recipes;
