import React, { useState, useRef, useEffect } from "react";
import StatusSelector from "../components/shop/StatusSelector";
import OrderModal from "../components/shop/OrderModal";
import useOrders from "../hooks/useOrders";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subWeeks,
  subMonths,
  subYears,
} from "date-fns";
import "react-date-range/dist/styles.css"; // import styles for date range picker
import "react-date-range/dist/theme/default.css";
import { useAppContext } from "../contexts/AppContext";
import { Flex, Table, Tbody, Thead, Th, Tr, Td, Box } from "@chakra-ui/react";
import { ArrowLeft, ArrowRight, Calendar } from 'lucide-react';
import { useLocation } from 'react-router-dom';


const Orders = () => {
  
  const location = useLocation();
  const {  mainLanguage, formatScheduledTime } = useAppContext();
  const startOfThisWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  const endOfThisWeek = endOfWeek(new Date(), { weekStartsOn: 1 });
  const [startDate, setStartDate] = useState(startOfThisWeek);
  const [endDate, setEndDate] = useState(endOfThisWeek);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(20);

  
  // Initialisation de l'état de la sélection pour le DateRangePicker
  const [state, setState] = useState([
    {
      startDate: startOfThisWeek,
      endDate: endOfThisWeek,
      key: "selection",
    },
  ]);
  const {
    orders,
    deleteOrder,
    isOrderModalOpen,
    setIsOrderModalOpen,
    totalOrders,
    totalSubTotal,
    totalTips,
    totalCancelledOrders,
    updateOrderStatus,
    totalTVA,
    processRefund,
  } = useOrders(startDate, endDate, currentPage, ordersPerPage);

  const [selectedOrder, setSelectedOrder] = useState(null);

  const totalOrdersCount = orders.length;
  const totalPages = Math.ceil(totalOrdersCount / ordersPerPage);

  // Calcul des commandes à afficher
  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  useEffect(() => {
    const savedOrdersPerPage = localStorage.getItem("ordersPerPage");
    if (savedOrdersPerPage) {
      setOrdersPerPage(Number(savedOrdersPerPage));
    }
  }, []);

  const handleOrdersPerPageChange = (event) => {
    const newOrdersPerPage = Number(event.target.value);
    setOrdersPerPage(newOrdersPerPage);
    setCurrentPage(1); // Réinitialiser à la première page lors d'un changement
    localStorage.setItem("ordersPerPage", newOrdersPerPage); // Enregistrer dans localStorage
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const openOrderModal = (order) => {
    setSelectedOrder(order);
    setIsOrderModalOpen(true);
  };

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const pickerRef = useRef(null);

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setState([selection]);
    setStartDate(selection.startDate);
    setEndDate(selection.endDate);
    setIsPickerOpen(false);
  };
  

  useEffect(() => {
    setState([{ startDate: startDate, endDate: endDate, key: "selection" }]);
  }, [startDate, endDate]);

  const formatDateRange = () => {
    if (!startDate || !endDate) return "Select Date Range";
    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  };

  const togglePicker = () => {
    setIsPickerOpen(!isPickerOpen);
  };

  const handleClickOutside = (event) => {
    if (
      isPickerOpen &&
      pickerRef.current &&
      !pickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false); // Alors on ferme le picker
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isPickerOpen]);

  const defineStaticRanges = (locale) => {
    return [
      {
        label: "Today",
        range: () => ({
          startDate: startOfDay(new Date()),
          endDate: endOfDay(new Date()),
        }),
        isSelected: function (range) {
          const definedRange = this.range();
          return (
            range.startDate.getTime() === definedRange.startDate.getTime() &&
            range.endDate.getTime() === definedRange.endDate.getTime()
          );
        },
      },
      {
        label: "This Week",
        range: () => {
          const start = startOfWeek(new Date(), { weekStartsOn: 1 });
          const end = endOfWeek(new Date(), { weekStartsOn: 1 });
          return { startDate: start, endDate: end };
        },
        isSelected: function (range) {
          const definedRange = this.range();
          return (
            range.startDate.getTime() === definedRange.startDate.getTime() &&
            range.endDate.getTime() === definedRange.endDate.getTime()
          );
        },
      },
      {
        label: "Last Week",
        range: () => ({
          startDate: startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
          endDate: endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }),
        }),
        isSelected: function (range) {
          const definedRange = this.range();
          return (
            range.startDate.getTime() === definedRange.startDate.getTime() &&
            range.endDate.getTime() === definedRange.endDate.getTime()
          );
        },
      },
      {
        label: "This Month",
        range: () => ({
          startDate: startOfMonth(new Date()),
          endDate: endOfMonth(new Date()),
        }),
        isSelected: function (range) {
          const definedRange = this.range();
          return (
            range.startDate.getTime() === definedRange.startDate.getTime() &&
            range.endDate.getTime() === definedRange.endDate.getTime()
          );
        },
      },
      {
        label: "Last Month",
        range: () => ({
          startDate: startOfMonth(subMonths(new Date(), 1)),
          endDate: endOfMonth(subMonths(new Date(), 1)),
        }),
        isSelected: function (range) {
          const definedRange = this.range();
          return (
            range.startDate.getTime() === definedRange.startDate.getTime() &&
            range.endDate.getTime() === definedRange.endDate.getTime()
          );
        },
      },
      {
        label: "This Year",
        range: () => ({
          startDate: startOfYear(new Date()),
          endDate: endOfYear(new Date()),
        }),
        isSelected: function (range) {
          const definedRange = this.range();
          return (
            range.startDate.getTime() === definedRange.startDate.getTime() &&
            range.endDate.getTime() === definedRange.endDate.getTime()
          );
        },
      },
      {
        label: "Last Year",
        range: () => ({
          startDate: startOfYear(subYears(new Date(), 1)),
          endDate: endOfYear(subYears(new Date(), 1)),
        }),
        isSelected: function (range) {
          const definedRange = this.range();
          return (
            range.startDate.getTime() === definedRange.startDate.getTime() &&
            range.endDate.getTime() === definedRange.endDate.getTime()
          );
        },
      },
    ];
  };

  const findBestSeller = (orders) => {
    const productCounts = {};

    orders.forEach((order) => {
      order.cartItems.forEach((item) => {
        const itemId = item.itemId;
        if (productCounts[itemId]) {
          productCounts[itemId].count += item.quantity;
        } else {
          productCounts[itemId] = {
            name: item[`name_${mainLanguage}`],
            count: item.quantity,
          };
        }
      });
    });

    let bestSellerItemId = "";
    let maxCount = 0;
    Object.entries(productCounts).forEach(([itemId, { name, count }]) => {
      if (count > maxCount) {
        bestSellerItemId = itemId;
        maxCount = count;
      }
    });

    return productCounts[bestSellerItemId]
      ? {
          itemId: bestSellerItemId,
          name: productCounts[bestSellerItemId].name,
          count: maxCount,
        }
      : null;
  };

  const bestSeller = findBestSeller(orders);

  

  const getRangeLabel = () => {
    const ranges = defineStaticRanges(mainLanguage);
    const matchingRange = ranges.find((range) => range.isSelected({ startDate, endDate }));
    return matchingRange ? matchingRange.label : formatDateRange();
  };


  useEffect(() => {
    if (location.state && location.state.orderId) {
      const orderToOpen = orders.find(order => order.id === location.state.orderId);
      if (orderToOpen) {
        setSelectedOrder(orderToOpen);
        setIsOrderModalOpen(true);
      }
    }
  }, [location, orders]);
  
  

  return (
    <Flex direction="column"  padding={{ desktop: "3vw 3vw 124px 3vw", mobile: "0 0 172px 0" }} gap={8} maxW="1320px">
      
      <Flex w='100%' className="widget" direction="column" gap={8}>
      <Flex className="sectionHeader row spaceBetween">
        <h3>Orders</h3>
        <Flex direction="row" gap={2} className="dateRange" alignItems="center" border="1px solid var(--borders)" borderRadius="8px" pt={2} pb={2} pl={3} pr={3} onClick={togglePicker} cursor="pointer">
          <Calendar size={20}/>
          <Box
            fontWeight="500"
            >
            {getRangeLabel()}
          </Box>
          {isPickerOpen && (
            <div ref={pickerRef} className="dateRangePicker">
              <DateRangePicker
                staticRanges={defineStaticRanges()}
                ranges={state}
                onChange={handleSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                weekStartsOn={1}
                months={1}
                maxDate={addDays(new Date(), 0)}
              />
            </div>
          )}
        </Flex>
      </Flex>

      <ul className="summary">
        <li>
          <div className="kpiLabel">Total Orders</div>
          <div className="kpi">
            {totalOrders} <span>({totalCancelledOrders} cancelled)</span>
          </div>
        </li>
        <li>
          <div className="kpiLabel">Total Revenue</div>
          <div className="kpi">
            {totalSubTotal.toFixed(2).replace(".", ",")}€{" "}
            <span className="smallText">
              (incl. {totalTVA.toFixed(2).replace(".", ",")}€ VAT)
            </span>
          </div>
        </li>
        <li>
          <div className="kpiLabel">Total Tips</div>
          <div className="kpi">{totalTips.toFixed(2).replace(".", ",")}€</div>
        </li>
        {bestSeller && (
          <li>
            <div className="kpiLabel">Best Seller</div>
            <div className="kpi">
              {bestSeller.name} <span>({bestSeller.count})</span>
            </div>
          </li>
        )}
      </ul>

      <Flex w='100%' direction="column" gap={8}>
        {currentOrders.length > 0 ? (
          <div className="tableWrapper">
            <Table w="100%">
              <Thead display={{ desktop: "table-header-group", mobile: "none" }}>
                <Tr className="orderHeader">
                  <Th>Order date</Th>
                  <Th>Order</Th>
                  <Th>Total</Th>
                  <Th className="mobile-hidden">Method</Th>
                  <Th className="mobile-hidden">Scheduled time</Th>
                  <Th className="mobile-hidden">Payment status</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody fontSize="14px">
                {currentOrders.map((order) => {
                  const paymentStatusBg = (order.paymentStatus === "paid" || order.paymentStatus === "Paid") ? "var(--success)" : "var(--error)";
                  return (
                    <Tr key={order.id} className="order">
                      <Td
                        className="orderDate"
                        onClick={() => openOrderModal(order)}
                        title={order.fullDate}>
                        {order.day}
                      </Td>
                      <Td onClick={() => openOrderModal(order)}>
                        #{order.id} {order.customerDetails.firstName}{" "}
                        {order.customerDetails.lastName}
                      </Td>
                      <Td onClick={() => openOrderModal(order)}>
                        <b>{order.total.replace(".", ",")}€</b>
                      </Td>
                      <Td
                        textTransform="capitalize"
                        className="mobile-hidden"
                        onClick={() => openOrderModal(order)}>
                        {order.deliveryMode}
                      </Td>
                      <Td
                        className="mobile-hidden"
                        onClick={() => openOrderModal(order)}>
                        <span className="tag">
                          {order.scheduledTime
                            ? formatScheduledTime(order.scheduledTime.toDate())
                            : "N/A"}
                        </span>
                      </Td>
                      <Td
                        className="mobile-hidden"
                        onClick={() => openOrderModal(order)}>
                        <Box pt={1} pb={1} pl={2} pr={2} className="tag" bg={paymentStatusBg} color="white">
                          {order.paymentStatus}
                        </Box>
                      </Td>
                      <Td>
                        <StatusSelector
                          currentStatus={order.status}
                          onUpdateStatus={updateOrderStatus}
                          orderId={order.id}
                          deliveryMode={order.deliveryMode}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            <div className="pagination">
              <div className="resultsNumber">
                <span>Rows per page: </span>
                <select
                  value={ordersPerPage}
                  onChange={handleOrdersPerPageChange}>
                  {[20, 50, 100].map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </div>
              <div className="pages">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                <ArrowLeft size={24} />
                </button>
                <span>
                  Page <span>{currentPage}</span> of <span>{totalPages}</span>
                </span>
                <button
                  onClick={goToNextPage}
                  disabled={orders.length < ordersPerPage}>
                  <ArrowRight size={24} />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <p>No orders found.</p>
        )}
      </Flex>
      </Flex>

      <OrderModal
        isOpen={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(false)}
        order={selectedOrder}
        onDelete={deleteOrder}
        onUpdate={updateOrderStatus}
        processRefund={processRefund}
        formatScheduledTime={formatScheduledTime}
      />
    </Flex>
  );
};

export default Orders;
