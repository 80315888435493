import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import { Flex, Box, Image, IconButton, Tooltip } from "@chakra-ui/react";
import {
  ArrowLeftToLine,
  ArrowRightToLine,
  Utensils,
  ReceiptText,
  Settings,
  Power,
  CookingPot,
  SquareGanttChart,
  CalendarSearch,
  Star,
  HandPlatter,
} from "lucide-react";
import logo from "../../images/guddenApp.svg";
import useLogout from "../../hooks/useLogout";
import useOrders from "../../hooks/useOrders";
import useReservations from "../../hooks/useReservations";

const Navigation = () => {
  
  const {isCollapsed, setIsCollapsed} = useAppContext();

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);
  useEffect(() => {
    localStorage.setItem("isCollapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1281 && !isCollapsed) {
        setIsCollapsed(true);
      } else if (window.innerWidth >= 1281 && isCollapsed) {
        setIsCollapsed(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isCollapsed]);

  const { selectedRestaurantId, settings, userRole } = useAppContext();

  const handleLogout = useLogout();
  const { pendingOrdersCount } = useOrders();
  const { pendingReservationsCount } = useReservations();


  return (
    <Flex
      direction="column"
      w={isCollapsed ? "78px" : "220px"}
      minW={isCollapsed ? "78px" : "220px"}
      h="100vh"
      pt={8}
      pb={8}
      justifyContent={"space-between"}
      alignItems={"center"}
      background="white"
      position="sticky"
      top="0"
      borderRight="1px solid var(--borders)"
      transition="all 0.5s ease"
      pr={4}
      pl={4}>
      <Flex
        direction="column"
        gap={32}
        w="100%"
        justifyContent="center"
        alignItems="center">
        <Flex
          direction="row"
          justifyContent={isCollapsed ? "center" : "space-between"}
          alignItems={"center"}
          minHeight="52px"
          w="100%"
          pr={4}
          pl={4}>
          {!isCollapsed && (
            <NavLink>
              <Image
                src={logo}
                w="52px"
                transition="all .2s ease"
                _hover={{
                  transform: "rotate(-35deg) scale(.95)",
                }}
              />
            </NavLink>
          )}

          <IconButton
            size="sm"
            aria-label="Toggle Sidebar"
            icon={
              isCollapsed ? (
                <ArrowRightToLine size={14} />
              ) : (
                <ArrowLeftToLine size={14} />
              )
            }
            onClick={toggleCollapse}
          />
        </Flex>

        {selectedRestaurantId ? (
          <Flex
            direction={"column"}
            justifyContent="flex-start"
            w="100%"
            className="navLinks"
            gap={1}>
            {userRole === "superAdmin" && (
              <Tooltip
                label="Dashboard"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink
                  to={`/${selectedRestaurantId}/dashboard`}
                  pr={isCollapsed ? 0 : 8}>
                  <Flex direction="row" alignItems="center" gap={2}>
                    <SquareGanttChart size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Dashboard
                    </Box>
                  </Flex>
                </NavLink>
              </Tooltip>
            )}

            

            <Tooltip label="Menus" isDisabled={!isCollapsed} placement="right">
              <NavLink to={`/${selectedRestaurantId}/`} end>
                <Flex direction="row" alignItems="center" gap={2}>
                  <Utensils size={16} />
                  <Box
                    opacity={isCollapsed ? "0" : "1"}
                    transition="all 0.24s ease">
                    Menus
                  </Box>
                </Flex>
              </NavLink>
            </Tooltip>

            {settings.modules.reservations && (
              <Tooltip
                label="Reservations"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink to={`/${selectedRestaurantId}/reservations`}>
                  {isCollapsed && pendingReservationsCount > 0 && (
                    <Box
                      as="span"
                      justifyContent="center"
                      className="status"
                      opacity={isCollapsed ? 1 : 0}
                      transitionDelay="1s"></Box>
                  )}
                  <Flex direction="row" alignItems="center" gap={2}>
                    <CalendarSearch size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Reservations
                    </Box>
                  </Flex>
                  {!isCollapsed && pendingReservationsCount > 0 && (
                    <Flex
                      justifyContent="center"
                      className="notification orderCount">
                      {pendingReservationsCount}
                    </Flex>
                  )}
                </NavLink>
              </Tooltip>
            )}

            {(settings.shopSettings?.delivery?.activation ||
              settings.shopSettings?.takeAway?.activation) && (
              <Tooltip label="Orders" isDisabled={!isCollapsed} placement="right">
                <NavLink to={`/${selectedRestaurantId}/shop/orders`}>
                  {isCollapsed && pendingOrdersCount > 0 && (
                    <Box
                      as="span"
                      justifyContent="center"
                      className="status"
                      opacity={isCollapsed ? 1 : 0}
                      transitionDelay="1s"></Box>
                  )}
                  <Flex direction="row" alignItems="center" gap={2}>
                    <ReceiptText size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Orders
                    </Box>
                  </Flex>
                  {!isCollapsed && pendingOrdersCount > 0 && (
                    <Flex
                      justifyContent="center"
                      className="notification orderCount">
                      {pendingOrdersCount}
                    </Flex>
                  )}
                </NavLink>
              </Tooltip>
            )}

            {settings.modules.ratio && (
              <Tooltip
                label="Recipes"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink to={`/${selectedRestaurantId}/recipes`}>
                  <Flex direction="row" alignItems="center" gap={2}>
                    <CookingPot size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Recipes
                    </Box>
                  </Flex>
                </NavLink>
              </Tooltip>
            )}
            {settings.modules?.reviews && (
              <Tooltip
                label="Reviews"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink to={`/${selectedRestaurantId}/reviews`}>
                  <Flex direction="row" alignItems="center" gap={2}>
                    <Star size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Reviews
                    </Box>
                  </Flex>
                </NavLink>
              </Tooltip>
            )}

            {settings.modules?.staff && (
              <Tooltip
                label="Staff"
                isDisabled={!isCollapsed}
                placement="right">
                <NavLink to={`/${selectedRestaurantId}/staff`}>
                  <Flex direction="row" alignItems="center" gap={2}>
                    <HandPlatter size={16} />
                    <Box
                      opacity={isCollapsed ? "0" : "1"}
                      transition="all 0.24s ease">
                      Staff
                    </Box>
                  </Flex>
                </NavLink>
              </Tooltip>
            )}
          </Flex>
        ) : (
          ""
        )}
      </Flex>

      <Flex
        direction={"column"}
        justifyContent="flex-start"
        w="100%"
        className="navLinks"
        gap={1}>
        {userRole === "superAdmin" && (
          <Tooltip label="Settings" isDisabled={!isCollapsed} placement="right">
            <NavLink to={`/${selectedRestaurantId}/settings`}>
              <Flex direction="row" alignItems="center" gap={2}>
                <Settings size={16} />
                <Box
                  opacity={isCollapsed ? "0" : "1"}
                  transition="all 0.24s ease">
                  Settings
                </Box>
              </Flex>
            </NavLink>
          </Tooltip>
        )}
        <Tooltip label="Logout" isDisabled={!isCollapsed} placement="right">
          <a onClick={handleLogout} href="#">
            <Flex direction="row" alignItems="center" gap={2}>
              <Power size={16} />
              <Box
                opacity={isCollapsed ? "0" : "1"}
                transition="all 0.24s ease">
                Logout
              </Box>
            </Flex>
          </a>
        </Tooltip>
      </Flex>
    </Flex>
  );
};

export default Navigation;
