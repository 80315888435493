import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { useAppContext } from "../contexts/AppContext";
import { toast } from "react-toastify";


const useReservations = ( selectedDate ) => {
  
  const { selectedRestaurantId } = useAppContext();
  const [reservations, setReservations] = useState([]);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [pendingReservations, setPendingReservations] = useState([]);
  const [pendingReservationsCount, setPendingReservationsCount] = useState(0);
  const [cancellationNote, setCancellationNote] = useState('');
  

    useEffect(() => {
    if (!selectedRestaurantId || !selectedDate || isNaN(new Date(selectedDate).getTime())) {
      console.error("Missing or invalid required data:", { selectedRestaurantId, selectedDate });
      return;
    }
    const todayStart = new Date(selectedDate);
    todayStart.setHours(0, 0, 0, 0);
    const todayEnd = new Date(selectedDate);
    todayEnd.setHours(23, 59, 59, 999);
    const reservationsRef = collection(db, `restaurants/${selectedRestaurantId}/reservations`);
    const q = query(
      reservationsRef,
      where("scheduledTime", ">=", Timestamp.fromDate(todayStart)),
      where("scheduledTime", "<=", Timestamp.fromDate(todayEnd)),
      orderBy("scheduledTime", "desc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedReservations = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          scheduledTime: data.scheduledTime,
        };
      });
      setReservations(fetchedReservations);
    });
    return () => unsubscribe();
  }, [selectedRestaurantId, selectedDate]);
  
  



  useEffect(() => {
    if (!selectedRestaurantId) return; 
    const reservationsRef = collection(db, `restaurants/${selectedRestaurantId}/reservations`);
    const q = query(reservationsRef, where("status", "==", "pending"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setPendingReservationsCount(querySnapshot.docs.length);
    });

    return () => unsubscribe();
  }, [selectedRestaurantId]);

  
  
  const updateReservationStatus = async (reservationId, newStatus, cancellationNote = null) => {
    console.log("Updating status for:", reservationId, "to", newStatus);
    try {
        const reservationRef = doc(db, `restaurants/${selectedRestaurantId}/reservations`, reservationId);
        const updatePayload = { status: newStatus };

        if (newStatus === "cancelled" && cancellationNote) {
            updatePayload.cancellationNote = cancellationNote;
        }

        await updateDoc(reservationRef, updatePayload);
        console.log("Reservation status updated successfully.");
        toast.success("Reservation status updated successfully.");
    } catch (error) {
        console.error("Error updating reservation status:", error);
    }
};




  return {
    reservations, setReservations,
    pendingReservations,
    pendingReservationsCount,
    isReservationModalOpen,
    setIsReservationModalOpen,
    updateReservationStatus,
    cancellationNote,
    setCancellationNote
  };
};

export default useReservations;
