import React, { useState} from "react";
import useLogout from "../../hooks/useLogout";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";
import RestaurantSelect from "./RestaurantSelect";
import { Flex, Text } from "@chakra-ui/react";
import {  Utensils, ReceiptText, CookingPot, EllipsisVertical } from "lucide-react";

const Navigation = () => {
  const {
    selectedRestaurantId,
    setSelectedRestaurantId,
    menuAdminBarZIndex,
    setMenuAdminBarZIndex,
    toggleSubMenu,
    showOverlay,
    closeSubMenu,
    openSubMenu,
    settings,
    userRole
  } = useAppContext();

  const [userRestaurantIds, setUserRestaurantIds] = useState([]);
  const handleLogout = useLogout();

  const handleRestaurantChange = (selectedRestaurant) => {
    if (!selectedRestaurant) {
      console.error("No restaurant selected");
      return;
    }
    setSelectedRestaurantId(selectedRestaurant.id);
  };

  const handleOpenSubMenu = (subMenuName) => {
    setMenuAdminBarZIndex(1);
    toggleSubMenu(subMenuName)();
  };
  

  return (
    <Flex className="mobile-nav">
      <RestaurantSelect
        onRestaurantChange={handleRestaurantChange}
        userRole={userRole}
        userRestaurantIds={userRestaurantIds}
        menuAdminBarZIndex={menuAdminBarZIndex}
        setMenuAdminBarZIndex={setMenuAdminBarZIndex}
      />

      {selectedRestaurantId ? (
        <>
          <NavLink to={`/${selectedRestaurantId}/`} end>
            <Utensils />
            <span>Menus</span>
          </NavLink>

          {(settings.shopSettings?.delivery?.activation ||
            settings.shopSettings?.takeAway?.activation) && (
            <NavLink to={`/${selectedRestaurantId}/shop/orders`}>
              <ReceiptText />
              <span>Orders</span>
            </NavLink>
          )}

          <NavLink to={`/${selectedRestaurantId}/recipes`}>
            <CookingPot />
            <span>Recipes</span>
          </NavLink>

          <NavLink onClick={() => handleOpenSubMenu("moreActions")}>
            <EllipsisVertical color="var(--grey400)" />
            <Text color="var(--grey400)">More</Text>
            {openSubMenu === "moreActions" && (
            <Flex className="submenu">
              <Flex direction="column" gap={0} className="submenuInner">
              {userRole === "superAdmin" && (
                  <NavLink to={`/${selectedRestaurantId}/settings/`} className="submenuLink">Settings</NavLink>
              )}

                <NavLink className="submenuLink" to='mailto:support@gudden.app?subject=gudden.app technical support request' alt="Technical support" title="Technical support">Technical support</NavLink>
                <NavLink className="submenuLink" to='mailto:support@gudden.app?subject=gudden.app new feature idea'  alt="New feature idea"  title="New feature idea">Submit a new feature</NavLink>
                <NavLink className="submenuLink" onClick={handleLogout}>Logout</NavLink>
              
              </Flex>
              <button onClick={closeSubMenu} className="closeSubmenu">
                Cancel
              </button>
          </Flex>
          )}
          {showOverlay && openSubMenu === "moreActions" && (
            <div className="overlay" onClick={closeSubMenu}></div>
          )}
          </NavLink>

          

        </>
      ) : (
        ""
      )}

   
    </Flex>
  );
};

export default Navigation;
