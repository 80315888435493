import { useState } from "react";
import { db } from "../firebaseConfig";
import { collection, deleteDoc, doc, addDoc, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useAppContext } from "../contexts/AppContext";
import { useRecipeContext } from "../contexts/RecipeContext";

const useSuppliers = () => {
  const { selectedRestaurantId } = useAppContext();
  const { suppliers, setSuppliers, fetchSuppliers } = useRecipeContext();
  const [isEditing, setIsEditing] = useState(null);
  const [supplierHasChanges, setSupplierHasChanges] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const openSupplierModal = (supplier = null) => {
	setSelectedSupplier(supplier);
	if (supplier) {
	  setIsEditing(true);
	} else {
	setIsEditing(false);
	}
  };

  const closeSupplierModal = () => {
	setSelectedSupplier(null);
	setIsEditing(false);
};



  const confirmDeleteSupplier = async (supplierId) => {
    if (window.confirm("Are you sure you want to delete this supplier?")) {
      try {
        const tagRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/suppliers`, supplierId
        );
        await deleteDoc(tagRef);
        setSuppliers(suppliers.filter((i) => i.id !== supplierId));
        toast.success(`Supplier deleted successfully`);
      } catch (error) {
        console.error("Error deleting supplier:", error);
      }
    }
  };

const saveSupplier = async (supplier) => {
	const dbPath = `restaurants/${selectedRestaurantId}/suppliers`;
	try {
	  if (supplier.id) {
		const ingredientRef = doc(db, dbPath, supplier.id);
		await setDoc(ingredientRef, { name: supplier.name });
		await fetchSuppliers();
		toast.success('supplier updated successfully');
	  } else {
		const suppliersColRef = collection(db, `restaurants/${selectedRestaurantId}/suppliers`);
		await addDoc(suppliersColRef, { name: supplier.name });
		await fetchSuppliers();
		toast.success(isEditing ? 'Supplier updated successfully' : 'Supplier added successfully');
	}
	} catch (error) {
	  console.error('Error saving supplier:', error);
	  toast.error('Error saving supplier');
	}
  };
  
  
  return {
    confirmDeleteSupplier,
    saveSupplier,
    isEditing,
    setIsEditing,
    supplierHasChanges,
    setSupplierHasChanges,
	  selectedSupplier, setSelectedSupplier,
	  openSupplierModal, closeSupplierModal
  };
};

export default useSuppliers;
