import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import Modal from 'react-modal';
import useModal from '../../hooks/useModal';
import { Flex, Input, Button, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Grid, Spinner, Text } from "@chakra-ui/react";
import { Utensils, Calendar, Clock, Table, User } from "lucide-react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import DatePicker from 'react-datepicker';
import { db } from '../../firebaseConfig';
import { Timestamp, collection, getDocs, query, where, addDoc, updateDoc } from "firebase/firestore";
import AsyncSelect from 'react-select/async';
import { toast } from "react-toastify";
import useOpeningHours from '../../hooks/useOpeningHours';


Modal.setAppElement('#root');

const initialFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  guests: 2,
  preference: '',
  scheduledTime: '',
  date: new Date(),
  newsletter: false,
};
const AddReservation = ({ isOpen, onClose, isEditing, onDelete  }) => {
  
  const { selectedRestaurantId, toggleSubMenu, openSubMenu, closeSubMenu, showOverlay, settings } = useAppContext();
  const {customStyles} = useModal();
  
  const [reservationHasChanges, setReservationHasChanges] = useState(false);

  const [inputValue, setInputValue] = useState('');
  
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const fetchAllCustomers = async () => {
    const customersRef = collection(db, `restaurants/${selectedRestaurantId}/customers`);
    const snapshot = await getDocs(customersRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  };
  
  const searchCustomersByPhone = async (inputValue) => {
    const allCustomers = await fetchAllCustomers();
    const filteredCustomers = allCustomers.filter(customer => {
      return Object.keys(customer.phoneNumbers).some(phone => phone.includes(inputValue));
    });
    return filteredCustomers.map(customer => ({
      label: `${Object.keys(customer.phoneNumbers).join(', ')} - ${customer.firstName} ${customer.lastName}`,
      value: customer.id,
      firstName: customer.firstName, // Inclure le prénom
      lastName: customer.lastName,   // Inclure le nom de famille
      email: customer.email,         // Inclure l'email
      phoneNumbers: customer.phoneNumbers // Inclure les numéros de téléphone
    }));
  };
  
  
  


  const handleInputChange = newValue => {
    setInputValue(newValue);
  };

  const handleSelectChange = selectedOption => {
    console.log('Selected Option:', selectedOption); // Utile pour le débogage
    setSelectedCustomer(selectedOption);
  
    if (selectedOption) {
      const phoneNumbers = selectedOption.phoneNumbers ? Object.keys(selectedOption.phoneNumbers) : [];
      const primaryPhone = phoneNumbers.length > 0 ? phoneNumbers[0] : '';
  
      setFormData(prevFormData => ({
        ...prevFormData,
        firstName: selectedOption.firstName || '',
        lastName: selectedOption.lastName || '',
        email: selectedOption.email || '',
        phone: primaryPhone
      }));
    } else {
      setFormData(initialFormData);
    }
  };
  
  
  
  
  
  




  const handleClose = () => {
    setActiveIndex(0);
    setSelectedCustomer(null);
    setFormData(initialFormData);
    onClose();
  }  

  const { openingHours = [] } = useOpeningHours() || {};

    const [formData, setFormData] = useState(initialFormData);
    const [slotsAreLoading, setSlotsAreLoading] = useState(false);
    const [selectedGuestNumber, setSelectedGuestNumber] = useState(2);
    const [timeSlots, setTimeSlots] = useState([]);
    const [slotsAvailable, setSlotsAvailable] = useState(true);  // Nouvelle variable d'état
    const [activeIndex, setActiveIndex] = useState(0);
    const [maxGuests, setMaxGuests] = useState(0);

    useEffect(() => {
      const fetchTables = async () => {
        const querySnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/tables`));
        let maxCapacity = 0;
        let combinableTablesCapacity = 0;
        
        // Trouvez la table avec la plus grande capacité
        querySnapshot.forEach((doc) => {
          maxCapacity = Math.max(maxCapacity, doc.data().capacity);
        });
        const tables = querySnapshot.docs.map(doc => doc.data()).sort((a, b) => b.capacity - a.capacity);
        combinableTablesCapacity = tables[0].capacity + (tables[1]?.capacity || 0);
        setMaxGuests(combinableTablesCapacity);
      };
      fetchTables();
    }, []);


    const handleGuestsSelection = (guestsNumber) => {
        setSelectedGuestNumber(guestsNumber);
        setFormData({ ...formData, guests: guestsNumber.toString() });
        setActiveIndex(1);
    };

    const isDateAvailable = (date) => {
        const dayName = date.toLocaleDateString('en-us', { weekday: 'long' });
        const daySettings = openingHours.find((day) => day.day === dayName);
        return !!daySettings?.slots?.length;
      };

   

    const generateTimeSlotsForDay = (day, slots) => {
        const timeSlots = [];
        slots.forEach((slot) => {
          let currentTime = new Date();
          currentTime.setHours(...slot.start.split(':'), 0, 0);
          const endTime = new Date();
          endTime.setHours(...slot.end.split(':'), 0, 0);
          
          while(currentTime < endTime) {
            timeSlots.push(currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }));
            currentTime = new Date(currentTime.getTime() + 15 * 60000);
          }
        });
        return timeSlots;
      };
      
      const selectedDayName = formData.date.toLocaleDateString('en-us', { weekday: 'long' }); // Get the selected day name
      const openingHoursForDay = openingHours.find((day) => day.day === selectedDayName)?.slots || [];
      
      useEffect(() => {
        setTimeSlots(generateTimeSlotsForDay(selectedDayName, openingHoursForDay));
      }, [formData.date, openingHours]);
      

      
      const isTimeSlotPassed = (timeSlot) => {
        const currentTime = new Date();
        const [hours, minutes] = timeSlot.time.split(':');
        const timeSlotDate = new Date(formData.date);
        timeSlotDate.setHours(parseInt(hours), parseInt(minutes), 0, 0);    
        return currentTime > timeSlotDate;
    };
    



      const getSelectedDateString = (date) => {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
      
        if (date.toDateString() === today.toDateString()) {
          return 'Today';
        } else if (date.toDateString() === tomorrow.toDateString()) {
          return 'Tomorrow';
        } else {
          return date.toLocaleDateString('en-us', { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' });
        }
      };

      const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;
        setFormData(prevState => ({
          ...prevState,
          [name]: newValue
        }));
      };
      
      
      console.log('settings.reservationsSettings.serviceDuration', settings.reservationsSettings.serviceDuration);
      

      const findAvailableTables = async (guests, date, time) => {
        guests = Number(guests);
        const startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(time.split(":")[0]), parseInt(time.split(":")[1]), 0);
        const endTime = new Date(startTime.getTime() + (settings.reservationsSettings.serviceDuration * 60000));
    
        const tablesSnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/tables`));
        
        let tableAvailability = new Map();
    
        tablesSnapshot.docs.forEach(doc => {
          let data = doc.data();
          const tableCapacity = Number(data.capacity);  // Convertir la capacité en nombre
          tableAvailability.set(doc.id, {
              ...data,
              id: doc.id,
              available: true,
              capacity: tableCapacity,
              isCombinationEmpty: !data.combination || data.combination.length === 0
          });
      });

      
        const reservationsSnapshot = await getDocs(query(
          collection(db, `restaurants/${selectedRestaurantId}/reservations`),
          where("scheduledTime", "<=", Timestamp.fromDate(endTime))
      ));

        reservationsSnapshot.docs.forEach(doc => {
          const reservation = doc.data();
          const reservationEndTime = new Date(reservation.scheduledTime.toDate().getTime() + settings.reservationsSettings.serviceDuration * 60000);
          
          reservationEndTime.setMilliseconds(0);
          startTime.setMilliseconds(0);
          
          reservation.tables.forEach(tableId => {
            if (reservationEndTime > startTime) {
              tableAvailability.get(tableId).available = false;
            }
          });
        });
      
      
        let availableTables = Array.from(tableAvailability.values())
        .filter(table => table.available)
        .sort((a, b) => a.isCombinationEmpty === b.isCombinationEmpty ? 0 : a.isCombinationEmpty ? -1 : 1); // Prioritize tables with no combinations
    
      return findTablesForGuests(availableTables, guests);

      };

      function findTablesForGuests(tables, guestsNeeded) {
        
        // Trier toutes les tables par leur capacité, ascendante, pour que les plus petites adéquates soient considérées en premier
        tables.sort((a, b) => a.capacity - b.capacity);
    
        // Filtrer pour trouver une table qui correspond exactement ou est plus grande, avec priorité aux tables sans combinaison
        let suitableTable = tables.find(table => table.capacity >= guestsNeeded && table.isCombinationEmpty);
    
        // Si une table convenable est trouvée, retourner immédiatement cette table dans un tableau
        if (suitableTable) {
            return [suitableTable];
        }
    
        // Si aucune table sans combinaison n'est adéquate, essayer de trouver une table sans condition de combinaison
        suitableTable = tables.find(table => table.capacity >= guestsNeeded);
        if (suitableTable) {
            return [suitableTable];
        }
    
        // Si toujours rien, essayer les combinaisons si nécessaire
        return findValidTableCombinations(tables, guestsNeeded);
    }
    
    
        
       
      function findValidTableCombinations(tables, guestsNeeded) {
        let combinations = [];
      
        // Build combinations
        tables.forEach(table => {
          if (table.combination.length > 0) {
            table.combination.forEach(combinationId => {
              let combinedTable = tables.find(t => t.id === combinationId && t.available);
              if (combinedTable) {
                let combinedCapacity = table.capacity + combinedTable.capacity;
                if (combinedCapacity >= guestsNeeded) {
                  let comboKey = [table.id, combinedTable.id].sort().join('|');
                  combinations[comboKey] = combinations[comboKey] || [];
                  combinations[comboKey].push([table, combinedTable]);
                }
              }
            });
          }
        });
      
        // Flatten combinations and remove duplicates
        let uniqueCombinations = Object.values(combinations).map(combo => combo[0]);
        return uniqueCombinations.length > 0 ? uniqueCombinations : [];
      }
      
      
      
    const updateTimeSlots = async () => {
      setSlotsAreLoading(true);
      const availableTimeSlots = [];
      const timeSlots = generateTimeSlotsForDay(selectedDayName, openingHoursForDay);
      for (let timeSlot of timeSlots) {
          const tables = await findAvailableTables(formData.guests, formData.date, timeSlot);
          let slotInfo = {
              time: timeSlot,
              available: tables.length > 0
          };
          availableTimeSlots.push(slotInfo);
      }
      setTimeSlots(availableTimeSlots);
      setSlotsAreLoading(false);
      setSlotsAvailable(availableTimeSlots.some(slot => slot.available));  // Mettre à jour slotsAvailable
  };
  
  
    
    
    useEffect(() => {
      updateTimeSlots();
    }, [formData.guests, formData.date]);



  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const [selectedHour, selectedMinute] = formData.scheduledTime.split(":");
      const reservationDateTime = new Date(formData.date);
      reservationDateTime.setHours(parseInt(selectedHour, 10), parseInt(selectedMinute, 10), 0, 0);
      const timestamp = Timestamp.fromDate(reservationDateTime);
    
      try {
        const customersRef = collection(db, `restaurants/${selectedRestaurantId}/customers`);
        const customerQuery = query(customersRef, where("email", "==", formData.email));
        const customerSnapshot = await getDocs(customerQuery);
    
        let customerId;

        if (!customerSnapshot.empty) {
          const customerDoc = customerSnapshot.docs[0];
          customerId = customerDoc.id;
          // Mise à jour du document client si nécessaire
          await updateDoc(customerDoc.ref, {
            [`phoneNumbers.${formData.phone}`]: true
          });
        } else {
          // Nouveau client
          const customerRef = await addDoc(customersRef, {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumbers: {
              [formData.phone]: true
            },
            newsletter: formData.newsletter
          });
          customerId = customerRef.id;
        }
    
        // Find available tables
        const availableTables = await findAvailableTables(formData.guests, formData.date, formData.scheduledTime);
        const tableIds = availableTables.flat(Infinity).map(table => table.id).filter(id => id !== undefined);
    
        if (tableIds.length === 0) {
          toast.error("No tables available for the selected time slot.");
          return;
        }
    
        // Create reservation
        const docRef = await addDoc(collection(db, `restaurants/${selectedRestaurantId}/reservations`), {
          customer: {
            id: customerId,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phone: `+${formData.phone}`,
          },
          guests: formData.guests,
          tables: tableIds,
          preference: formData.preference,
          scheduledTime: timestamp,
          status: settings.reservationsSettings?.autoConfirm ? 'confirmed' : 'pending',
          newsletter: formData.newsletter,
        });
    
        toast.success("Your reservation request has been successfully submitted. Our team will review the details and confirm your booking promptly.");
        setFormData(initialFormData);
        onClose();
      } catch (error) {
        console.error("Error adding document: ", error);
      }
    };



    


  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} className={'modal'} style={customStyles}>
      
      <div className="modal-content" >
      <form onSubmit={(e) => e.preventDefault()}>
      
      <div className="modalTopBar">

            <div className="closeModal">
              <span className="close" onClick={handleClose}> 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z" fill="black"/>
                </svg>  
              </span>
            </div>         
            <div className="modalTitle">Add a reservation</div>   
            <div className="actionButtons">
            {reservationHasChanges && (
               <button type="submit" className="simple" onClick={handleSubmit}>
               {isEditing? 'Save changes' : 'Save'}
              </button>
            )}
            
            {isEditing && (
            <div className='btn simple itemActions subMenuToggle' onClick={toggleSubMenu('tagActions')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z" fill="black"/>
              </svg>
              
              {openSubMenu === 'tagActions' && (
                <div className='submenu'>
                  <ul>
                    <li onClick={onDelete} style={{ color: 'var(--error)' }}>Delete reservation</li>
                  </ul>
                  <button onClick={closeSubMenu} className='closeSubmenu'>Cancel</button>
                </div>
              )}
            {showOverlay && openSubMenu === 'tagActions' && (
       					 <div className="overlay" onClick={closeSubMenu}></div>
        			)}
            </div>
             )}
            </div>
  </div>
        
        
        <Flex className='modalInner' p={0}>

      

        <Flex direction="column" >
        
    

          <Accordion index={activeIndex} onChange={(index) => setActiveIndex(index)}>
                    <AccordionItem borderColor='var(--borders)' borderTop="none">
                        <h6>
                        <AccordionButton>
                            <Flex flex='1' gap={3} alignItems="center" color="var(--grey600)" pt={2} pb={2}>
                                <User size={16} color="var(--grey400)"/>
                                {formData.firstName && formData.lastName ? `${formData.firstName} ${formData.lastName}` : "Customer" }
                           </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                        </h6>
                    <AccordionPanel pb={4}>
                    <Flex direction={"column"} gap={2}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={searchCustomersByPhone} // Changez la fonction ici
                      onInputChange={handleInputChange}
                      onChange={handleSelectChange}
                      placeholder="Enter customer's phone number..."
                      isClearable={true}
                      className="customSelect-container"
                      classNamePrefix="customSelect"
                    />
                  
                     <Input border="1px solid var(--borders)" required color="var(--grey600)" placeholder="First Name" name="firstName" type="text" onChange={handleChange} value={formData.firstName} isDisabled={selectedCustomer}  />
                     <Input border="1px solid var(--borders)" required color="var(--grey600)" placeholder="Last Name" name="lastName" type="text" onChange={handleChange} value={formData.lastName} isDisabled={selectedCustomer}  />
                     <Input border="1px solid var(--borders)" required color="var(--grey600)" placeholder="Email" name="email" type="email" onChange={handleChange} value={formData.email} isDisabled={selectedCustomer}  />
                     <PhoneInput
                        country={'lu'}
                        value={formData.phone}
                        onChange={(phone) => setFormData({ ...formData, phone: phone || '' })}
                        containerStyle={{ width: '100%', marginTop: '8px' }}
                        inputStyle={{
                          width: '100%',
                          height: '37.5px',
                          border: '1px solid var(--borders)',
                          background: 'transparent',
                          color: 'var(--grey600)',
                          fontWeight: '400 !important',
                          fontFamily: 'apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important',
                        }}
                      />                  
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
                
                <AccordionItem borderColor='var(--borders)'>
                        <h6>
                        <AccordionButton>
                            
                            <Flex flex='1' gap={3} alignItems="center" color="var(--grey600)" pt={2} pb={2}>
                                <Utensils size={16} color="var(--grey400)"/>
                                {selectedGuestNumber} guests
                           </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                        </h6>
                        <AccordionPanel pb={4}>
                  <Grid gridTemplateColumns="repeat(5, 1fr)" gridGap={2}>
                  {[...Array(maxGuests)].map((_, i) => (
                      <Button 
                        bg={selectedGuestNumber === i + 1 ? 'var(--grey100)' : 'transparent'}
                        color={selectedGuestNumber === i + 1 ? '#121212' : 'var(--grey500)'}
                        border={selectedGuestNumber === i + 1 ? "1px solid var(--grey600)" : "1px solid var(--borders)"} 
                        key={i} 
                        onClick={() => handleGuestsSelection(i + 1)}>
                        {i + 1}
                      </Button>
                    ))}
                     <Button 
                        bg="transparent"
                        color="var(--grey600)"
                        border="1px solid var(--borders)"
                        onClick={() => toast.error(`Please contact us by phone for reservations over ${maxGuests} guests`)}
                      >
                        +
                      </Button>
                  </Grid>
                </AccordionPanel>
                </AccordionItem>

                <AccordionItem borderColor='var(--borders)'>
                  <AccordionButton>
                  <Flex flex='1' gap={3} alignItems="center" color="var(--grey600)" pt={2} pb={2}>
                                <Calendar size={16} color="var(--grey400)"/>
                                {getSelectedDateString(formData.date)} 
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <DatePicker
                      selected={formData.date}
                      onChange={(date) => {
                        setFormData({ ...formData, date });
                        setActiveIndex(activeIndex + 1);
                      }}
                      dateFormat="MMMM d, yyyy"
                      minDate={new Date()}
                      filterDate={isDateAvailable}
                      inline
                    />
                  </AccordionPanel>
                </AccordionItem>
                

                <AccordionItem borderColor='var(--borders)'>
                        <h6>
                        <AccordionButton>
                        <Flex flex='1' gap={3} alignItems="center" color="var(--grey600)" pt={2} pb={2}>
                                <Clock size={16} color="var(--grey400)"/>
                                {formData.scheduledTime ? formData.scheduledTime : "Time"}
                           </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                        </h6>
                    <AccordionPanel pb={4}>
                      
                      
                    {slotsAreLoading ? (
  <Flex justifyContent="center" alignItems="center" height="32px">
    <Spinner size="lg" color="var(--grey400)" />
  </Flex>
) : (
  slotsAvailable ? (
    <Grid templateColumns="repeat(3, 1fr)" gap={2} maxHeight={"260px"} overflowY="auto" pl={4} pr={4}>
      {timeSlots.map(({ time, available }, index) => (
        <Button
          size="sm"
          fontSize="14px"
          fontWeight="400"
          key={index}
          onClick={() => available && setFormData(prevState => ({ ...prevState, scheduledTime: time, activeIndex: prevState.activeIndex + 1 }))}
          bg={formData.scheduledTime === time ? 'var(--grey100)' : 'transparent'}
          color={formData.scheduledTime === time ? '#121212' : 'var(--grey500)'}
          border={formData.scheduledTime === time ? "1px solid var(--grey600)" : "1px solid var(--borders)"} 
          isDisabled={!available || isTimeSlotPassed({ time })}
        >
          {time}
        </Button>
      ))}
    </Grid>
  ) : (
    <Text color="#121212" fontSize="16px" textAlign="center" p={4}>
      No availability for {selectedGuestNumber} guests on the selected day.
    </Text>
  )
)}
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem borderColor='var(--borders)' borderBottom="none">
                        <h6>
                        <AccordionButton>
                        <Flex flex='1' gap={3} alignItems="center" color="var(--grey600)" pt={2} pb={2}>
                            <Table size={16} color="var(--grey400)"/>
                                Table selection
                           </Flex>
                            <AccordionIcon />
                        </AccordionButton>
                        </h6>
                    <AccordionPanel pb={4}>
                   
                    Coming soon

                    </AccordionPanel>
                </AccordionItem>


                
                    


                </Accordion>
          </Flex>

          </Flex>
          <Button onClick={handleSubmit}>Complete Reservation</Button>

        
        </form>
      </div>
    </Modal>
  );
};

export default AddReservation;
