import React, { useState } from "react";
import ReservationStatusSelector from "../components/reservations/ReservationStatusSelector";
import useReservations from "../hooks/useReservations";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useAppContext } from "../contexts/AppContext";
import { Flex, Box, Text, Table, Tbody, Thead, Th, Tr, Td, Button, Link, useColorModeValue } from "@chakra-ui/react";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import DatePicker from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import { formatRelative, isToday, isTomorrow, isYesterday } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';
import AddReservation from "../components/reservations/AddReservation";



const Reservations = () => {
  
  const { selectedRestaurantId, formatScheduledTime, menuAdminBarZIndex } = useAppContext();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const {reservations, updateReservationStatus, isReservationModalOpen, setIsReservationModalOpen} = useReservations(selectedDate, selectedDate);
  const [selectedReservation, setSelectedReservation] = useState(null);


  const openReservationModal = (reservation) => {
    setSelectedReservation(reservation);
    setIsReservationModalOpen(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const goPreviousDay = () => {
    setSelectedDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() - 1);
      return newDate;
    });
  };

  const goNextDay = () => {
    setSelectedDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + 1);
      return newDate;
    });
  };

  const datePickerButtonStyle = useColorModeValue('solid', 'outline');


  const CustomDatePickerInput = ({ value, onClick }) => (
    <Button onClick={onClick} size="sm" variant={datePickerButtonStyle}>
      {value}
    </Button>
  );

  return (


    <>
    

      <Flex w='100%' className="widget" direction="column" gap={8}>
      <Flex className="sectionHeader row spaceBetween">
       
       <Flex gap={2}>
        <h3>Reservations</h3>
        <Button className="btn secondary" size="sm" onClick={openReservationModal}>Add a reservation</Button>
        </Flex>
        <Flex alignItems="center" gap="2">
      <Button onClick={goPreviousDay} size="sm" variant={datePickerButtonStyle}>
        <ChevronLeft size={20} />
      </Button>
      <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="MMMM d, yyyy"
          customInput={<CustomDatePickerInput />}
          locale={enGB}
          minDate={new Date()}
        />

      <Button onClick={goNextDay} size="sm" variant={datePickerButtonStyle}>
        <ChevronRight size={20} />
      </Button>
    </Flex>
      </Flex>

      <Flex w='100%' direction="column" gap={8}>
        {reservations.length > 0 ? (
          <div className="tableWrapper">
            <Table w="100%">
              <Thead display={{ desktop: "table-header-group", mobile: "none" }}>
                <Tr className="reservationHeader">
                <Th>Scheduled time</Th>
                <Th>Customer</Th>
                <Th>Guests</Th>
                <Th>Preference</Th>
                <Th>Assigned table</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody fontSize="14px">
                {reservations.map((reservation) => {
                  return (
                    <Tr key={reservation.id} className="reservation">
                      <Td>
                        <span className="tag">
                          {reservation.scheduledTime
                            ? formatScheduledTime(reservation.scheduledTime.toDate())
                            : "N/A"}
                        </span>
                      </Td>
                      
                      <Td >
                      <b>{reservation.customer.firstName}{" "}{reservation.customer.lastName}</b>
                      </Td>
                      <Td>
                      {reservation.guests}                      
                      </Td>
                      <Td>
                        <Text>{reservation.preference}</Text>
                      </Td>
                      <Td >
                        <Flex gap={2} direction="row">
                        {reservation.tables.map((table, index) => (
                         <Box className="tag">{table}</Box>
                        ))}
                        </Flex>
                      </Td>
                      <Td
                        >
                          <ReservationStatusSelector 
                            currentStatus={reservation.status}
                            onUpdateStatus={updateReservationStatus}
                            reservationId={reservation.id}
                          />
                      </Td>
                      <Td>
                        <Link href={`tel:${reservation.customer.phone}`}><Button size="sm" className="btn secondary">
                          Call
                        </Button>
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        ) : (
          <p>No reservations found.</p>
        )}
      </Flex>
      </Flex>

      <AddReservation
        isOpen={isReservationModalOpen}
        onClose={() => setIsReservationModalOpen(false)}
        reservation={selectedReservation}
      />

      
    </>
  );
};

export default Reservations;
