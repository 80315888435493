import Navigation from "../components/navigation/Navigation";
import MobileNavigation from "../components/navigation/MobileNavigation";
import useWindowWidth from "../hooks/useWindowWidth";
import { Flex } from "@chakra-ui/react";
import TopHeader from "../components/navigation/TopHeader";
import PendingOrders from "../components/shop/PendingOrders";
import { useAppContext } from "../contexts/AppContext";


function BackendLayout({ children }) {

  const { settings } = useAppContext();
  
  /* useMessaging(); */

    
  const NavigationComponent =
    useWindowWidth() < 960 ? MobileNavigation : Navigation;

  return (
    <Flex direction="row" w="100vw">
      <NavigationComponent />
      <Flex direction="column" flex={{ desktop: "1", mobile: "1" }}  >
        <TopHeader/>
        <Flex direction="column" flex={{ desktop: "1", mobile: "1" }} >
          {children}
        </Flex>
      </Flex>
      {(settings.shopSettings.delivery.activation ||  settings.shopSettings.takeAway.activation) &&
        <PendingOrders />
      }
    </Flex>
  );
}
export default BackendLayout;
