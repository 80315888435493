import React, { useState } from "react";
import Modal from "react-modal";
import { useAppContext } from "../../contexts/AppContext";
import { Button, } from "@chakra-ui/react";
import PdfSettingsModal from "./pdf/PdfSettingsModal";
import { useMenuContext } from "../../contexts/MenuContext";
import ImportModal from "./importModal";
import useImportExport from "../../hooks/useImportExport";
import { pdf } from "@react-pdf/renderer";
import QrPDF from "./pdf/QrPDF";


Modal.setAppElement("#root");


const OptionsManager = () => {

  const {
    selectedRestaurantId,
    toggleSubMenu,
    showOverlay,
    closeSubMenu,
    openSubMenu,
    settings,
    mainLanguage,
  } = useAppContext();
  
  const { categories, itemsByCategory, menuItems, currentMainCategory } = useMenuContext();
  const { importModalIsOpen, setImportModalIsOpen, closeImportModal  } = useImportExport();
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);


  
    
  const { handleExport } = useImportExport();

  const openImportModal = () => setImportModalIsOpen(true);
  const openPdfModal = () => setPdfModalIsOpen(true);
  
  const [pdfLanguage, setPdfLanguage] = useState(
    settings.language && settings.language.length > 0
      ? settings.language[0].value
      : ""
  );
  const closePdfModal = () => {
    setPdfModalIsOpen(false);
    setPdfLanguage("");
  };

  const openQrPdf = async () => {
    const blob = await pdf(
      <QrPDF selectedRestaurantId={selectedRestaurantId}/>
    ).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };
  

  return (
    <>
   
       <Button size="sm" className="btn secondary" onClick={toggleSubMenu("CsvActions")}>Actions</Button>


        {openSubMenu === "CsvActions" && (
          <div className="submenu">
            <ul>
              <li
                onClick={() =>
                  window.open(
                    `https://gudden.app/${selectedRestaurantId}/menu`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }>
                {" "}
                View menu
              </li>

              {(settings.shopSettings?.delivery?.activation ||
                settings.shopSettings?.takeAway?.activation) && (
                <li
                  onClick={() =>
                    window.open(
                      `https://gudden.app/${selectedRestaurantId}/shop`,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }>
                  {" "}
                  View shop
                </li>
              )}
              <li
                onClick={() => {
                  openImportModal();
                  closeSubMenu();
                }}>
                Import CSV
              </li>
              <li
                onClick={() => {
                  handleExport();
                  closeSubMenu();
                }}>
                Export to CSV
              </li>
              <li onClick={openPdfModal}>Print</li>
              
              <li onClick={openQrPdf}>QR Code</li>
            
            </ul>
            <button onClick={closeSubMenu} className="closeSubmenu">
              Cancel
            </button>
          </div>
        )}
        {showOverlay && openSubMenu === "CsvActions" && (
          <div className="overlay" onClick={closeSubMenu}></div>
        )}

      <ImportModal 
        isOpen={importModalIsOpen}
        setImportModalIsOpen={setImportModalIsOpen}
        onClose={closeImportModal}
        
      />
      <PdfSettingsModal
        onClose={closePdfModal}
        isOpen={pdfModalIsOpen}
        categories={categories}
        itemsByCategory={itemsByCategory}
        menuItems={menuItems}
        selectedRestaurantId={selectedRestaurantId}
        currentMainCategory={currentMainCategory}
        mainLanguage={mainLanguage}
        settings={settings}
        selectedLanguage={pdfLanguage}
        setSelectedLanguage={setPdfLanguage}
      />
    </>
  );
};

export default OptionsManager;
