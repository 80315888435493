import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import MenuToPDF from "./MenuToPDF";
import { pdf } from "@react-pdf/renderer";
import { Flex, FormLabel, Button } from "@chakra-ui/react";


Modal.setAppElement("#root");

const PdfSettingsModal = ({ isOpen, onClose, categories, selectedRestaurantId, itemsByCategory, menuItems, currentMainCategory, mainLanguage, settings, selectedLanguage, setSelectedLanguage }) => {
  const { customStyles } = useModal();
  
  const allLanguages = settings.language.concat(settings.secondaryLanguages);

  
  const openMenuToPdf = async () => {
    const blob = await pdf(
      <MenuToPDF
        selectedRestaurantId={selectedRestaurantId}
        categories={categories}
        itemsByCategory={itemsByCategory}
        menuItems={menuItems}
        settings={settings}
        mainLanguage={mainLanguage}
        selectedLanguage={selectedLanguage}
        currentMainCategory={currentMainCategory}
      />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={"modal"}
      style={customStyles}>
      <div className="modal-content">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <div className="modalTitle">Print menu in .pdf</div>
            <div className="actionButtons"></div>
          </div>

          <div className="modalInner">
            <Flex direction="column" gap={4}>
            <Flex direction="column" gap={0}>
              <FormLabel>Select a language</FormLabel>
                <select
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}>
                  {allLanguages.map((lang, index) => (
                    <option key={index} value={lang.value}>
                      {lang.label}
                    </option>
                  ))}
                </select>
              </Flex>
                <Button size="lg" className="btn primary" onClick={openMenuToPdf}>
                  Generate pdf
                </Button>
            </Flex>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default PdfSettingsModal;
