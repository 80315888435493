
import { MenuProvider } from "../contexts/MenuContext";


function MenuLayout({children}) {
  

  return (
    <MenuProvider>
      {children}
    </MenuProvider>
  );
}
export default MenuLayout;
