// OrderModal.js
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useAppContext } from "../../contexts/AppContext";
import StatusSelector from "./StatusSelector";
import useModal from "../../hooks/useModal";
import RefundModal from "./RefundModal";
import OrderInvoice from "./OrderInvoice";
import { Flex, Box, Link, Grid, Text,Image,  Table, Tbody, Thead, Th, Tr, Td } from "@chakra-ui/react";
import { User, ShoppingBag } from "lucide-react";

import { pdf } from "@react-pdf/renderer";

Modal.setAppElement("#root");

const OrderModal = ({
  isOpen,
  onClose,
  order,
  onDelete,
  onUpdate,
  processRefund,
  formatScheduledTime
}) => {
  const {
    selectedRestaurantId,
    toggleSubMenu,
    openSubMenu,
    closeSubMenu,
    showOverlay,
    mainLanguage,
    generalSettings
  } = useAppContext();

  const [orderStatus, setOrderStatus] = useState(order?.status);
  const { customStyles } = useModal();
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  useEffect(() => {
    if (order) {
      setOrderStatus(order.status);
    }
  }, [order]);
  if (!order) return null;

  const openInvoice = async (order) => {
    const blob = await pdf(<OrderInvoice order={order} generalSettings={generalSettings} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const calculateSubtotal = (item) => {
    const price = parseFloat(item.price.replace(",", "."));    
    const subtotal = price * item.quantity;
    return subtotal.toFixed(2).replace(".", ",");
  };

  const deliveryFeeNumber = parseFloat(order.deliveryFee);


  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={"modal"}
        style={customStyles}>
        <Flex className="modal-content orderModal" w="960px">
          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <Flex className="modalTitle" direction="row" gap={2} w="fit-content" maxWidth="fit-content" alignItems={"center"}>
              Order #{order.id}{" "}
              <StatusSelector
                currentStatus={orderStatus}
                onUpdateStatus={onUpdate}
                orderId={order.id}
              />
            </Flex>
            <div className="actionButtons">
              <div
                className="btn simple itemActions subMenuToggle"
                onClick={toggleSubMenu("mainCategoryActions")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z"
                    fill="black"
                  />
                </svg>

                {openSubMenu === "mainCategoryActions" && (
                  <div className="submenu">
                    <ul>
                      {" "}
                      {order.status === "completed" && (
                        <li onClick={() => setIsRefundModalOpen(true)}>
                          Refund
                        </li>
                      )}
                      {order.status === "completed" && (
                        <li onClick={() => openInvoice(order)}>
                          Download invoice
                        </li>
                      )}
                     
                      <li
                        onClick={() => onDelete(selectedRestaurantId, order.id)}
                        style={{ color: "var(--error)" }}>
                        Delete order
                      </li>
                    </ul>
                    <button onClick={closeSubMenu} className="closeSubmenu">
                      Cancel
                    </button>
                  </div>
                )}
                {showOverlay && openSubMenu === "mainCategoryActions" && (
                  <div className="overlay" onClick={closeSubMenu}></div>
                )}
              </div>
            </div>
          </div>

          <Flex className="modalInner" p={16} gap={16}>
                <Grid direction="row" templateColumns={{ desktop: '1fr 1fr', mobile :'1fr' }} columnGap={16} rowGap={16} alignItems="flex-start">
              
                <Flex direction="row" gap={4}>
                    <Flex bg="var(--grey100)" h="62px" w="62px" minWidth="62px" alignItems="center" justifyContent="center" borderRadius={"42px"}>
                      <User size={24} color="var(--backend-text-color)"  />
                    </Flex>
                    <Flex direction="column" gap={2} flex={1}>
                    <h5>Customer</h5>
                    <Table size="sm" flex={1} className="detailsTable">
                      <Tr>
                        <Td w="72px"><Text fontSize="12px">Name</Text></Td>
                        <Td fontWeight="500">{order.customerDetails.firstName}{" "}{order.customerDetails.lastName}</Td>
                      </Tr>
                      <Tr>
                        <Td><Text fontSize="12px">Email</Text></Td>
                        <Td><Link href={`mailto:${order.customerDetails.email}`}>{order.customerDetails.email}</Link></Td>
                      </Tr>
                      <Tr>
                        <Td><Text fontSize="12px">Phone</Text></Td>
                        <Td><a href={`tel:${order.customerDetails.phone}`}>{order.customerDetails.phone}</a></Td>
                      </Tr>
                      <Tr>
                        <Td><Text fontSize="12px">Address</Text></Td>
                        <Td>{order.customerDetails.address}</Td>
                      </Tr>
                      <Tr>
                        <Td><Text fontSize="12px">Zip & City</Text></Td>
                        <Td>{order.customerDetails.postalCode}{" "}{order.customerDetails.city}</Td>
                      </Tr>
                      {(order.deliveryNote && order.deliveryMode === "delivery") &&
                      <Tr>
                        <Td><Text fontSize="12px">Note</Text></Td>
                        <Td>{order.deliveryNote}</Td>
                      </Tr>
                      }
                    </Table>
                    </Flex>
                </Flex>

                <Flex direction="row" gap={4}>
                    <Flex bg="var(--grey100)" h="62px" w="62px" minWidth="62px" alignItems="center" justifyContent="center" borderRadius={"42px"}>
                      <ShoppingBag size={24} color="var(--backend-text-color)"  />
                    </Flex>
                    <Flex direction="column" gap={2} flex={1}>
                    <h5>Order info</h5>
                    
                    <Table size="sm" flex={1} className="detailsTable">
                      <Tr gap={2} alignItems="baseline" >
                        <Td w="72px"><Text fontSize="12px">Order ID</Text></Td>
                        <Td fontWeight="500">#{order.id}</Td>
                      </Tr>
                      <Tr gap={2} alignItems="baseline" >
                        <Td><Text fontSize="12px">Payment</Text></Td>
                        <Td textTransform="capitalize">{order.paymentMethod}</Td>
                      </Tr>
                      <Tr gap={2} alignItems="baseline" >
                        <Td><Text fontSize="12px">Status</Text></Td>
                        <Td>{order.paymentStatus}</Td>
                      </Tr>
                      <Tr gap={2} alignItems="baseline" >
                        <Td w="72px"><Text fontSize="12px">Method</Text></Td>
                        <Td textTransform="capitalize">{order.deliveryMode}</Td>
                      </Tr>
                      <Tr gap={2} alignItems="baseline" >
                        <Td><Text fontSize="12px">Time</Text></Td>
                        <Td>{order.scheduledTime
                            ? formatScheduledTime(order.scheduledTime.toDate())
                            : "N/A"}
                        </Td>
                      </Tr>
                    </Table>
                    </Flex>
                </Flex>

                
              </Grid>

            <Flex direction="column" gap={4}>
              <h5>Order details</h5>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Item</Th>
                    <Th isNumeric>Price</Th>
                    <Th isNumeric>Qty</Th>
                    <Th isNumeric>Subtotal</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {order.cartItems.map((item, index) => (
                    <Tr key={index}>
                      <Td>
                        <Flex gap={4} alignItems={"center"}>
                          <Box background="var(--grey100)" borderRadius={8} overflow="hidden" w="52px" h="52px">
                            {item.photo && 
                            <Image src={item.photo} aspectRatio="1/1" objectFit="cover" objectPosition="center" w="52px" h="52px"/>
                            }
                          </Box>
                          <Flex direction="column" gap={1}>
                          <Box fontWeight={"500"}>{item[`name_${mainLanguage}`]}</Box>
                          {item.extras && item.extras.length > 0 && (
                          <Box className="extrasList">
                            <p>Extras</p>
                            {item.extras.map((extra, extraIndex) => (
                              <span key={extraIndex} className="extra">
                                {extra.name}
                                {extraIndex < item.extras.length - 1 && (
                                  <span> - </span>
                                )}
                              </span>
                            ))}
                          </Box>
                        )}
                        {item.note && (
                          <>
                            <p>Note</p> <mark>{item.note}</mark>
                          </>
                        )}
                          </Flex>
                        </Flex>
                      </Td>
                      <Td isNumeric>{item.price}€</Td>
                      <Td isNumeric>{item.quantity}</Td>
                      <Td isNumeric>{calculateSubtotal(item)}€</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Flex>

            <Flex direction="column" gap={4} w="40%" alignSelf="flex-end">
              <Table>
                <Tbody>
                  {order.tip > 0 && (
                    <Tr>
                      <Td>Tip</Td>
                      <Td isNumeric>{order.tip}€</Td>
                    </Tr>
                  )}
                  {order.deliveryFee > 0 && (
                    <Tr>
                      <Td>Delivery fee</Td>
                      <Td isNumeric>{deliveryFeeNumber.toFixed(2).replace(".", ",")}€</Td>
                    </Tr>
                  )}
                  {order.serviceFee > 0 && (
                    <Tr>
                      <Td>Service fee</Td>
                      <Td isNumeric>{order.serviceFeeTTC.toFixed(2).replace(".", ",")}€</Td>
                    </Tr>
                  )}
                  
                  <Tr>
                    <Td><strong>Total</strong></Td>
                    <Td isNumeric><strong>{order.total}€</strong></Td>
                  </Tr>
                  {order?.refundedAmount && (
                    <Tr>
                      <Td>
                        <span style={{ color: "var(--error)" }}>Refunded</span>
                      </Td>
                      <Td>
                        <span style={{ color: "var(--error)" }}>
                          {order.refundedAmount}€
                        </span>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Flex>
          {order.cancellationNote &&
            <Flex direction="column" gap={2}>
            <h6>Cancellation note</h6>
            <Box>{order.cancellationNote}</Box>
            </Flex>
          }</Flex>
          
        </Flex>
      </Modal>

      <RefundModal
        isOpen={isRefundModalOpen}
        onRequestClose={() => setIsRefundModalOpen(false)}
        order={order}
        processRefund={processRefund}
      />
    </>
  );
};

export default OrderModal;
