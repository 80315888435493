import { Flex, Text, Box } from "@chakra-ui/react";
import OpeningHoursModal from "./OpeningHoursModal";
import useOpeningHours from "../../hooks/useOpeningHours";

const OpeningHours = ({ openingHoursModalIsOpen, setOpeningHoursModalIsOpen }) => {

  const { openingHours } = useOpeningHours();
 
  const closeHoursModal = () => {
    setOpeningHoursModalIsOpen(false); // Ferme le modal
  };

  return (
    <Flex direction="column" gap={2} className="openingHoursForm">
      <Flex direction="column">
        
      {openingHours && openingHours.length > 0 ? (
        openingHours.map((day, dayIndex) => (
          <Flex direction="row" key={dayIndex} justifyContent="space-between" borderBottom="1px solid var(--grey150)" pt={2} pb={2}>
            <Text color="black" fontSize="md">{day.day || "Day"}</Text>
            <Flex direction="column" gap={2}>
              {day.slots.length > 0 ? (
                day.slots.map((slot, slotIndex) => (
                  <Flex direction="row" key={slotIndex}>
                    <Text>
                      {slot.start} - {slot.end}
                    </Text>
                  </Flex>
                ))
              ) : (
                <Box className="tag error">Closed</Box>
              )}
            </Flex>
          </Flex>
        )) 
        ) : (
          <Text>Opening hours are not set.</Text>
        )}
      </Flex>

      <OpeningHoursModal
        isOpen={openingHoursModalIsOpen} // Utilisez l'état correct ici
        onClose={closeHoursModal} // Utilisez la fonction qui modifie l'état
      />
    </Flex>
  );
};

export default OpeningHours;
