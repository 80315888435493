import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import useOpeningHours from '../../hooks/useOpeningHours';
import { Button, Flex, Text, IconButton, Box } from '@chakra-ui/react';
import { X } from 'lucide-react';
import ModalTopBar from "../modal/ModalTopBar";

Modal.setAppElement("#root");

const OpeningHoursModal = ({ isOpen, onClose }) => {
  const { openingHours, handleSaveOpeningHours, handleSlotChange, handleRemoveSlot, handleAddSlot } = useOpeningHours();
  const { customStyles } = useModal();

  const handleSave = () => {
    handleSaveOpeningHours();
    onClose();
  };

  const createOptions = (values, currentValue, padZero = false) => {
    return values.map((value) => (
      <option key={value} value={padZero ? String(value).padStart(2, '0') : value}>
        {padZero ? String(value).padStart(2, '0') : value}
      </option>
    ));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={"modal"}
      style={customStyles}>
        <div className="modal-content">
          <ModalTopBar onClose={onClose} onSave={handleSave} title="Set opening hours" />
          <Flex direction="column" gap={2} className="modalInner">
            {openingHours.map((day, dayIndex) => (
              <Flex direction="row" key={dayIndex} justifyContent="space-between">
                <Text fontSize="md">{day.day || 'Day'}</Text>
                <Flex direction="column" gap={2}>
                  {day.slots.length > 0 ? (
                    day.slots.map((slot, slotIndex) => (
                      <Flex direction="row" gap={2} key={slotIndex} alignItems="center" className="timeSelect">
                        <Flex direction="row" gap={1}>
                          <select 
                            value={slot.start ? slot.start.split(':')[0] : '00'}
                            onChange={(e) => handleSlotChange(dayIndex, slotIndex, 'start', `${e.target.value}:${slot.start ? slot.start.split(':')[1] : '00'}`)}>
                            {createOptions(Array.from({length: 24}, (_, i) => i), slot.start ? slot.start.split(':')[0] : '00', true)}
                          </select>:
                          <select 
                            value={slot.start ? slot.start.split(':')[1] : '00'}
                            onChange={(e) => handleSlotChange(dayIndex, slotIndex, 'start', `${slot.start ? slot.start.split(':')[0] : '00'}:${e.target.value}`)}>
                            {createOptions([0, 15, 30, 45], slot.start ? slot.start.split(':')[1] : '00', true)}
                          </select>
                        </Flex> 
                        -
                        <Flex direction="row" gap={1}>
                          <select 
                            value={slot.end ? slot.end.split(':')[0] : '00'}
                            onChange={(e) => handleSlotChange(dayIndex, slotIndex, 'end', `${e.target.value}:${slot.end ? slot.end.split(':')[1] : '00'}`)}>
                            {createOptions(Array.from({length: 24}, (_, i) => i), slot.end ? slot.end.split(':')[0] : '00', true)}
                          </select>:
                          <select 
                            value={slot.end ? slot.end.split(':')[1] : '00'}
                            onChange={(e) => handleSlotChange(dayIndex, slotIndex, 'end', `${slot.end ? slot.end.split(':')[0] : '00'}:${e.target.value}`)}>
                            {createOptions([0, 15, 30, 45], slot.end ? slot.end.split(':')[1] : '00', true)}
                          </select>
                        </Flex>
                        <IconButton variant='outline' onClick={() => handleRemoveSlot(dayIndex, slotIndex)} icon={<X />}/>
                      </Flex>
                    ))
                  ) : (
                    <Box className="tag error">Closed</Box>
                  )}
                  <Button size="xs" onClick={() => handleAddSlot(dayIndex)}>Add Slot</Button>
                </Flex>
              </Flex>
            ))}
          </Flex >
        </div>
    </Modal>
  );
};

export default OpeningHoursModal;
