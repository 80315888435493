// useCategoryData.js

import { useState } from "react";
import { db, storage } from "../firebaseConfig";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { toast } from 'react-toastify';
import { useAppContext } from "../contexts/AppContext";
import useMenuData from "./useMenuData";
import { useRecipeContext } from "../contexts/RecipeContext";



const useRecipes = ({ instructions = '', image = null, currentRecipe, setImage } = {}) => {
	
	const { selectedRestaurantId, mainCategoryName } = useAppContext();
	const { ingredients, selectedIngredients, setSelectedIngredients, fetchRecipes } = useRecipeContext();

	const [hasChanges, setHasChanges] = useState(false);
	
	const saveRecipe = async () => {
		const isVariationItem = currentRecipe.id.includes('-');
		let itemId = currentRecipe.id;
	  
		// Si l'item est une variation, extraire l'ID de l'item d'origine
		if (isVariationItem) {
		  itemId = itemId.split('-')[0];
		}
	  
		const menuItemRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`, itemId);
	  
		// Charger les données existantes de l'item pour récupérer ou initialiser le champ `recipes`
		const menuItemSnap = await getDoc(menuItemRef);
		let recipes = menuItemSnap.exists() && menuItemSnap.data().recipes ? menuItemSnap.data().recipes : [];
	  
		// Préparer les données de la nouvelle recette
		let newRecipe = {
		  ingredients: selectedIngredients.map(({ ingredientId, quantity }) => ({
			ingredientId,
			quantity: parseFloat(quantity),
		  })),
		  instructions,
		  photo: image && image.preview ? image.preview : "",
		};
	  
		// Ajouter l'identifiant de variation à la nouvelle recette si nécessaire
		if (isVariationItem) {
		  newRecipe.variationId = currentRecipe.id;
		}
	  
		// Si l'item est une variation, mettre à jour ou ajouter la recette spécifique à la variation
		// Sinon, traiter comme un item sans variations
		if (isVariationItem) {
		  const existingRecipeIndex = recipes.findIndex(r => r.variationId === currentRecipe.id);
		  if (existingRecipeIndex > -1) {
			recipes[existingRecipeIndex] = newRecipe;
		  } else {
			recipes.push(newRecipe);
		  }
		} else {
		  // Pour les items sans variations, remplacer ou définir le tableau des recettes
		  recipes = [newRecipe]; // Remplacez par [newRecipe] pour les éléments sans variations
		}
	  
		// Sauvegarder la mise à jour dans Firestore
		try {
		  await updateDoc(menuItemRef, { recipes });
		  fetchRecipes();
		  toast.success(`Recipe ${isVariationItem ? 'for variation ' : ''}saved successfully.`);
		} catch (error) {
		  console.error("Error saving recipe:", error);
		  toast.error("Error saving recipe.");
		}
	  };
	  
	  
	
	  
	  
	  
	
	

	const deleteImage = async () => {
		if (currentRecipe?.photo) {
		  const imageRef = ref(storage, currentRecipe.photo);
		  try {
			await deleteObject(imageRef);
			// Mettre à jour la recette dans Firestore pour enlever le lien de l'image
			const recipeRef = doc(db, `recipes/${currentRecipe.id}`);
			await updateDoc(recipeRef, {
			  photo: "" // ou null, selon ce que votre base de données attend pour une image absente
			});
			console.log("Image deleted successfully from server and Firestore");
		  } catch (error) {
			console.error("Error removing image from server:", error);
			// Afficher une erreur à l'utilisateur si nécessaire
		  }
		}
		setImage(null);
		if (image && image.preview) {
		  URL.revokeObjectURL(image.preview);
		}
	  };

	
	  
	  const calculateTotalPrice = () => {
		return selectedIngredients.reduce((total, { ingredientId, quantity }) => {
		  const ingredient = ingredients.find(ing => ing.id === ingredientId);
		  if (!ingredient) return total; 
		  const pricePerGram = parseFloat(ingredient.price) / 1000;
		  return total + (pricePerGram * parseFloat(quantity));
		}, 0).toFixed(2); 
	  };
	  
	  
	  const calculateTotalPriceForEach = (item, allIngredients) => {
		// Si l'item a des recettes, trouvez celle correspondant à l'item ou à sa variation
		if (item.recipes && item.recipes.length > 0) {
		  const itemOrVariationRecipe = item.recipes.find(
			r => item.variationId ? r.variationId === item.variationId : true
		  );
	  
		  // Calculer le coût total pour cette recette spécifique
		  if (itemOrVariationRecipe) {
			return itemOrVariationRecipe.ingredients.reduce((total, { ingredientId, quantity }) => {
			  const ingredient = allIngredients.find(ing => ing.id === ingredientId);
			  if (!ingredient) return total;
			  const pricePerGram = parseFloat(ingredient.price) / 1000;
			  return total + (pricePerGram * parseFloat(quantity));
			}, 0).toFixed(2);
		  }
		}
		// Si l'item n'a pas de recettes ou si la recette spécifique n'est pas trouvée, retournez 'N/A'
		return 'N/A';
	  };
	  
			
	  
	

	return {
		setHasChanges,
		saveRecipe,
		calculateTotalPrice,
		calculateTotalPriceForEach,
		deleteImage
	};
};

export default useRecipes;
