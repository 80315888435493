import { Flex, Skeleton } from "@chakra-ui/react";


function SkeletonLayout() {
  

  return (
    <Flex
        padding={{ desktop: "3vw 3vw 124px 3vw", mobile: "0 0 172px 0" }}
        maxW="1320px"
        direction={{ desktop: "row", mobile: "column" }} 
        gap={8} 
        flex="1">

        <Flex flex={1} direction="column" gap={4}>
          <Skeleton height='320px' w="100%" borderRadius="var(--radius-sm)" />
          <Skeleton height='320px' w="100%" borderRadius="var(--radius-sm)" />
          <Skeleton height='320px' w="100%" borderRadius="var(--radius-sm)" />
        </Flex>
        <Flex as="aside" flex={{ desktop: "0 0 320px", mobile: "1" }} direction="column" gap={8}>
            <Skeleton height='200px' w="100%" borderRadius="var(--radius-sm)" />
            <Skeleton height='200px' w="100%" borderRadius="var(--radius-sm)" />
            <Skeleton height='200px' w="100%" borderRadius="var(--radius-sm)" />

        </Flex>

    </Flex>
  );
}
export default SkeletonLayout;
