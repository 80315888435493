// EditExtrasGroup.js
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import Modal from 'react-modal';
import useModal from '../../hooks/useModal';
import Select from 'react-select';
import { Flex, Button, Input } from '@chakra-ui/react';
import { X } from "lucide-react";



Modal.setAppElement('#root');

const EditExtrasGroup = ({ extrasGroup, onSave, onClose, onDelete, isOpen, isEditing, setIsEditing, groupDetails, setGroupDetails, categories, items, applicableTo, setApplicableTo, selectedOptions, setSelectedOptions, currentExtrasGroup }) => {
  
  const { toggleSubMenu, openSubMenu, closeSubMenu, showOverlay, mainLanguage } = useAppContext();
  const {customStyles} = useModal();
  const [ extrasGroupHasChanges, setExtrasGroupHasChanges ] = useState(false);
  const [extrasList, setExtrasList] = useState(extrasGroup && extrasGroup.items ? extrasGroup.items : []);
  
    
  const options = {
    categories: categories.map(cat => ({ value: cat.id, label: cat['name_' + mainLanguage] })),
  };
  
  const getItemOptions = () => {
    const itemOptions = [];
    for (const category in items) {
      items[category].forEach(item => {
        itemOptions.push({ value: item.id, label: item['name_' + mainLanguage] });
      });
    }
    return itemOptions;
  };

  useEffect(() => {
    if (!extrasGroup) {
      setExtrasList([]); 
      setApplicableTo('categories'); 
      setSelectedOptions([]);
      setGroupDetails({
        name: '',
        items: [],
        applicableTo: 'categories',
        selectedOptions: [],
      });
    } else {
      setExtrasList(Array.isArray(extrasGroup.items) ? extrasGroup.items : []);
      setApplicableTo(extrasGroup.applicableTo || 'categories');
      setSelectedOptions(extrasGroup.applicableToOptions?.map(optionId => {
        
        let optionLabel = categories.find(cat => cat.id === optionId)?.[`name_${mainLanguage}`];
        
        if (!optionLabel) {
            for (const category in items) {
                const foundItem = items[category].find(item => item.id === optionId);
                if (foundItem) {
                    optionLabel = foundItem['name_' + mainLanguage];
                    break;
                }
            }
        }
        
        // Retourne l'option avec le label trouvé
        return { value: optionId, label: optionLabel || 'Label not found' };
    }) || []);      
      setGroupDetails(extrasGroup);
    }
  }, [extrasGroup]);
  
  

    const handleApplicableToChange = (selectedOption) => {
      setApplicableTo(selectedOption.value);
      setSelectedOptions([]);
    };

    const handleSelectedOptionsChange = (selectedOptions) => {
      setSelectedOptions(selectedOptions);
      setExtrasGroupHasChanges(true);
    };

    const handleNameChange = (e) => {
      setGroupDetails(prevDetails => ({ ...prevDetails, name: e.target.value }));
      setExtrasGroupHasChanges(true);
  };
  

    const handleAddExtra = () => {
      const newExtra = { name: '', price: '0' };
      const updatedExtras = [...extrasList, newExtra];
      setExtrasList(updatedExtras);
      setGroupDetails({ ...groupDetails, items: updatedExtras });
    };

    const handleExtraChange = (index, field, value) => {
        const updatedExtras = [...extrasList];
        updatedExtras[index][field] = value;
        setExtrasList(updatedExtras);
        setGroupDetails({ ...groupDetails, items: updatedExtras });
        setExtrasGroupHasChanges(true);
    };
      
    const handleRemoveExtra = (index) => {
        const updatedExtras = [...extrasList];
        updatedExtras.splice(index, 1);
        setExtrasList(updatedExtras);
        setGroupDetails({ ...groupDetails, items: updatedExtras });
        setExtrasGroupHasChanges(true);
    };

      const handleSubmit = (event) => {
        event.preventDefault();
        const groupToSave = {
            ...groupDetails,
            applicableTo,
            applicableToOptions: selectedOptions.map(option => option.value),
        };
        if (currentExtrasGroup?.id) {
            onSave(groupToSave, currentExtrasGroup.id);
        } else {
            onSave(groupToSave);
        }
      };


   

  
  
      const isFormValid = groupDetails.name.trim() !== '' && extrasList.every(extra => extra.name.trim() !== '');

    
  
  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={'modal'} style={customStyles}>
      
      <div className="modal-content" >
      <form onSubmit={handleSubmit}>
      <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={onClose}> 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z" fill="black"/>
                </svg>  
              </span>
            </div>         
            <div className="modalTitle">{isEditing ? "Edit extras group" : "Add new extras group"}</div>   
            <div className="actionButtons">
            {isFormValid && extrasGroupHasChanges && (
              <button type="submit" className="simple" onClick={(e) => handleSubmit(e)}>
               {isEditing? 'Save changes' : 'Save'}
              </button>
            )}
            
            {isEditing && (
            <div className='btn simple itemActions subMenuToggle' onClick={toggleSubMenu('extrasGroupActions')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z" fill="black"/>
              </svg>
              
              {openSubMenu === 'extrasGroupActions' && (
                <div className='submenu'>
                  <ul>
                    <li onClick={() => onDelete(currentExtrasGroup.id)} style={{ color: 'var(--error)' }}>Delete group</li>
                  </ul>
                  <button onClick={closeSubMenu} className='closeSubmenu'>Cancel</button>
                </div>
              )}
            {showOverlay && openSubMenu === 'extrasGroupActions' && (
       					 <div className="overlay" onClick={closeSubMenu}></div>
        			)}
            </div>
             )}
            </div>
  </div>
        
        
        <Flex className='modalInner'>

        <div className="field">
        <label>Group name</label>
        <Input
            type="text"
            value={groupDetails.name}
            className="nameInput"
            onChange={handleNameChange}
            placeholder="Group Name"
            required
          />
          </div>

          <div className="field">
            <label>Selection type</label>
            <select
              value={groupDetails.selectionType}
              onChange={e => setGroupDetails({...groupDetails, selectionType: e.target.value})}
              className="custom-select">
              <option value="multiple">Multiple choice</option>
              <option value="unique">Unique choice</option>
            </select>
          </div>

          <div className="separator"></div>


      <Flex direction="column" gap={4}>
        <h6>Options</h6>
        {extrasList && extrasList.map((extra, index) => (
                    <Flex direction="row" key={index} gap={4} alignItems="center">
                        <Input
                            type="text"
                            value={extra.name}
                            onChange={(e) => handleExtraChange(index, 'name', e.target.value)}
                            placeholder="Extra name"
                            required
                            flex={1}
                        />
                        <Input
                            type="number"
                            value={extra.price}
                            onChange={(e) => handleExtraChange(index, 'price', parseFloat(e.target.value))}
                            placeholder="Price"
                            min="0"
                            flex="0 0 72px !important"
                        />
                        <Button
                          size="xs"
                          onClick={() => handleRemoveExtra(index)}>
                          <X size={16} />
                        </Button>
                    
                    </Flex>
        ))}
        <Button type="button" className="secondary" onClick={handleAddExtra}>Add option</Button>
        </Flex>
        <div className="separator"></div>

        <Flex direction="column" gap={2}>
        <h6>Apply to</h6>
          <div className='field'>
          <Select
        value={{ value: applicableTo, label: applicableTo }}
        onChange={handleApplicableToChange}
        options={[
          { value: 'categories', label: 'Categories' },
          { value: 'items', label: 'Items' }
        ]}
          className="customSelect-container"
          classNamePrefix="customSelect"
      /></div>
      <div className='field'>
        <Select
          value={selectedOptions}
          onChange={handleSelectedOptionsChange}
          options={applicableTo === 'categories' ? options.categories : getItemOptions()}
          isMulti='true'
          className="customSelect-container"
          classNamePrefix="customSelect"
        />
        </div>
        </Flex>
        
        
      </Flex>     

        
        </form>
      </div>
    </Modal>
  );
};

export default EditExtrasGroup;