// MenuContext.js
import React, { createContext, useState, useContext } from 'react';


const MenuContext = createContext();

export const useMenuContext = () => useContext(MenuContext);
export const MenuProvider = ({ children }) => {
  
  const [mainCategories, setMainCategories] = useState([]);
  const [currentMainCategory, setCurrentMainCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [itemsByCategory, setItemsByCategory] = useState({});
  const [menuItems, setMenuItems] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  
    
  const value = {
    mainCategories, setMainCategories,
    currentMainCategory, setCurrentMainCategory,
    categories, setCategories, 
    itemsByCategory, setItemsByCategory,
    menuItems, setMenuItems,
    tags, setTags,
    selectedTags, setSelectedTags
  };

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};
