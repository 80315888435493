import { Flex } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";

function ReservationsLayout({ children }) {
  const { selectedRestaurantId, userRole, menuAdminBarZIndex, settings } = useAppContext();

  return (
    <>
    <Flex
          justifyContent="space-between"
          direction="row"
          w="100%"
          alignItems="center"
          position="sticky"
          top="0"
          bg="white"
          pl={8}
          pr={8}
          maxWidth="100vw"
          borderBottom="1px solid var(--borders)"
          minHeight="45px"
          style={{ zIndex: menuAdminBarZIndex }}
          >
          
          <Flex className="tabLinks">
          <NavLink to={`/${selectedRestaurantId}/reservations`} className="tabLink" end>Reservations</NavLink>
          <NavLink to={`/${selectedRestaurantId}/reservations/tableplan`} className="tabLink">Table plan</NavLink>
          </Flex>
    </Flex>

    <Flex direction="column"  padding={{ desktop: "3vw 3vw 124px 3vw", mobile: "0 0 172px 0" }} gap={8} maxW="1320px">
    
    {children}

    </Flex>

    </>

  );
}
export default ReservationsLayout;
