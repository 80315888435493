import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { db } from "../../firebaseConfig";
import { doc, updateDoc, writeBatch } from "firebase/firestore";
import useStrictModeDroppable from "../../hooks/useStrictModeDroppable"; // Adjust the import path as necessary
import { useAppContext } from "../../contexts/AppContext";
import { Flex, Button, Text, Box } from "@chakra-ui/react";
import { GripVertical, CircleAlert, EllipsisVertical } from "lucide-react";
import { Tooltip } from "react-tooltip";

const StrictModeDroppableComponent = ({ droppableId, children }) => {
  const { enabled } = useStrictModeDroppable();

  if (!enabled) {
    return null;
  }

  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => children(provided, snapshot)}
    </Droppable>
  );
};
 
const MenuList = ({
  categories,
  mainCategoryName,
  itemsByCategory,
  setItemsByCategory,
  onCategoryDelete,
  addItemWithCategory,
  openEditModal,
  currentMainCategory,
  openSubCategoryModal,
  onDuplicate,
  confirmAndDelete,
  toggleAvailability,
}) => {
  const { selectedRestaurantId, mainLanguage, userRole, settings, closeSubMenu, setMenuAdminBarZIndex } = useAppContext();
  
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0); // État pour stocker la position initiale du scroll
  const [maxWidth, setMaxWidth] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);

  const updateMinWidth = () => {
    const dishPriceElements = document.querySelectorAll(".dish-price");
    let maxFoundWidth = 0;

    dishPriceElements.forEach((element) => {
      const width = element.offsetWidth;
      if (width > maxFoundWidth) {
        maxFoundWidth = width;
      }
    });

    setMaxWidth(maxFoundWidth);
  };

  useEffect(() => {
    const observer = new MutationObserver(updateMinWidth);
    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    const sourceItems = [...itemsByCategory[source.droppableId]];
    const destinationItems =
      source.droppableId === destination.droppableId
        ? sourceItems
        : [...itemsByCategory[destination.droppableId]];

    const [removed] = sourceItems.splice(source.index, 1);
    destinationItems.splice(destination.index, 0, removed);

    const updatedRemovedItem = {
      ...removed,
      categorieId: destination.droppableId,
    };

    const newItemsByCategory = {
      ...itemsByCategory,
      [source.droppableId]: sourceItems,
      [destination.droppableId]: destinationItems.map((item) =>
        item.id === updatedRemovedItem.id ? updatedRemovedItem : item
      ),
    };
    setItemsByCategory(newItemsByCategory);
    await updateItemCategoryAndOrderInFirestore(
      updatedRemovedItem,
      destination.droppableId,
      destinationItems
    );
  };

  const updateItemsOrderInFirestore = async (items, categoryId) => {
    const batch = writeBatch(db);
    items.forEach((item, index) => {
      const itemRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
        item.id
      );
      batch.update(itemRef, { order: index });
    });
    await batch.commit();
  };

  const updateItemCategoryAndOrderInFirestore = async (
    item,
    newCategoryId,
    newCategoryItems
  ) => {
    const itemRef = doc(
      db,
      `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
      item.id
    );
    await updateDoc(itemRef, {
      categorieId: newCategoryId,
      order: newCategoryItems.indexOf(item),
    });
    await updateItemsOrderInFirestore(newCategoryItems, newCategoryId);
  };

  const confirmCategoryDeletion = (categoryId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this category?"
    );
    if (isConfirmed) {
      onCategoryDelete(categoryId);
    }
  };

  const getDisplayStyles = (display) => {
    switch (display) {
      case "menu":
        return {
          color: "white",
          backgroundColor: "var(--blue400)",
        };
      case "shop":
        return {
          color: "white",
          backgroundColor: "var(--success)",
        };
      default:
        return {
          color: "white",
          backgroundColor: "var(--brand)",
        };
    }
  };

  const secondaryLanguages = settings.secondaryLanguages.map(lang => lang.value);
  const areTranslationsMissing = (item, settings) => {
      return secondaryLanguages.some(lang => {
      return (
        (item[`name_${mainLanguage}`] && !item[`name_${lang}`]) ||
        (item[`description_${mainLanguage}`] && !item[`description_${lang}`]) ||
        (item[`note_${mainLanguage}`] && !item[`note_${lang}`])
      );
    });
  };

  const toggleSubMenu = (itemId) => (e) => {
    e.stopPropagation();
    if (openSubMenu === itemId) {
      setOpenSubMenu(null);
      setShowOverlay(false);
    } else {
      setOpenSubMenu(itemId);
      setShowOverlay(true);
      setScrollPosition(window.scrollY); // Capture la position actuelle du scroll
    }
  };
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openSubMenu && !event.target.closest(".subMenuToggle")) {
        setOpenSubMenu(null); // Ferme le sous-menu
        setShowOverlay(false); // Masque l'overlay
      }
    };
  
    const handleScroll = () => {
      if (openSubMenu && Math.abs(window.scrollY - scrollPosition) > 200) {
        setOpenSubMenu(null); // Ferme le sous-menu après avoir défilé de 100px
        setShowOverlay(false); // Masque l'overlay
      }
    };
  
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", handleScroll);
  
    // Nettoyage des événements lors du démontage du composant
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [openSubMenu]); // Relance l'effet uniquement lorsque `openSubMenu` change
  
  


  return (
    
    <Flex direction="column" flexGrow="1" minW="0">
      <DragDropContext onDragEnd={handleOnDragEnd}>
      {showOverlay && (
        <div className="overlay" onClick={closeSubMenu}></div>
      )}
  <Flex
          direction="column"
          className="menuItems"
          gap={{ desktop: "var(--padding-sm)", mobile: "0" }}>
          {categories
            .filter((cat) => cat.id !== "uncategorized")
            .map((category) => {
              if (!category || !itemsByCategory[category.id]) return null;
              return (
                <StrictModeDroppableComponent
                  key={category.id}
                  droppableId={category.id}>
                  {(provided) => (
                    <Flex
                      direction="column"
                      gap="var(--padding-xs)"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="menu-category widget"
                      id={category.id}>
                      <Flex direction={"column"} gap={1}>
                        <Flex
                        direction="row"
                        className="category-title"
                        justifyContent="space-between">
                        <h5>{category[`name_${mainLanguage}`]}</h5>
                        <Flex direction="row" gap={2}>
                          <Button
                            size="sm"
                            className="btn secondary"
                            onClick={() => openSubCategoryModal(category)}>
                            Edit
                          </Button>
                        </Flex>
                      </Flex>
                      
                        {category[`description_${mainLanguage}`] && <Text fontSize="17px">{category[`description_${mainLanguage}`]}</Text>}
                      
                      </Flex>
                      <Flex className="dishes-grid" direction="column">
                        {itemsByCategory[category.id] &&
                        itemsByCategory[category.id].length > 0 ? (
                          itemsByCategory[category.id].map((item, idx) => (
                            
                           
                              <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={idx}>
                              {(provided) => {
                                const missingTranslations = areTranslationsMissing(item);
                                return (
                                  <Flex
                                    direction="row"
                                    justifyContent="space-between"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="dish"
                                    onClick={() => openEditModal(item)}>
                                    <Flex className="dish-name">
                                      <GripVertical
                                        size={16}
                                        color="var(--grey500)"
                                      />
                                      {(currentMainCategory?.imageDisplay ||
                                        category.style === "Cards") && (
                                        <div className="dish-img">
                                          {item.photo && (
                                            <img
                                              src={item.photo}
                                              alt={item.name}
                                            />
                                          )}
                                        </div>
                                      )}
                                      <Box>{item[`name_${mainLanguage}`]}</Box>
                                      {missingTranslations && (
                                        <>
                                          <CircleAlert
                                            size={16}
                                            color="var(--error)"
                                            data-tooltip-id={`missingTranslations${item.id}`}
                                            data-tooltip-content="Missing translations"
                                            data-tooltip-place="right"
                                          />
                                          <Tooltip
                                            id={`missingTranslations${item.id}`}
                                            className="toolTip"></Tooltip>
                                        </>
                                      )}
                                    </Flex>

                                    {userRole === "superAdmin" && (
                                      <Text
                                        fontSize="10px"
                                        maxWidth="20px"
                                        whiteSpace="nowrap"
                                        overflow="hidden">
                                        {item.id}
                                      </Text>
                                    )}

                                    <Flex
                                      direction="row"
                                      justifyContent="flex-end"
                                      className="dish-info">
                                      <Flex className="dishAvailability">
                                        {!item.availability && (
                                          <span
                                            className="tag sm"
                                            style={{
                                              color: "white",
                                              backgroundColor: "var(--error)",
                                            }}
                                          >
                                            Unavailable
                                          </span>
                                        )}
                                      </Flex>
                                      <Flex className="dish-display mobile-hidden">
                                        {item.display &&
                                        Array.isArray(item.display) &&
                                        item.display.length > 0 ? (
                                          item.display
                                            .sort((a, b) => {
                                              if (a === "menu") return -1;
                                              if (b === "menu") return 1;
                                              if (a === "shop") return -1;
                                              if (b === "shop") return 1;
                                              return 0;
                                            })
                                            .map((display, index) => (
                                              <span
                                                className="tag sm"
                                                key={index}
                                                style={getDisplayStyles(
                                                  display
                                                )}>
                                                {display}
                                              </span>
                                            ))
                                        ) : (
                                          <span
                                            className="tag sm"
                                            style={{
                                              color: "white",
                                              backgroundColor: "var(--error)",
                                            }}>
                                            Unpublished
                                          </span>
                                        )}
                                      </Flex>

                                      <div
                                        className="dish-price"
                                        style={{ minWidth: maxWidth + "px" }}>
                                        {item.type === "variable" ? (
                                          Array.isArray(item.variations) ? (
                                            <div className="tag sm">
                                              {item.variations.length}{" "}
                                              variations
                                            </div>
                                          ) : null
                                        ) : (
                                          <>{item.price}€</>
                                        )}
                                      </div>
                                      <Flex
                                        alignItems={"center"}
                                        className="itemQuickActions subMenuToggle"
                                        cursor="pointer" 
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleSubMenu(item.id)(e);
                                        }}>
                                        <EllipsisVertical
                                          size={20}
                                          color="var(--grey500)"
                                        />
                                        {openSubMenu === item.id && (
                                          <Flex className="submenu">
                                            <ul>
                                            <li onClick={() => toggleAvailability(item)}>
                                              {item.availability ? 'Mark as unavailable' : 'Mark as available'}
                                            </li>
                                              <li
                                                onClick={() =>
                                                  onDuplicate(item)
                                                }>
                                                Duplicate item
                                              </li>
                                              <li
                                                onClick={() =>
                                                  confirmAndDelete(item.id)
                                                }
                                                style={{
                                                  color: "var(--error)",
                                                }}>
                                                Delete item
                                              </li>
                                            </ul>
                                            <button
                                              onClick={closeSubMenu}
                                              className="closeSubmenu">
                                              Cancel
                                            </button>
                                          </Flex>
                                        )}
                                        
                                      </Flex>
                                    </Flex>
                                  </Flex>
                                );}}
                            </Draggable>
                          ))
                        ) : (
                          <Flex
                            direction="column"
                            justifyContent={"center"}
                            p={8}
                            textAlign={"center"}
                            alignItems={"center"}
                            gap={4}>
                            <Text>This category is empty.</Text>
                            <Flex direction={"row"} gap={2}>
                              <div
                                className="text-link error"
                                onClick={() =>
                                  confirmCategoryDeletion(category)
                                }>
                                Delete category?
                              </div>
                              or
                              <div
                                onClick={() => addItemWithCategory(category.id)}
                                className="text-link">
                                Add an item
                              </div>
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                      <Button
                        size="sm"
                        className="btn secondary"
                        onClick={() => addItemWithCategory(category.id)}
                        width="fit-content">
                        Add item
                      </Button>
                      {provided.placeholder}
                    </Flex>
                  )}
                </StrictModeDroppableComponent>
              );
            })}
        </Flex>
      </DragDropContext>
      
    </Flex>
  );
};

export default MenuList;
