import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Importez useNavigate
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import useModal from "../hooks/useModal";
import logo from "../images/guddenApp.svg";
import { useSelectedRestaurant } from "../contexts/SelectedRestaurantContext";
import { Flex, Link, Button, Text } from "@chakra-ui/react";

function Login({ onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const adjustHeight = useModal();
  const navigate = useNavigate(); // Utilisez useNavigate
  const { setSelectedRestaurantId } = useSelectedRestaurant();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onLoginSuccess();
      navigate(`/${localStorage.getItem("lastSelectedRestaurantId")}`);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Flex style={{ height: "calc(var(--vh, 1vh) * 100)" }} justifyContent="center" alignItems="center" w="100%">
      <Flex direction="column" className="login-container" justifyContent="space-around" maxWidth="100%" w="320px" height="100%">
        <Link
          href="/"
          className="logo"
          title="Retour au menu"
          style={{ width: "82px" }}>
          <img src={logo} alt="logo"  />
        </Link>
        <div className="login-inside">
          <div className="welcome-txt">
            <h3>Welcome!</h3>
            <p>Log in into your account</p>
          </div>

          <form onSubmit={handleSubmit} className="login-form">
            <div className="field">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="field">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </div>
            <Button size="lg" type="submit" className="btn primary" fontSize="16px">
              Log in
            </Button>
            {error && (
              <p
                style={{
                  color: "var(--red)",
                  position: "absolute",
                  bottom: "-30px",
                }}>
                {error}
              </p>
            )}
          </form>
        </div>
        <div className="bottom-text">
          <Text>© Good Robot Sarls. All rights reserved.</Text>
        </div>
      </Flex>
    </Flex>
  );
}

export default Login;
