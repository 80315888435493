// EdiTagModal.js
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import Modal from 'react-modal';
import useModal from '../../hooks/useModal';
import { Flex, Button } from "@chakra-ui/react";
import { TwitterPicker } from "react-color";


Modal.setAppElement('#root');

const EditTagModal = ({ tag, secondaryLanguages, onSave, onClose, onDelete, isOpen, isEditing, setIsEditing, tagName, setTagName, mainLanguage, setSelectedTag }) => {
  
  const { toggleSubMenu, openSubMenu, closeSubMenu, showOverlay, settings } = useAppContext();

  const {customStyles} = useModal();
  const [tagHasChanges, setTagHasChanges] = useState(false);
  const [color, setColor] = useState(tag?.color || '#defaultColor');
  const [selectedNameLanguage, setSelectedNameLanguage] = useState(settings.language[0].value); // Initial language for name


  const handleColorChange = (color) => {
    setColor(color.hex);
    setTagHasChanges(true);
  };
  


  const handleTagChange = (lang, value) => {
    setTagName(prev => ({ ...prev, [lang]: value }));
    setTagHasChanges(true);
  };   

  
  const handleSave = async () => {
    const tagData = { ...tag, color: color };
    const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
    allLanguages.forEach(lang => {
      tagData[`name_${lang.value}`] = tagName[lang.value] || '';
    });
    await onSave(tagData); 
    onClose(); 
  };
  
  
  
  
  
  useEffect(() => {
    if (isOpen) {
      if (tag && tag.id) {
        const initialNameState = {};
        const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
        allLanguages.forEach(lang => {
          initialNameState[lang.value] = tag[`name_${lang.value}`] || '';
        });
        setTagName(initialNameState);
      } else {
        const initialNameState = {};
        const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
        allLanguages.forEach(lang => {
          initialNameState[lang.value] = '';
        });
        setTagName(initialNameState);
      }
    }
    // Pas besoin de réinitialiser l'état à la fermeture ici, car onClose s'en chargera.
  }, [isOpen, tag, settings]);
  
  
  
  
  const renderTagFields = () => {
    const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
    return (
      <Flex className="field" gap={3}>
        <Flex justifyContent={"space-between"} alignItems={"flex-end"}>
          <label>Name</label>
          <Flex className="languageButtons" gap={1}>
            {allLanguages.map((lang) => (
              <Button key={lang.value} onClick={() => setSelectedNameLanguage(lang.value)} p={0} height="unset" minWidth="unset" borderRadius={"16px"} overflow={"hidden"} className={selectedNameLanguage === lang.value ? "active" : ""} opacity='.4' title={lang.label}>
                <img src={`/images/flags/${lang.value}.svg`} alt={lang.label} width="18px" height="18px" />
              </Button>
            ))}
          </Flex>
        </Flex>
        <input
          type="text"
          name={`name_${selectedNameLanguage}`}
          id={`name_${selectedNameLanguage}`}
          placeholder={`Tag (${selectedNameLanguage})`}
          value={tagName[selectedNameLanguage] || ''}
          onChange={(e) => handleTagChange(selectedNameLanguage, e.target.value)}
        />
      </Flex>
    );
  };
  

  

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={'modal'} style={customStyles}>
      
      <div className="modal-content" >
      <form onSubmit={(e) => e.preventDefault()}>
      
      <div className="modalTopBar">

            <div className="closeModal">
              <span className="close" onClick={onClose}> 
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z" fill="black"/>
                </svg>  
              </span>
            </div>         
            <div className="modalTitle">Edit tag</div>   
            <div className="actionButtons">
            {tagHasChanges && (
               <button type="submit" className="simple" onClick={handleSave}>
               {isEditing? 'Save changes' : 'Save'}
              </button>
            )}
            
            {isEditing && (
            <div className='btn simple itemActions subMenuToggle' onClick={toggleSubMenu('tagActions')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z" fill="black"/>
              </svg>
              
              {openSubMenu === 'tagActions' && (
                <div className='submenu'>
                  <ul>
                    <li onClick={onDelete} style={{ color: 'var(--error)' }}>Delete tag</li>
                  </ul>
                  <button onClick={closeSubMenu} className='closeSubmenu'>Cancel</button>
                </div>
              )}
            {showOverlay && openSubMenu === 'tagActions' && (
       					 <div className="overlay" onClick={closeSubMenu}></div>
        			)}
            </div>
             )}
            </div>
  </div>
        
        
        <div className='modalInner'>

        {renderTagFields()}

        <Flex className='field'>
          <label>Color</label>
          <TwitterPicker color={color} onChangeComplete={handleColorChange} />
        </Flex>

          </div>

        
        </form>
      </div>
    </Modal>
  );
};

export default EditTagModal;
