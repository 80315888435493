import Select from 'react-select';


const RestaurantDropdown = ({ options, selectedValues, onAdd, onRemove }) => {
   
    const handleRestaurantChange = (selectedOptions) => {
        // Filter out options that were removed
        const removedOptions = selectedValues.filter(value => !selectedOptions.some(option => option.value === value));
        removedOptions.forEach(value => onRemove(value));

        // Filter out options that were added
        const addedOptions = selectedOptions.filter(option => !selectedValues.includes(option.value));
        addedOptions.forEach(option => onAdd(option.value));
    };

   
  
    return (
        <Select
        isMulti
        onChange={handleRestaurantChange}
        options={options.map(option => ({ value: option.id, label: option.name }))}
        value={selectedValues.map(value => ({ value, label: options.find(option => option.id === value).name }))}
    />
    );
  };

  export default RestaurantDropdown;