import { useState } from "react";
import { db } from "../firebaseConfig";
import { collection, deleteDoc, doc, addDoc, setDoc, query, where, getDoc, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { useAppContext } from "../contexts/AppContext";
import { useRecipeContext } from "../contexts/RecipeContext";
import * as XLSX from "xlsx";
import Papa from "papaparse";

const useIngredients = () => {
  const { selectedRestaurantId, userData } = useAppContext();
  const { ingredients, setIngredients, fetchIngredients, supplierOptions } = useRecipeContext();
  const [isEditing, setIsEditing] = useState(false);
  const [ingredientHasChanges, setIngredientHasChanges] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);


  const openIngredientModal = (ingredient = null) => {
	setSelectedIngredient(ingredient);
	setIsEditing(!!ingredient);
  };

  const closeIngredientModal = () => {
	setSelectedIngredient(null);
	setIsEditing(false);
};


  const confirmDeleteIngredient = async (ingredientId) => {
    if (window.confirm("Are you sure you want to delete this ingredient?")) {
      try {
        const tagRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/ingredients`, ingredientId
        );
        await deleteDoc(tagRef);
        setIngredients(ingredients.filter((i) => i.id !== ingredientId));
        toast.success(`Ingredient deleted successfully`);
      } catch (error) {
        console.error("Error deleting ingredient:", error);
      }
    }
  };


  
  const saveIngredient = async (ingredient) => {
    const dbPath = `restaurants/${selectedRestaurantId}/ingredients`;
    const currentDateTime = new Date().toLocaleString('fr-FR', { timeZone: 'Europe/Paris' });
    const username = userData.name || "Unknown User"; // Utilise le nom de l'utilisateur ou une valeur par défaut
  
    const newLog = {
      date: currentDateTime,
      action: ingredient.id ? `${username} edited ingredient` : `${username} added ingredient`,
    };
  
    const normalizedSupplier = {
      id: ingredient.supplier?.id || ingredient.supplier?.value,
      name: ingredient.supplier?.name || ingredient.supplier?.label,
    };
  
    try {
      if (ingredient.id) {
        const ingredientRef = doc(db, dbPath, ingredient.id);
        const docSnapshot = await getDoc(ingredientRef);
        const existingData = docSnapshot.data();
        const updatedHistory = existingData.history ? [newLog, ...existingData.history] : [newLog];
  
        await setDoc(ingredientRef, {
          articleId: ingredient.articleId,
          name: ingredient.name,
          unit: ingredient.unit,
          price: ingredient.price,
          quantity: ingredient.quantity,
          supplier: normalizedSupplier,
          history: updatedHistory, // Ajout du nouveau log en tête de liste
        }, { merge: true });
        await fetchIngredients();
        toast.success('Ingredient updated successfully');
      } else {
        const ingredientsColRef = collection(db, dbPath);
        await addDoc(ingredientsColRef, {
          articleId: ingredient.articleId,
          name: ingredient.name,
          unit: ingredient.unit,
          price: ingredient.price,
          quantity: ingredient.quantity,
          supplier: normalizedSupplier,
          history: [newLog], // Création de l'historique avec une entrée initiale
        });
        await fetchIngredients();
        toast.success('Ingredient added successfully');
      }
    } catch (error) {
      console.error('Error saving ingredient:', error);
      toast.error('Error saving ingredient');
    }
  };
  
  
  

  const deleteIngredient = (ingredientId) => {
    confirmDeleteIngredient(ingredientId);
    setSelectedIngredient(null);
  };


  // Fonction pour lire le fichier importé
  const handleOrderImport = async (file) => {
    const reader = new FileReader();
  
    reader.onload = async (e) => {
      const data = e.target.result;
      let parsedData = [];
  
      try {
        if (file.type === "text/csv") {
          Papa.parse(data, {
            header: true,
            complete: function(results) {
              parsedData = results.data;
              saveIngredientsToFirebase(parsedData);
            },
          });
        } else {
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          parsedData = sheet;
          saveIngredientsToFirebase(parsedData);
        }
      } catch (error) {
        console.error('Error processing the file:', error);
        toast.error('Error processing the file');
      }
    };
  
    if (file.type === "text/csv") {
      reader.readAsText(file);
    } else {
      reader.readAsBinaryString(file);
    }
  };


  const extractNumericPrice = (priceString) => {
    const match = priceString.match(/[\d,.]+/);
    return match ? parseFloat(match[0].replace(',', '.')) : 0;
  };

  // Fonction pour enregistrer les données dans Firebase
  const saveIngredientsToFirebase = async (data) => {
    const dbPath = `restaurants/${selectedRestaurantId}/ingredients`;
    const currentDateTime = new Date().toLocaleString('fr-FR', { timeZone: 'Europe/Paris' });
    const username = userData.name || "Unknown User"; // Utilise le nom de l'utilisateur ou une valeur par défaut
  
    const selectedSupplier = supplierOptions.find(supplier => supplier.label === "La Provençale");
    const normalizedSupplier = {
      id: selectedSupplier?.value || selectedSupplier?.id,
      name: selectedSupplier?.label || selectedSupplier?.name,
    };
  
    try {
      for (let item of data) {
        const q = query(collection(db, dbPath), where("articleId", "==", item.articleNumber));
        const querySnapshot = await getDocs(q);
  
        const numericPrice = extractNumericPrice(item.unitPrice);
        const newLog = {
          date: currentDateTime,
          action: `${username} imported ${item.quantity} ${item.sellingUnitName}`,
        };
  
        if (!querySnapshot.empty) {
          querySnapshot.forEach(async (doc) => {
            const existingData = doc.data();
            const newQuantity = existingData.quantity + item.quantity;
            const updatedHistory = existingData.history ? [newLog, ...existingData.history] : [newLog];
  
            await setDoc(doc.ref, { 
              ...existingData, 
              quantity: newQuantity,
              price: numericPrice,
              supplier: normalizedSupplier,
              history: updatedHistory, // Mise à jour de l'historique
            }, { merge: true });
          });
          toast.success(`Quantity updated for ${item.articleName}`);
        } else {
          await addDoc(collection(db, dbPath), {
            articleId: item.articleNumber,
            name: item.articleName,
            unit: item.sellingUnitName,
            price: numericPrice,
            quantity: item.quantity,
            supplier: normalizedSupplier,
            history: [newLog], // Création de l'historique avec une entrée initiale
          });
          toast.success(`Ingredient ${item.articleName} added successfully`);
        }
      }
      await fetchIngredients();
    } catch (error) {
      console.error('Error importing ingredients:', error);
      toast.error('Error importing ingredients');
    }
  };
  
  
  
  
  
  
 


  return {
    confirmDeleteIngredient,
    deleteIngredient,
    saveIngredient,
    isEditing,
    setIsEditing,
    ingredientHasChanges,
    setIngredientHasChanges,
    selectedIngredient, setSelectedIngredient,
	  openIngredientModal, closeIngredientModal,
    handleOrderImport
  };
};

export default useIngredients;
