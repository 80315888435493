import { useState } from "react";
import { Flex, Box, Table, Thead, Tr, Th, Tbody, Td, Input, Button } from "@chakra-ui/react";
import EditIngredientModal from "../components/recipes/EditIngredientModal";
import ImportOrderModal from "../components/recipes/importOrderModal"; // Assure-toi d'importer la modal ici
import useIngredients from "../hooks/useIngredients";
import { useRecipeContext } from "../contexts/RecipeContext";

const Stock = () => {
  const { filteredIngredients, searchIngredients, setSearchIngredients } = useRecipeContext();
  const { deleteIngredient, isEditing, selectedIngredient, openIngredientModal, closeIngredientModal } = useIngredients();
  const [isImportModalOpen, setImportModalOpen] = useState(false); // État pour gérer l'ouverture de la modal

  const openOrderImportModal = () => {
    setImportModalOpen(true);
  };

  const closeOrderImportModal = () => {
    setImportModalOpen(false);
  };

  return (
    <>
      <Flex direction={{ desktop: "row", mobile: "column" }} gap={16} flex="1">

        <Flex direction="column" gap={8} flex={1} className="widget">
          <Flex alignItems="center" gap={4}>
            <h3>Stock management</h3>
            <Button
              size="sm"
              className="btn secondary"
              onClick={openIngredientModal}>
              Add ingredient
            </Button>
            <Button size="sm" className="btn secondary" onClick={openOrderImportModal}>
              Import order
            </Button>
          </Flex>
          <Input
            type="text"
            placeholder="Search ingredients..."
            value={searchIngredients}
            onChange={(e) => setSearchIngredients(e.target.value)}
            className="search-input"
          />
          <Table className="stockTable" fontSize={'14px'}>
            <Thead>
              <Tr>
                <Th>Ingredient</Th>
                <Th>Supplier</Th>
                <Th>Unit</Th>
                <Th isNumeric>Price per unit</Th>
                <Th isNumeric>Stock</Th>
                
              </Tr>
            </Thead>
            <Tbody>
              {filteredIngredients.length > 0 ? (
                filteredIngredients.map((ingredient) => (
                  <Tr key={ingredient.id} onClick={() => openIngredientModal(ingredient)}>
                    <Td fontWeight='500'>{ingredient.name}</Td>
                    <Td><Box className="tag">{ingredient.supplier?.name}</Box></Td>
                    <Td>{ingredient.unit}</Td>
                    <Td isNumeric>{(ingredient.price)}€</Td>
                    <Td isNumeric color={ingredient.quantity < 3 ? "red.500" : "inherit"} fontWeight={600}>
                      {ingredient.quantity}
                    </Td>
                  </Tr>
                ))
              ) : (
                'No ingredients found.'
              )}
            </Tbody>
          </Table>
        </Flex>

        {selectedIngredient && (
          <EditIngredientModal
            ingredient={selectedIngredient}
            isOpen={!!selectedIngredient || !isEditing}
            onClose={closeIngredientModal}
            onDelete={() => deleteIngredient(selectedIngredient.id)}
            isEditing={isEditing}
          />
        )}

        {/* Intégration de la modal pour l'importation des commandes */}
        <ImportOrderModal
          isOpen={isImportModalOpen}
          onClose={closeOrderImportModal}
        />

      </Flex>
    </>
  );
};

export default Stock;
