import React, { useState, useEffect, useRef } from "react";
import { Flex, Box } from '@chakra-ui/react';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { useAppContext } from "../../contexts/AppContext";
import { useMenuContext } from "../../contexts/MenuContext";

const SubCategoryMenu = () => {
    
    const { mainLanguage, isCollapsed } = useAppContext();
    const { categories } = useMenuContext();

    const [activeId, setActiveId] = useState(null);
    const [isManualScroll, setIsManualScroll] = useState(false);
    const observerRef = useRef(null);
    const visibilityApiRef = useRef(null);

      
      useEffect(() => {
        observerRef.current = new IntersectionObserver(
          (entries) => {
            if (!isManualScroll) {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  setActiveId(entry.target.id);
                  centerActiveItem(entry.target.id);
                }
              });
            }
          },
          { rootMargin: "-80px 0px -90%" }
        );
    
        categories.forEach((subCategory) => {
          const element = document.getElementById(subCategory.id);
          if (element) observerRef.current.observe(element);
        });
    
        return () => {
          categories.forEach((subCategory) => {
            const element = document.getElementById(subCategory.id);
            if (element) observerRef.current.unobserve(element);
          });
        };
      }, [categories, isManualScroll]);
    
      const centerActiveItem = (activeId) => {
        if (visibilityApiRef.current) {
          const { getItemById, scrollToItem } = visibilityApiRef.current;
          const item = getItemById(activeId);
          if (item) {
            scrollToItem(item, "smooth", "center");
          } else {
          }
        }
      };
    
      const handleLinkClick = (id) => {
        const anchor = document.getElementById(id);
        if (anchor) {
          const topOffset =
            anchor.getBoundingClientRect().top + window.pageYOffset - 95;
          window.scrollTo({ top: topOffset, behavior: "smooth", block: "center" });
        }
    
        setActiveId(id);
      };

  return (
     <Box
        position="sticky"
        top="47.5px"
        bg="white"
        zIndex={2}
        pl={8}
        pr={8}
        pt={2}
        pb={2}
        borderBottom={"1px solid var(--borders)"}
        w={{
          desktop: isCollapsed ? "calc(100vw - 78px)" : "calc(100vw - 220px)",
          mobile: "100vw",
        }}
        overflow="hidden">
        <ScrollMenu
          apiRef={visibilityApiRef}
          onInit={(api) => (visibilityApiRef.current = api)}
          onUpdate={(api) => (visibilityApiRef.current = api)}
          scrollContainerClassName="subCategoriesChaser"
          itemClassName="subCategoriesChaserItem">
          {categories
            .filter((cat) => cat.id !== "uncategorized")
            .map((category, index) => {
              const isSelected = category.id === activeId;

              return (
                <Flex
                cursor={"pointer"}
                  p={1}
                  pl={3}
                  pr={3}
                  fontSize={"14px"}
                  borderRadius={"24px"}
                  key={category.id}
                  onClick={() => handleLinkClick(category.id)}
                  className={`scrollMenuItem ${isSelected ? "isSelected" : ""}`} // Appliquer la classe isSelected
                >
                  {category[`name_${mainLanguage}`]}
                </Flex>
              );
            })}
        </ScrollMenu>
      </Box>

      
  );
};

export default SubCategoryMenu;
