import { useState } from "react";
import { Flex, Box, Image, Text, Input } from "@chakra-ui/react";
import RecipeModal from "./RecipeModal";
import { useAppContext } from "../../contexts/AppContext";
import { useRecipeContext } from "../../contexts/RecipeContext";
import MenuSwitcher from "../menu/MenuSwitcher";
import useRecipes from "../../hooks/useRecipes";

const RecipesList = () => {

  const { mainLanguage } = useAppContext();
  const { filteredRecipes, ingredients, searchRecipe, setSearchRecipe } = useRecipeContext();
  const { calculateTotalPriceForEach } = useRecipes();
 
  
  const [currentRecipe, setCurrentRecipe] = useState(null);
  
  
  const [isEditing, setIsEditing] = useState(false);
  

  const openRecipeModal = (recipe = null) => {
    setCurrentRecipe(recipe);
    setIsEditing(!!recipe);
  };
  
    const closeRecipeModal = () => {
    setCurrentRecipe(null);
    setIsEditing(false);
  };

  



  return (
    <Flex direction="column" gap={8} flex={1} className="widget">
      <Flex alignItems="center" gap={4}>
        <h3>Recipes</h3>
      </Flex>
      <MenuSwitcher />
      <Flex>
        <Input
          size="lg"
          type="text"
          placeholder="Search recipes..."
          value={searchRecipe}
          onChange={(e) => setSearchRecipe(e.target.value)}
          className="search-input"
          bg="white !important"
        />
      </Flex>
      <Flex direction="column">
        {filteredRecipes.map((item) => (
          <Flex
            key={item.id}
            direction={{ desktop: "row", mobile: "column" }}
            justifyContent="space-between"
            className="dish"
            alignItems="center"
            onClick={() => openRecipeModal(item)}>
            <Flex gap={4} alignItems="center">
              {item?.recipe?.photo &&
              <Image
                borderRadius={8}
                src={item?.recipe?.photo}
                maxW="72px"
                h="72px"
                objectFit="cover"
              />
              }
              
              <Flex direction="column" gap={1}>
                <Box fontSize="16px" fontWeight="500" textTransform="capitalize">{item[`name_${mainLanguage}`]}</Box>
                <Flex direction="row" gap={1}>
                  <Text fontSize="15px">Cost price</Text>
                  <Box fontSize="15px" fontWeight="500" color="black">
                   {`${calculateTotalPriceForEach(item, ingredients)}€`}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column">
              <Text fontSize="13px">Suggested selling price</Text>
              <Flex
                direction="row"
                fontWeight="500"
                color="black"
                gap={2}>
                <Text color="orange">
                {((calculateTotalPriceForEach(item, ingredients)) * 3).toFixed(2)}€
                </Text>
                -
                <Text color="var(--success)">
                {((calculateTotalPriceForEach(item, ingredients)) * 3.5).toFixed(2)}€
                </Text>
              </Flex>
          
            </Flex>
          </Flex>
        ))}
      </Flex>


      {currentRecipe && (
        <RecipeModal
          currentRecipe={currentRecipe}
          setCurrentRecipe={setCurrentRecipe}
          isOpen={!!currentRecipe || !isEditing}
          onClose={closeRecipeModal}
          isEditing={isEditing}
        />
      )}
    </Flex>
  );
};

export default RecipesList;
