// SelectedRestaurantContext.js
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAllRestaurants from '../hooks/useAllRestaurants';
import { useAppContext } from './AppContext';

const SelectedRestaurantContext = createContext();

export const useSelectedRestaurant = () => {
  return useContext(SelectedRestaurantContext);
};

export const SelectedRestaurantProvider = ({ children }) => {
  const { restaurants } = useAllRestaurants();
  const { selectedRestaurantId, setSelectedRestaurantId, userRole, userRestaurantIds } = useAppContext();

  const navigate = useNavigate();
  const location = useLocation();

  const filteredRestaurants = useMemo(() => {
    if (userRole === 'superAdmin') {
      return restaurants;
    } else if (userRole === 'restaurantOwner') {
      return restaurants.filter(restaurant => userRestaurantIds.includes(restaurant.id));
    }
    return [];
  }, [restaurants, userRole, userRestaurantIds]);

  const selectedRestaurant = useMemo(() => {
    return filteredRestaurants.find(r => r.id === selectedRestaurantId);
  }, [filteredRestaurants, selectedRestaurantId]);


  useEffect(() => {
    if (location.pathname === '/login') {
      return;
    }
    const lastSelectedRestaurantId = localStorage.getItem('lastSelectedRestaurantId');
    if (selectedRestaurantId && restaurants.some(r => r.id === selectedRestaurantId)) {
      return;
    }
    if (lastSelectedRestaurantId && restaurants.some(r => r.id === lastSelectedRestaurantId)) {
      setSelectedRestaurantId(lastSelectedRestaurantId);
    } else if (restaurants.length > 0) {
      const firstAvailableRestaurantId = restaurants[0].id;
      setSelectedRestaurantId(firstAvailableRestaurantId);
      localStorage.setItem('lastSelectedRestaurantId', firstAvailableRestaurantId);
    }
  }, [restaurants, selectedRestaurantId, setSelectedRestaurantId, location.pathname]);


  useEffect(() => {
    const isSuperAdminRoute = location.pathname.includes("/settings");
    const isOrdersRoute = location.pathname.includes("/shop");
    const isRecipesRoute = location.pathname.includes("/recipes");
    const isDashboardRoute = location.pathname.includes("/dashboard");
    const isReservationsRoute = location.pathname.includes("/reservations");
    if (selectedRestaurantId && !isSuperAdminRoute && !isOrdersRoute && !isRecipesRoute && !isDashboardRoute && ! isReservationsRoute) {
      navigate(`/${selectedRestaurantId}/`);
    }
  }, [selectedRestaurantId, navigate, location.pathname]);


  const handleChangeRestaurant = (selectedId) => {
    localStorage.setItem('lastSelectedRestaurantId', selectedId);
    setSelectedRestaurantId(selectedId);
    const restaurant = restaurants.find(r => r.id === selectedId);
    if (restaurant) {
      handleRestaurantChange(restaurant);
    }
  };
  const handleRestaurantChange = (selectedRestaurant) => {
    if (!selectedRestaurant) {
      console.error("No restaurant selected");
      return;
    }
    setSelectedRestaurantId(selectedRestaurant.id);
  };

  const value = {
    selectedRestaurant,
    setSelectedRestaurantId,
    handleChangeRestaurant,
    handleRestaurantChange,
    filteredRestaurants
  };



  return (
    <SelectedRestaurantContext.Provider value={value}>
      {children}
    </SelectedRestaurantContext.Provider>
  );
};
