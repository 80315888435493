import React, { useState } from "react";
import { db, storage } from "../firebaseConfig";
import { doc, updateDoc, FieldValue } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useAppContext } from "../contexts/AppContext";
import Users from "../components/settings/users/Users";
import { toast } from "react-toastify";
import useLanguages from "../hooks/useLanguages";
import Select from "react-select";
import ShopSettings from "../components/settings/tabs/ShopSettings";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Button, Flex, Box, Switch } from "@chakra-ui/react";
import OpeningHours from "../components/settings/OpeningHours";
import ReservationsSettings from "../components/settings/tabs/ReservationsSettings";

const Settings = ({ isLoggedIn, userRole }) => {
  const {
    selectedRestaurantId,
    menuAdminBarZIndex,
    settings,
    setSettings,
    stripeKeys,
  } = useAppContext();

  const [activeTab, setActiveTab] = useState("general");
  const { languages } = useLanguages("");
  const functions = getFunctions();
  const saveStripeKeys = httpsCallable(functions, "saveStripeKeys");

  const [stripeModalIsOpen, setStripeModalIsOpen] = useState(null);

  const openStripeModal = () => {
    setStripeModalIsOpen(true);
  };
  const closeStripeModal = () => {
    setStripeModalIsOpen(false); // Ferme le modal
  };

  const primaryLanguageOptions = languages.filter(
    (lang) =>
      !settings.secondaryLanguages ||
      (Array.isArray(settings.secondaryLanguages) &&
        !settings.secondaryLanguages.some(
          (selectedLang) => selectedLang.value === lang.value
        ))
  );
  const secondaryLanguageOptions = languages.filter(
    (lang) =>
      !settings.language ||
      !settings.language.some(
        (selectedLang) => selectedLang.value === lang.value
      )
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const keys = name.split(".");
    const isNumericField =
      keys[keys.length - 1] === "preparationTime" ||
      keys[keys.length - 1] === "maxOrders" ||
      keys[keys.length - 1] === "serviceDuration";

    setSettings((prevSettings) => {
      let updatedSettings = { ...prevSettings };
      let temp = updatedSettings;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          temp[key] = isNumericField ? parseInt(value, 10) : value;
        } else {
          temp[key] = temp[key] || {};
          temp = temp[key];
        }
      });

      console.log(updatedSettings);

      return updatedSettings;
    });
  };

  const handleFileChange = async (e, logoType = "shop") => {
    const file = e.target.files[0];
    if (file) {
      try {
        const fileName = `${selectedRestaurantId}/${logoType}/${file.name}`;
        const storageRef = ref(storage, `logos/${fileName}`);
        const uploadResult = await uploadBytes(storageRef, file);
        const logoUrl = await getDownloadURL(uploadResult.ref);

        setSettings((prevSettings) => {
          const newSettings = {
            ...prevSettings,
            logo: {
              ...(prevSettings.logo || {}),
              [logoType]: logoUrl,
            },
          };

          const settingsDocRef = doc(
            db,
            `restaurants/${selectedRestaurantId}/settings`,
            "config"
          );
          updateDoc(settingsDocRef, { [`logo.${logoType}`]: logoUrl }).catch(
            console.error
          );

          return newSettings;
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleBackgroundImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const storageRef = ref(
          storage,
          `${selectedRestaurantId}/backgroundImages/${file.name}`
        );
        const uploadResult = await uploadBytes(storageRef, file);
        const imageUrl = await getDownloadURL(uploadResult.ref);

        const updatedSettings = { ...settings, backgroundImage: imageUrl };
        setSettings(updatedSettings);

        // Sauvegarder les nouveaux paramètres dans Firestore
        const settingsDocRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/settings`,
          "config"
        );
        await updateDoc(settingsDocRef, updatedSettings);
      } catch (error) {
        console.error("Error uploading background image:", error);
      }
    }
  };

  const handleDeleteLogo = async (logoType) => {
    const logoUrl = settings.logo?.[logoType];
    if (!logoUrl) return;

    try {
      const logoRef = ref(storage, logoUrl);
      await deleteObject(logoRef);

      setSettings((prevSettings) => ({
        ...prevSettings,
        logo: {
          ...prevSettings.logo,
          [logoType]: "", // Mettez simplement la clé correspondante à une chaîne vide
        },
      }));

      const settingsDocRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/settings`,
        "config"
      );
      await updateDoc(settingsDocRef, {
        [`logo.${logoType}`]: FieldValue.delete(), // Utilisez FieldValue.delete() pour supprimer la clé spécifique
      });
    } catch (error) {
      console.error(`Error deleting ${logoType} logo:`, error);
    }
  };

  const handleDeleteBackgroundImage = async () => {
    if (!settings.backgroundImage) return;

    try {
      const backgroundImageRef = ref(storage, settings.backgroundImage);
      await deleteObject(backgroundImageRef);
      console.log("Background image deleted successfully from Storage");

      const updatedSettings = { ...settings, backgroundImage: "" };
      setSettings(updatedSettings);
      const settingsDocRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/settings`,
        "config"
      );
      await updateDoc(settingsDocRef, updatedSettings);
    } catch (error) {
      console.error("Error deleting background image:", error);
    }
  };

  const handleLanguageChange = (selectedOption) => {
    const languageData = languages.find(
      (lang) => lang.value === selectedOption.value
    );

    setSettings((prevSettings) => ({
      ...prevSettings,
      language: [
        {
          ...selectedOption,
          flag: languageData ? languageData.flag : "",
        },
      ],
    }));
  };

  const handleSecondaryLanguagesChange = (selectedOptions) => {
    const updatedSecondaryLanguages = selectedOptions.map((option) => {
      const languageWithFlag = languages.find(
        (lang) => lang.value === option.value
      );
      return {
        value: option.value,
        label: option.label,
        flag: languageWithFlag ? languageWithFlag.flag : "",
      };
    });

    // Met à jour l'état settings avec les nouvelles langues secondaires
    setSettings({ ...settings, secondaryLanguages: updatedSecondaryLanguages });
  };

  const handleSaveSettings = async () => {
    if (selectedRestaurantId) {
      const settingsRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/settings`,
        "config"
      );
      try {
        await updateDoc(settingsRef, settings, { merge: true });
        toast.success("Settings saved successfully");
      } catch (error) {
        console.error("Error saving settings:", error);
        toast.error("Error saving settings");
      }
    } else {
      alert("Restaurant ID is not set.");
    }
  };
  const handleSaveStripeKeys = async () => {
    if (selectedRestaurantId) {
      const { PublicLive, SecretLive, PublicTest, SecretTest } = stripeKeys;
      try {
        // Supposant que saveStripeKeys est une fonction appelant votre fonction Cloud pour sauvegarder les clés
        await saveStripeKeys({
          selectedRestaurantId,
          PublicLive,
          SecretLive,
          PublicTest,
          SecretTest,
        });
        setStripeModalIsOpen(false); // Supposant que ceci ferme votre modal après succès
        toast.success("Stripe keys saved successfully");
      } catch (error) {
        console.error("Erreur lors de la sauvegarde des clés Stripe :", error);
        toast.error("Error saving Stripe keys");
      }
    } else {
      console.error("Stripe settings are missing");
      toast.error("Stripe settings are missing");
    }
  };

  const [openingHoursModalIsOpen, setOpeningHoursModalIsOpen] = useState(null);
  const openHoursModal = () => {
    setOpeningHoursModalIsOpen(true);
  };

  return (
    <>
      <Flex
        justifyContent="space-between"
        direction="row"
        w="100%"
        alignItems="center"
        position="sticky"
        top="0"
        bg="white"
        pl={8}
        pr={8}
        borderBottom="1px solid var(--borders)"
        style={{ zIndex: menuAdminBarZIndex }}>
        <Flex direction="row" className="tabLinks">
          <div
            onClick={() => setActiveTab("general")}
            className={`tabLink ${activeTab === "general" ? "active" : ""}`}>
            General
          </div>
          {settings.modules.reservations && 
          <div
            onClick={() => setActiveTab("reservations")}
            className={`tabLink ${activeTab === "reservations" ? "active" : ""}`}>
            Reservations
          </div>
          }
          <div
            onClick={() => setActiveTab("shop")}
            className={`tabLink ${activeTab === "shop" ? "active" : ""}`}>
            Shop
          </div>
          <div
            onClick={() => setActiveTab("users")}
            className={`tabLink ${activeTab === "users" ? "active" : ""}`}>
            Users
          </div>
        </Flex>
        <Button size="sm" onClick={handleSaveSettings} className="btn primary">
          Save Settings
        </Button>
      </Flex>

      <Flex
        padding={{ desktop: "3vw 3vw 124px 3vw", mobile: "0 0 81px 0" }}
        maxW="1320px"
        direction={{ desktop: "row", mobile: "column" }}
        gap={{ desktop: 8, mobile: 0 }}
        flex="1">
        {activeTab === "general" && (
          <>
            <Flex
              direction="column"
              flexGrow="1"
              minW="0"
              gap={{ desktop: 8, mobile: 0 }}>
              <Flex direction="column" flex="1" gap={4} className="widget">
                <h5>Company information</h5>
                <Flex direction="column" gap={2}>
                  <Flex className="field">
                    <label>Company</label>
                    <input
                      type="text"
                      name="shopSettings.contact.companyName"
                      value={settings.shopSettings?.contact?.companyName}
                      onChange={handleInputChange}></input>
                  </Flex>
                  <Flex className="field">
                    <label>Address</label>
                    <input
                      type="text"
                      name="shopSettings.contact.address"
                      value={settings.shopSettings?.contact?.address}
                      onChange={handleInputChange}></input>
                  </Flex>
                  <Flex className="field">
                    <label>Phone number</label>
                    <input
                      type="phone"
                      name="shopSettings.contact.phoneNumber"
                      value={settings.shopSettings?.contact?.phoneNumber}
                      onChange={handleInputChange}></input>
                  </Flex>

                  <Flex className="field">
                    <label>VAT</label>
                    <input
                      type="text"
                      name="shopSettings.contact.vat"
                      value={settings.shopSettings?.contact?.vat}
                      onChange={handleInputChange}></input>
                  </Flex>
                  <Flex className="field">
                    <label>RCS</label>
                    <input
                      type="text"
                      name="shopSettings.contact.rcs"
                      value={settings.shopSettings?.contact?.rcs}
                      onChange={handleInputChange}></input>
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction="column" flex="1" gap={4} className="widget">
                <h5>Branding</h5>
                <Flex className="field">
                  <label>Menu logo</label>
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "menu")}
                  />
                </Flex>

                {settings.logo.menu && (
                  <Flex
                    className="logoPreview"
                    style={{
                      backgroundColor: settings.backgroundColor,
                    }}>
                    <img src={settings.logo.menu} alt="Menu Logo preview" />
                    <button
                      onClick={() => handleDeleteLogo("menu")}
                      className="btn error sm">
                      Delete menu logo
                    </button>
                  </Flex>
                )}
                <Flex className="field">
                  <p>Upload a background image</p>
                  <input type="file" onChange={handleBackgroundImageChange} />
                  {settings.backgroundImage && (
                    <>
                      <Box w="320px">
                        <img
                          src={settings.backgroundImage}
                          alt="Background preview"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </Box>
                      <button
                        onClick={handleDeleteBackgroundImage}
                        className="btn error sm">
                        Delete Background Image
                      </button>
                    </>
                  )}
                </Flex>
                <Flex className="field">
                  <label>Brand color</label>
                  <div className="color-input">
                    <div
                      className="color-preview"
                      style={{
                        backgroundColor: settings.brandColor,
                      }}></div>
                    <input
                      type="text"
                      name="brandColor"
                      value={settings.brandColor}
                      onChange={handleInputChange}
                      placeholder="#FFFFFF"
                    />
                  </div>
                </Flex>
                <Flex className="field">
                  <label>Background color</label>
                  <div className="color-input">
                    <div
                      className="color-preview"
                      style={{
                        backgroundColor: settings.backgroundColor,
                      }}></div>
                    <input
                      type="text"
                      name="backgroundColor"
                      value={settings.backgroundColor}
                      onChange={handleInputChange}
                      placeholder="#FFFFFF"
                    />
                  </div>
                </Flex>
                <Flex className="field">
                  <label>Text color</label>
                  <div className="color-input">
                    <div
                      className="color-preview"
                      style={{
                        backgroundColor: settings.textColor,
                      }}></div>
                    <input
                      type="text"
                      name="textColor"
                      value={settings.textcolor}
                      onChange={handleInputChange}
                      placeholder="#FFFFFF"
                    />
                  </div>
                </Flex>
              </Flex>
            </Flex>

            {/* SIDEBAR */}
            <Flex
              flex={{ desktop: "0 0 320px", mobile: "1" }}
              direction="column"
              gap={{ desktop: 8, mobile: 0 }}>
              <Flex direction="column" gap={4} className="widget">
                <h5>Language settings</h5>
                <Flex className="field">
                  <label>Menu main language</label>
                  <Select
                    name="language"
                    value={
                      settings.language && settings.language.length > 0
                        ? settings.language[0]
                        : ""
                    }
                    onChange={handleLanguageChange}
                    options={primaryLanguageOptions}
                    className="customSelect-container"
                    classNamePrefix="customSelect"
                  />
                </Flex>
                <Flex className="field">
                  <label>Secondary Languages</label>
                  <Select
                    isMulti
                    name="secondaryLanguages"
                    value={settings.secondaryLanguages}
                    onChange={handleSecondaryLanguagesChange}
                    options={secondaryLanguageOptions}
                    className="customSelect-container"
                    classNamePrefix="customSelect"
                  />
                </Flex>
              </Flex>

              <Flex direction="column" gap={4} className="widget">
                <Flex
                  direction={"row"}
                  gap={2}
                  justifyContent={"space-between"}>
                  <h5>Opening hours</h5>
                  <Button
                    size="sm"
                    className="btn secondary"
                    onClick={openHoursModal}>
                    Edit
                  </Button>
                </Flex>
                <OpeningHours
                  openingHoursModalIsOpen={openingHoursModalIsOpen}
                  setOpeningHoursModalIsOpen={setOpeningHoursModalIsOpen}
                />
              </Flex>

              {userRole === "superAdmin" && (
                <Flex direction="column" gap={4} className="widget">
                  <h5>Modules</h5>
                 
                 <Flex
                    directionm="row"
                    alignItems="center"
                    justifyContent="space-between"
                    >
                    <h6>Ratio</h6>
                    <Switch
                      id="ratio-switch"
                      isChecked={settings.modules?.ratio || false} // Assurez-vous que `settings.modules` est défini
                      onChange={(e) => {
                        setSettings((prevSettings) => ({
                          ...prevSettings,
                          modules: {
                            ...prevSettings.modules,
                            ratio: e.target.checked,
                          },
                        }));
                      }}
                    />
                  </Flex>
                  
                  <Flex
                    directionm="row"
                    alignItems="center"
                    justifyContent="space-between"
                    >
                    <h6>Reservations</h6>
                    <Switch
                      id="reservations-switch"
                      isChecked={settings.modules?.reservations || false} // Assurez-vous que `settings.modules` est défini
                      onChange={(e) => {
                        setSettings((prevSettings) => ({
                          ...prevSettings,
                          modules: {
                            ...prevSettings.modules,
                            reservations: e.target.checked,
                          },
                        }));
                      }}
                    />
                  </Flex>

                  <Flex
                    directionm="row"
                    alignItems="center"
                    justifyContent="space-between"
                    >
                    <h6>Staff</h6>
                    <Switch
                      id="staff-switch"
                      isChecked={settings.modules?.staff || false} // Assurez-vous que `settings.modules` est défini
                      onChange={(e) => {
                        setSettings((prevSettings) => ({
                          ...prevSettings,
                          modules: {
                            ...prevSettings.modules,
                            staff: e.target.checked,
                          },
                        }));
                      }}
                    />
                  </Flex>

                </Flex>
              )}
            </Flex>
          </>
        )}

        {activeTab === "reservations" && (

          <ReservationsSettings
            onChange={handleInputChange}
          />
        )}
        
        
        {activeTab === "shop" && (
          <ShopSettings
            handleFileChange={handleFileChange}
            onChange={handleInputChange}
            onDelete={handleDeleteLogo}
            saveStripeKeys={handleSaveStripeKeys}
            stripeModalIsOpen={stripeModalIsOpen}
            openStripeModal={openStripeModal}
            closeStripeModal={closeStripeModal}
          />
        )}
        {activeTab === "users" && (
          <Users selectedRestaurantId={selectedRestaurantId} />
        )}
      </Flex>
    </>
  );
};

export default Settings;
