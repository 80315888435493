// AppContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, query } from 'firebase/firestore';
import { useAppContext } from './AppContext';

const RecipeContext = createContext();

export const useRecipeContext = () => useContext(RecipeContext);

export const RecipeProvider = ({ children }) => {
  
  const {selectedRestaurantId, mainCategoryName, mainLanguage } = useAppContext();
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [searchRecipe, setSearchRecipe] = useState('');
  const [searchIngredients, setSearchIngredients] = useState('');
  
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState({ id: '', name: '' });

  const [recipes, setRecipes] = useState([]);

  const fetchRecipes = useCallback(async () => {
    if (!selectedRestaurantId || !mainCategoryName) return;
    const menuSnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`));
    let tempMenuItems = [];
    menuSnapshot.docs.forEach(doc => {
      const itemData = { ...doc.data(), id: doc.id };
  
      if (itemData.variations && itemData.variations.length > 0) {
        itemData.variations.forEach(variation => {
          const newItem = {
            ...itemData,
            id: `${itemData.id}-${variation.name}`,
            [`name_${mainLanguage}`]: `${itemData[`name_${mainLanguage}`]} ${variation.name}`,
            price: variation.price,
            variations: undefined,
            variationId: `${itemData.id}-${variation.name}`,
          };
          tempMenuItems.push(newItem); 
        });
      } else {
        // Si pas de variations, ajouter l'item original à la liste
        tempMenuItems.push(itemData);
      }
    });
  
    setRecipes(tempMenuItems.sort((a, b) => a[`name_${mainLanguage}`].localeCompare(b[`name_${mainLanguage}`])));
  }, [mainCategoryName, selectedRestaurantId]);


  useEffect(() => {
    fetchRecipes();
  }, [fetchRecipes]);

  const filteredRecipes = recipes.filter((item) =>
  item[`name_${mainLanguage}`].toLowerCase().includes(searchRecipe.toLowerCase())
);

  
  const fetchIngredients = async () => {
    if (!selectedRestaurantId) {
      return;
    }
    const querySnapshot = await getDocs(
      query(collection(db, `restaurants/${selectedRestaurantId}/ingredients`))
    );
    const ingredientsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    ingredientsData.sort((a, b) => a.name.localeCompare(b.name));
    setIngredients(ingredientsData);
  };

  useEffect(() => {
    fetchIngredients();
  }, [selectedRestaurantId]);

  
  const fetchSuppliers = async () => {
    if (!selectedRestaurantId) {
      return;
    }
    const querySnapshot = await getDocs(
      query(collection(db, `restaurants/${selectedRestaurantId}/suppliers`))
    );
    const suppliersData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    suppliersData.sort((a, b) => a.name.localeCompare(b.name));
    setSuppliers(suppliersData);
  };

  useEffect(() => {
    fetchSuppliers();
  }, [selectedRestaurantId]);

  const filteredIngredients = ingredients.filter(ingredient => 
    ingredient.name.toLowerCase().includes(searchIngredients.toLowerCase())
  );

  const supplierOptions = suppliers.map((supplier) => ({
    value: supplier.id,
    label: supplier.name,
  }));
  


  const value = {
    fetchRecipes,
    filteredRecipes,
    ingredients,
    setIngredients,
    fetchIngredients,
    filteredIngredients,
    searchRecipe, setSearchRecipe,
    searchIngredients, setSearchIngredients,
    supplier, setSupplier,
    suppliers, setSuppliers,
    fetchSuppliers,
    selectedIngredients, 
    setSelectedIngredients,
    supplierOptions
   
  };

  return (
    <RecipeContext.Provider value={value}>
      {children}
    </RecipeContext.Provider>
  );
};
