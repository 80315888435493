import { Flex, Grid } from "@chakra-ui/react";
import { useAppContext } from "../contexts/AppContext";

const Dashboard = () => {

  const {userData} = useAppContext();

  return (
    <Flex direction="column"  padding={{ desktop: "3vw 3vw 124px 3vw", mobile: "0 0 172px 0" }} gap={8} maxW="1320px">
      <h1>Welcome {userData.name}</h1>
      
      <Grid templateColumns='repeat(3, 1fr)' gap={8}>
        <Flex className="widget">
          <h5>Latest orders</h5>
        </Flex>
        <Flex className="widget">
          <h5>Latest orders</h5>
        </Flex>
        <Flex className="widget">
          <h5>Latest orders</h5>
        </Flex>        
      </Grid>
      
    </Flex>
  );
};
export default Dashboard;
