import { Flex, Link } from '@chakra-ui/react';
import UserProfile from './UserProfile';
import { Bell, Lightbulb, LifeBuoy } from 'lucide-react';
import RestaurantSelect from './RestaurantSelect';
import useOrders from '../../hooks/useOrders';
import { Tooltip } from 'react-tooltip';




const TopHeader = () => {

  const { pendingOrdersCount } = useOrders();


  return (
    <Flex direction="row" w="100%" pl={8} pr={8} pt={2} pb={2} justifyContent={"space-between"} alignItems={"center"} background="white" borderBottom="1px solid var(--borders)" display={{ desktop: "flex", mobile: "none" }} >
        
        
        <RestaurantSelect />

        <Flex direction="row" alignItems="center" gap={2} className='topIcons'>
            
            <Link href='mailto:support@gudden.app?subject=gudden.app technical support request' alt="Technical support" data-tooltip-id="technicalSupport" data-tooltip-content="Technical support" data-tooltip-place="top" >
              <Flex w="42px" h="42px" backgroundColor="var(--backend-background-color)" 
                alignItems="center" 
                justifyContent="center" 
                borderRadius="8px">
                <LifeBuoy size={20} />
                <Tooltip id="technicalSupport" place="top" className="toolTip"></Tooltip>
              </Flex>
            </Link>
            <Link href='mailto:support@gudden.app?subject=gudden.app new feature idea'  alt="New feature idea" data-tooltip-id="newFeature" data-tooltip-content="Submit a new feature idea" data-tooltip-place="top">
              <Flex w="42px" h="42px" backgroundColor="var(--backend-background-color)" alignItems="center" justifyContent="center" borderRadius="8px">
                    <Lightbulb size={20} />
                    <Tooltip id="newFeature" place="top" className="toolTip"></Tooltip>
              </Flex>
            </Link>
            <Flex w="42px" h="42px" backgroundColor="var(--backend-background-color)" alignItems="center" justifyContent="center" borderRadius="8px" opacity='.3' pointerEvents="none">
                <Bell size={20} />
                {pendingOrdersCount > 0 && (
                  <Flex justifyContent="center" className="notification orderCount">
                    {pendingOrdersCount}
                  </Flex>
                )}
            </Flex>
            
            <UserProfile />
        </Flex>
        
    </Flex>
  );
};

export default TopHeader;
