import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  deleteDoc,
  updateDoc,
  doc,
  Timestamp,
  startAfter,
  limit,
  getDoc,
} from "firebase/firestore";
import { useAppContext } from "../contexts/AppContext";
import { toast } from "react-toastify";

const ORDERS_PER_PAGE = 20;

const useOrders = (startDate, endDate, page = 1) => {
  const { selectedRestaurantId, settings } = useAppContext();
  const [orders, setOrders] = useState([]);
  const [totalSubTotal, setTotalSubTotal] = useState(0);
  const [totalTVA, setTotalTVA] = useState(0);
  const [totalTips, setTotalTips] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalCancelledOrders, setTotalCancelledOrders] = useState(0);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const [cancellationNote, setCancellationNote] = useState('');



  useEffect(() => {
    if (!selectedRestaurantId) return; // Ne procéder que si un restaurant est sélectionné
    const ordersRef = collection(db, `restaurants/${selectedRestaurantId}/orders`);
    const q = query(ordersRef, where("status", "==", "pending"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedOrders = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPendingOrders(fetchedOrders);
      setPendingOrdersCount(querySnapshot.docs.length);
    });

    return () => unsubscribe(); // Nettoyage en désabonnant l'observateur lors du démontage du composant
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (!selectedRestaurantId || !startDate || !endDate) return;

    const adjustedStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const adjustedEndDate = new Date(endDate.setHours(23, 59, 59, 999));

    const startTimestamp = Timestamp.fromDate(adjustedStartDate);
    const endTimestamp = Timestamp.fromDate(adjustedEndDate);
    const ordersRef = collection(
      db,
      `restaurants/${selectedRestaurantId}/orders`
    );
    const q = query(
      ordersRef,
      orderBy("createdAt", "desc"),
      where("createdAt", ">=", startTimestamp),
      where("createdAt", "<=", endTimestamp),
      limit(ORDERS_PER_PAGE)
    );

    if (page > 1 && lastDoc) {
      q = query(q, startAfter(lastDoc)); // Commencer après le dernier document de la page précédente
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedOrders = [];
      let subTotal = 0;
      let tips = 0;
      let cancelledOrders = 0;
      let newLastDoc = null;
      let confirmedOrdersCount = 0;

      querySnapshot.docs.forEach((doc, index) => {
        if (index === querySnapshot.docs.length - 1) {
          newLastDoc = doc; // Garder une trace du dernier document
        }
        const data = doc.data();
        const createdAt = data.createdAt.toDate();

        fetchedOrders.push({
          id: doc.id,
          ...data,
          day: createdAt.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          }),
          fullDate: createdAt.toLocaleString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }),
        });

        if (data.status === "completed") {
          subTotal += parseFloat(data.subTotal.replace(",", "."));
          tips += parseFloat(data.tip);
          if (data.deliveryFee) {
            subTotal += parseFloat(data.deliveryFee.replace(",", "."));
          }
          confirmedOrdersCount++;
        }

        if (data.status === "cancelled") {
          cancelledOrders++;
        }
      });

      setOrders(fetchedOrders);
      setLastDoc(newLastDoc);
      setTotalSubTotal(subTotal);
      setTotalTips(tips);
      setTotalOrders(fetchedOrders.length);
      setTotalCancelledOrders(cancelledOrders);
      const totalTVA = calculateTVA(fetchedOrders);
      setTotalTVA(parseFloat(totalTVA));
    });

    return () => unsubscribe();
  }, [selectedRestaurantId, startDate, endDate]);

  const deleteOrder = async (selectedRestaurantId, orderId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this order? This action cannot be undone.");
    if (isConfirmed) {
      const orderRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/orders`,
        orderId
      );
      try {
        await deleteDoc(orderRef);
        toast.success(`Order ${orderId} was successfully deleted.`);
        setIsOrderModalOpen(false);
      } catch (error) {
        console.error("Error during order deletion:", error);
        toast.error("Error during order deletion.");
      }
    } else {
      console.log("Order deletion cancelled.");
    }
  };

  const updateOrderStatus = async (orderId, newStatus, newScheduledTime = null, cancellationNote = null) => {
    const orderRef = doc(
      db,
      `restaurants/${selectedRestaurantId}/orders`,
      orderId
    );
    const orderSnap = await getDoc(orderRef);
    if (!orderSnap.exists()) {
      console.error("Order not found");
      return;
    }
    const orderData = orderSnap.data();

    let updatePayload = {
      status: newStatus,
    };
    if (newStatus === "cancelled" && cancellationNote) {
      updatePayload.cancellationNote = cancellationNote;
    }
    // Mise à jour du paymentStatus si le statut est complété
    if (newStatus === "completed") {
      updatePayload.paymentStatus = "Paid";
    }

    // Mise à jour du scheduledTime si newScheduledTime est fourni
    if (newScheduledTime && newScheduledTime instanceof Date) {
      updatePayload.scheduledTime = Timestamp.fromDate(newScheduledTime);
    }

    // Ajout de la cancellationNote à la commande si applicable
    if (cancellationNote && newStatus === 'cancelled') {
      updatePayload.cancellationNote = cancellationNote;
    }

    try {
      await updateDoc(orderRef, updatePayload);
      toast.success("Order status updated");
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error("Error updating order status");
    }
};



  const processRefund = async (orderId, amount) => {
    console.log("Processing refund", amount, "for order", orderId);
    const orderRef = doc(
      db,
      `restaurants/${selectedRestaurantId}/orders`,
      orderId
    );
    try {
      const orderSnap = await getDoc(orderRef);
      if (!orderSnap.exists()) {
        throw new Error("Order not found");
      }

      // Mise à jour de la commande avec le montant remboursé et le statut de paiement modifié
      await updateDoc(orderRef, {
        refundedAmount: amount, // Supposons que vous stockiez le montant remboursé dans ce champ
        paymentStatus: "refunded", // Mise à jour du statut de paiement à "remboursé"
      });

      toast.success(
        `Refund of ${amount} processed successfully for order ${orderId}.`
      );
    } catch (error) {
      console.log("Error processing refund", error);
      console.error("Error processing refund for order", orderId, ":", error);
      toast.error(
        `Error processing refund for order ${orderId}: ${
          error.message || error
        }`
      );
    }
  };

  const calculateTVA = (orders) => {
    let totalVAT = 0;
    const completedOrders = orders.filter(
      (order) => order.status === "completed"
    );
    completedOrders.forEach((order) => {
      order.cartItems.forEach((item) => {
        // Assurez-vous de convertir le format de nombre européen en format standard si nécessaire
        const price = parseFloat(item.price.replace(",", "."));
        const vatRate = item.vat;
        const vatAmount = (price - price / (1 + vatRate)) * item.quantity;
        totalVAT += Math.round(vatAmount * 100) / 100; // Arrondir à deux décimales ici
      });
    });
    return totalVAT.toFixed(2);
  };

  const calculateTotalCommission = (orders, commissionFee) => {
    const fee = parseFloat(commissionFee.replace(",", ".")) / 100;
    const totalVAT = parseFloat(calculateTVA(orders).replace(",", "."));
    const totalSubTotal = orders.reduce((acc, order) => {
      if (order.status === "completed") {
        return acc + parseFloat(order.subTotal.replace(",", "."));
      }
      return acc;
    }, 0);
    const totalCommission = (totalSubTotal - totalVAT) * fee;
    return totalCommission; // Formate le résultat à deux décimales
  };
  
  // Usage
  const totalCommission = calculateTotalCommission(orders, settings.shopSettings?.fees?.commissionFee);
  

  return {
    orders,
    pendingOrders,
    pendingOrdersCount,
    setOrders,
    totalOrders,
    totalSubTotal,
    totalTips,
    totalCancelledOrders,
    deleteOrder,
    isOrderModalOpen,
    setIsOrderModalOpen,
    updateOrderStatus,
    lastDoc,
    calculateTotalCommission,
    totalCommission,
    calculateTVA,
    processRefund,
    totalTVA,
    cancellationNote,
    setCancellationNote
  };
};

export default useOrders;
