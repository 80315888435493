import { useState, useEffect, useRef } from "react";
import { useAppContext } from "../../../contexts/AppContext";
import { Image, FormLabel, Switch } from "@chakra-ui/react";
import StripeKeysModal from "../StripeKeysModal";
import {
  Flex,
  Text,
  Box,
  Button,
  Link,
  Input,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { X } from "lucide-react";

const ShopSettings = ({
  onChange,
  handleFileChange,
  onDelete,
  saveStripeKeys,
  stripeModalIsOpen,
  openStripeModal,
  closeStripeModal,
}) => {
  const { selectedRestaurantId, settings, setSettings, userRole } =
    useAppContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const inputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFileChange(e.dataTransfer.files[0]);
      onClose(); // Fermer l'interface de drop après le traitement
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClickBrowse = () => {
    inputRef.current.click(); // Assurez-vous que inputRef est bien attaché à l'input de type file
  };
  

  const handleFileSelected = (e) => {
    handleFileChange(e); // Passer l'événement complet
  };
  




  const handleShopSettingsChange = (event, path) => {
    const isActivated = event.target.checked;

    setSettings((prevSettings) => {
      const pathParts = path.split(".");
      let lastPart = prevSettings;

      // Traverse the path to get to the last object
      for (let i = 0; i < pathParts.length - 1; i++) {
        lastPart = lastPart[pathParts[i]];
      }

      // Set the activation at the final part of the path
      lastPart[pathParts[pathParts.length - 1]] = isActivated;

      return {
        ...prevSettings,
      };
    });
  };

  const [deliveryZoneInput, setDeliveryZoneInput] = useState(
    settings.shopSettings.delivery.zoneInput || ""
  );
  const [deliveryZoneExpanded, setDeliveryZoneExpanded] = useState([]);

  const handleZoneInputChange = (e) => {
    setDeliveryZoneInput(e.target.value);
  };

  const saveDeliveryZone = () => {
    const postalCodes = parsePostalCodes(deliveryZoneInput);

    setDeliveryZoneExpanded(postalCodes);

    setSettings((prevSettings) => ({
      ...prevSettings,
      shopSettings: {
        ...prevSettings.shopSettings,
        delivery: {
          ...prevSettings.shopSettings.delivery,
          zone: postalCodes,
          zoneInput: deliveryZoneInput,
        },
      },
    }));
  };

  useEffect(() => {
    const input = settings.shopSettings.delivery.zoneInput || "";
    const postalCodes = settings.shopSettings.delivery.zone?.join(", ") || "";
    setDeliveryZoneInput(input);
    setDeliveryZoneExpanded(postalCodes.split(",").map((code) => code.trim()));
  }, [settings.shopSettings.delivery]);

  const parsePostalCodes = (input) => {
    return input
      .split(",")
      .map((code) => code.trim())
      .reduce((acc, code) => {
        if (code.includes("-")) {
          const [start, end] = code.split("-").map(Number);
          for (let i = start; i <= end; i++) {
            acc.push(i.toString());
          }
        } else {
          acc.push(code);
        }
        return acc;
      }, []);
  };

  return (
    <>
      {userRole === "superAdmin" ? (
        <>
          <Flex direction="column">
            <Box
              border="1px solid var(--borders)"
              p={4}
              w="142px"
              h="142px"
              borderRadius="var(--radius-sm)"
              alignItems="center"
              justifyContent="center"
              position="relative"
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onMouseEnter={onOpen}
              onMouseLeave={onClose}
              cursor="pointer"
              bg="white">
              
              {settings.logo.shop ? (
                <Image src={settings.logo.shop} alt="Shop Logo" />
              ) : (
                <p>No logo uploaded</p>
              )}

              {isOpen && (
                <>
                  <IconButton
                    icon={<X size={14} color="white" />}
                    position="absolute"
                    top="1"
                    right="1"
                    onClick={() => onDelete("shop")}
                    colorScheme="red"
                    size="xs"
                  />
                  <Button
                    position="absolute"
                    bottom="1"
                    left="50%"
                    transform="translateX(-50%)"
                    size="sm"
                    onClick={handleClickBrowse}>
                    Browse
                  </Button>
                </>
              )}

              <Input
                ref={inputRef}
                type="file"
                onChange={handleFileSelected}
                hidden
                accept="image/*"
              />

            </Box>
          </Flex>

          <Flex
            direction="column"
            flexGrow="1"
            minW="0"
            gap={{ desktop: 8, mobile: 0 }}>
            <Flex direction="column" gap={4} className="widget">
              <h5>General</h5>

              <Flex className="field">
                <label>Shop manager email address</label>
                <Input
                  type="email"
                  name="shopSettings.contact.shopManagerEmail"
                  value={settings.shopSettings?.contact?.shopManagerEmail}
                  onChange={onChange}></Input>
              </Flex>
              <Flex direction={{ desktop: "row", mobile: "column" }} gap={4}>
                {userRole === "superAdmin" && (
                  <Flex className="field">
                    <label>Commission fee</label>
                    <input
                      type="number"
                      name="shopSettings.fees.commissionFee"
                      value={settings.shopSettings?.fees?.commissionFee}
                      onChange={onChange}></input>
                  </Flex>
                )}
                <Flex className="field">
                  <label>Preparation time</label>
                  <Input
                    type="number"
                    name="shopSettings.general.preparationTime"
                    value={
                      settings.shopSettings?.general?.preparationTime || ""
                    }
                    onChange={onChange}
                  />
                </Flex>
                <Flex className="field">
                  <label>Maximum orders per 15 minutes</label>
                  <Input
                    type="number"
                    name="shopSettings.general.maxOrders"
                    value={settings.shopSettings?.general?.maxOrders || ""}
                    onChange={onChange}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              gap={8}
              alignItems="flex-start">
              <Flex direction="column" gap={4} className="widget" flex="50%">
                <h5>Take-away settings</h5>
                <div className="field">
                  <FormLabel htmlFor="takeAwayActivation" mb="0">
                    Activate take-away
                  </FormLabel>
                  <Switch
                    id="takeAwayActivation"
                    isChecked={
                      settings.shopSettings?.takeAway?.activation || false
                    }
                    onChange={(e) =>
                      handleShopSettingsChange(
                        e,
                        "shopSettings.takeAway.activation"
                      )
                    }
                  />
                </div>
                {settings.shopSettings.takeAway.activation === true && (
                  <div className="field">
                    <label>Minimum order amount</label>
                    <input
                      type="number"
                      name="shopSettings.takeAway.minimumOrderAmount"
                      value={
                        settings.shopSettings?.takeAway.minimumOrderAmount || ""
                      }
                      onChange={onChange}
                    />
                  </div>
                )}
              </Flex>

              <Flex direction="column" gap={4} className="widget" flex="50%">
                <h5>Delivery settings</h5>

                <div className="field">
                  <FormLabel htmlFor="deliveryActivation" mb="0">
                    Activate delivery
                  </FormLabel>
                  <Switch
                    id="deliveryActivation"
                    isChecked={
                      settings.shopSettings?.delivery?.activation || false
                    }
                    onChange={(e) =>
                      handleShopSettingsChange(
                        e,
                        "shopSettings.delivery.activation"
                      )
                    }
                  />
                </div>

                {settings.shopSettings.delivery.activation === true && (
                  <>
                    <div className="field">
                      <label>Minimum order amount</label>
                      <input
                        type="number"
                        name="shopSettings.delivery.minimumOrderAmount"
                        value={
                          settings.shopSettings?.delivery.minimumOrderAmount ||
                          ""
                        }
                        onChange={onChange}
                      />
                    </div>

                    <div className="field">
                      <label>Delivery time (in minutes)</label>
                      <input
                        type="number"
                        name="shopSettings.delivery.deliveryTime"
                        value={
                          settings.shopSettings?.delivery.deliveryTime || ""
                        }
                        onChange={onChange}
                      />
                    </div>
                    <div className="field">
                      <label>Delivery fee</label>
                      <input
                        type="number"
                        name="shopSettings.delivery.deliveryFee"
                        value={
                          settings.shopSettings?.delivery.deliveryFee || ""
                        }
                        onChange={onChange}
                      />
                    </div>

                    <div className="field">
                      <label>Delivery zone</label>
                      <textarea
                        placeholder="Enter postal codes separated by a comma, use a dash for ranges"
                        value={deliveryZoneInput}
                        onChange={handleZoneInputChange}
                        onBlur={saveDeliveryZone}
                      />
                    </div>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>

          {/* SIDEBAR */}
          {(settings.shopSettings.delivery.activation || settings.shopSettings.takeAway.activation) && (

          <Flex
            flex={{ desktop: "0 0 320px", mobile: "1" }}
            direction="column"
            gap={{ desktop: 8, mobile: 0 }}>
            
              <Flex direction="column" flex="1" gap={4} className="widget">
                <h5>Payment methods</h5>
                <Flex direction="column" gap={2}>
                  <Flex direction="row" justifyContent="space-between" flex="1">
                    <FormLabel htmlFor="till" mb="0">
                      Pay upon pickup
                    </FormLabel>
                    <Switch
                      id="till"
                      isChecked={
                        settings.shopSettings.paymentMethods?.till.activation ||
                        false
                      }
                      onChange={(e) =>
                        handleShopSettingsChange(
                          e,
                          "shopSettings.paymentMethods.till.activation"
                        )
                      }
                    />
                  </Flex>
                  <Flex direction="row" justifyContent="space-between" flex="1">
                    <FormLabel htmlFor="online" mb="0">
                      Online payment with Stripe
                    </FormLabel>
                    <Switch
                      id="online"
                      isChecked={settings.shopSettings.paymentMethods?.online.activation || false}
                      onChange={(e) => handleShopSettingsChange(e,"shopSettings.paymentMethods.online.activation")}
                    />
                  </Flex>
                  {settings.shopSettings.paymentMethods.online.activation === true && (
                    <Button
                      size="md"
                      className="secondary"
                      onClick={openStripeModal}>
                      Set Stripe keys
                    </Button>
                  )}
                </Flex>

                <StripeKeysModal
                  isOpen={stripeModalIsOpen}
                  onClose={closeStripeModal}
                  onChange={onChange}
                  onSave={saveStripeKeys}
                />
              </Flex>
            
          </Flex>)}
        </>
      ) : (
        <Flex>
          <Text>Your shop is not activated.</Text>
          <Link
            href={`mailto:it@goodrobot.lu?subject=Shop activation request for ${selectedRestaurantId}`}>
            <Button size="lg" className="primary">
              Contact us to activate your shop
            </Button>
          </Link>
        </Flex>
      )}
    </>
  );
};

export default ShopSettings;
