import React, { useState, useEffect } from 'react';
import { Page, Image, View, Document, StyleSheet, Font, Text } from "@react-pdf/renderer";
import QRCode from 'qrcode';

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "/fonts/Poppins/Poppins-Light.ttf",
      family: "Poppins",
      fontWeight: 300,
    },
    {
      src: "/fonts/Poppins/Poppins-Regular.ttf",
      family: "Poppins",
      fontWeight: 400,
    },
    {
      src: "/fonts/Poppins/Poppins-Medium.ttf",
      family: "Poppins",
      fontWeight: 500,
    },
    {
      src: "/fonts/Poppins/Poppins-SemiBold.ttf",
      family: "Poppins",
      fontWeight: 600,
    },
    {
      src: "/fonts/Poppins/Poppins-Bold.ttf",
      family: "Poppins",
      fontWeight: 700,
    },
  ],
});

async function generateQRDataURL(value) {
  try {
    const url = await QRCode.toDataURL(value);
    return url;
  } catch (err) {
    console.error(err);
  }
}

const styles = StyleSheet.create({
  page: {
    
    fontFamily: "Poppins",
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  printQR: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2mm",
    padding: "8mm",
  
  },
  image: {
    height: 80,
    width: 80,
    border: "2px solid #000",
    padding: "1px"

  },
  title: {
    fontSize: "13pt",
    fontWeight: "600",
    fontFamily: "Poppins",
    textAlign: "center"
  },
  text: {
    fontSize: "8pt",
    fontWeight: "500",
    fontFamily: "Poppins",
    textAlign: "center"
  }
});

const QrPDF = ({ selectedRestaurantId }) => {
 
  const [qrCodeURL, setQrCodeURL] = useState('');

  useEffect(() => {
    const generate = async () => {
      const url = await generateQRDataURL(`https://gudden.app/${selectedRestaurantId}/menu`);
      setQrCodeURL(url);
    };
    generate();
  }, [selectedRestaurantId]);

  return (
    <Document>
      <Page size={[148.82, 210.47]}  style={styles.page} wrap>
        <View style={styles.printQR}>
            <Image src={qrCodeURL} style={styles.image} width="2cm" />
            <Text style={styles.title}>Scan to view our menu</Text>
        </View>
      </Page>
    </Document>
  );
};

export default QrPDF;
