import React, { useState, useEffect, useRef, useMemo } from "react";
import useAllergenes from "../../hooks/useAllergenes";
import { useAppContext } from "../../contexts/AppContext";
import TagSelect from "./TagSelect";
import Select from "react-select";
import { Button, Flex, Input } from "@chakra-ui/react";
import { X, EllipsisVertical } from "lucide-react";
import { useMenuContext } from "../../contexts/MenuContext";

const ItemModal = ({
  categories,
  handleSubmit,
  onDuplicate,
  handleImageUpload,
  confirmAndDelete,
  handleDragOver,
  handleDrop,
  handleImageDelete,
  handleImageUploadClick,
  currentItem,
  setCurrentItem,
  closeEditModal,
  currentMainCategory,
  hasChanges,
  setHasChanges,
  toggleAvailability
}) => {
  const {
    toggleSubMenu,
    openSubMenu,
    closeSubMenu,
    showOverlay,
    settings,
    mainLanguage,
  } = useAppContext();

  const { tags, selectedTags, setSelectedTags } = useMenuContext();

  const [activeLanguageTab, setActiveLanguageTab] = useState(mainLanguage);
  useEffect(() => {
    setActiveLanguageTab(mainLanguage);
  }, [mainLanguage]);

  const [productType, setProductType] = useState(currentItem.type || "simple");


  const defaultVariations = [
    { name: "", price: "" },
    { name: "", price: "" },
  ];
  const [variations, setVariations] = useState(
    productType === "variable"
      ? defaultVariations
      : currentItem.variations || []
  );

  const allergenesWithUndefined = useAllergenes();

  const [selectedAllergenes, setSelectedAllergenes] = useState([]);

  const [allergenMode, setAllergenMode] = useState("Ask Waiter");

  const [selectedCategory, setSelectedCategory] = useState(
    currentItem.categorieId || ""
  );
  const selectedCategoryObject = categories.find(
    (cat) => cat.id === selectedCategory
  );

  const combinedLanguages = useMemo(() => {
    return [settings.language[0], ...settings.secondaryLanguages].filter(
      Boolean
    );
  }, [settings.language, settings.secondaryLanguages]);

  const handleAllergenModeChange = (newMode) => {
    setAllergenMode(newMode);
    if (newMode === "Ask Waiter") {
      setSelectedAllergenes(["ask waiter"]);
    } else if (newMode === "Define Allergens") {
      setSelectedAllergenes(
        selectedAllergenes.filter((allergene) => allergene !== "ask waiter")
      );
    }
    setHasChanges(true);
  };

  const toggleAllergene = (selectedAllergene) => {
    if (allergenMode !== "Define Allergens") return;

    setSelectedAllergenes((prevSelectedAllergenes) => {
      if (selectedAllergene === "ask waiter") {
        return prevSelectedAllergenes.includes("ask waiter")
          ? []
          : ["ask waiter"];
      }

      if (prevSelectedAllergenes.includes("ask waiter")) {
        const newSelectedAllergenes = ["ask waiter"].includes(selectedAllergene)
          ? []
          : [selectedAllergene];
        return newSelectedAllergenes;
      }

      const isAllergeneSelected =
        prevSelectedAllergenes.includes(selectedAllergene);
      return isAllergeneSelected
        ? prevSelectedAllergenes.filter(
            (allergene) => allergene !== selectedAllergene
          )
        : [...prevSelectedAllergenes, selectedAllergene];
    });
    setHasChanges(true);
  };

  useEffect(() => {
    setProductType(currentItem.type || "simple");
  }, [currentItem.type]);

  useEffect(() => {
    setSelectedAllergenes(currentItem.allergenes || []);

    if (
      currentItem.allergenes &&
      currentItem.allergenes.includes("ask waiter")
    ) {
      setAllergenMode("Ask Waiter");
      setSelectedAllergenes(["ask waiter"]);
    } else if (currentItem.allergenes && currentItem.allergenes.length > 0) {
      setAllergenMode("Define Allergens");
      setSelectedAllergenes(currentItem.allergenes);
    } else {
      setAllergenMode("Ask Waiter");
      setSelectedAllergenes(["ask waiter"]);
    }
    setVariations(currentItem.variations || []);
    setSelectedCategory(currentItem.categorieId || "");

    if (currentItem && currentItem.tags) {
      const updatedSelectedTags = currentItem.tags
        .map((tagInfo) => {
          const foundTag = tags.find((tag) => tag.id === tagInfo.value);
          return foundTag ? { ...tagInfo, color: foundTag.color } : null;
        })
        .filter((tag) => tag !== null);

      setSelectedTags(updatedSelectedTags);
    } else {
      setSelectedTags([]);
    }
  }, [currentItem, tags]);

  useEffect(() => {
    if (currentItem && currentItem.id) {
      const editItemModal = document.querySelector("#edit-item-modal");
      editItemModal.style.display = "flex";
      editItemModal.classList.add("active");
    }
  }, [currentItem]);

  useEffect(() => {
    if (productType === "variable") {
      if (currentItem.variations && currentItem.variations.length > 0) {
        setVariations(currentItem.variations);
      } else {
        setVariations(defaultVariations);
      }
    } else if (productType === "simple" && variations.length !== 0) {
      setVariations([]);
    }
  }, [productType, currentItem.variations]);

  useEffect(() => {
    if (currentItem.display) {
      const initialDisplayOptions = displayOptions.filter((option) =>
        currentItem.display.includes(option.value)
      );
      setSelectedDisplayOptions(initialDisplayOptions);
    } else {
      setSelectedDisplayOptions([]);
    }
  }, [currentItem.display]);

  const addVariation = () => {
    setVariations([...variations, { name: "", price: "" }]);
  };

  const handleVariationChange = (index, field, value) => {
    if (productType !== "variable") return;

    const updatedVariations = variations.map((variation, i) => {
      if (i === index) {
        return { ...variation, [field]: value };
      }
      return variation;
    });
    setVariations(updatedVariations);
    setHasChanges(true);
  };

  const handleDeleteVariation = (index) => {
    setVariations(variations.filter((_, i) => i !== index));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newItemState = {
      ...currentItem,
      [name]: value,
      variations: variations,
      allergenes: selectedAllergenes,
      display: selectedDisplayOptions.map((option) => option.value),
      tags: selectedTags,
    };

    if (name.startsWith("name_")) {
      setCurrentItem(newItemState);
    } else if (name.startsWith("price") && productType === "variable") {
      const index = parseInt(name.replace("price", ""), 10);
      const newVariations = variations.map((variation, idx) =>
        idx === index ? { ...variation, price: value } : variation
      );
      setVariations(newVariations);
      setCurrentItem({ ...newItemState, variations: newVariations });
    } else if (name === "price" && productType === "simple") {
      setCurrentItem(newItemState);
    } else {
      setCurrentItem(newItemState);
    }
    setHasChanges(true);
  };

  const handleCategoryChange = (newValue) => {
    setSelectedCategory(newValue);
    setCurrentItem({
      ...currentItem,
      categorieId: newValue,
    });
    handleInputChange({ target: { name: "categorieId", value: newValue } });
  };

  const handleVATChange = (selectedOption) => {
    setCurrentItem((prevCurrentItem) => ({
      ...prevCurrentItem,
      vat: parseFloat(selectedOption.value),
      variations: prevCurrentItem.variations, // Explicitement préserver les variations existantes
    }));
    setHasChanges(true); // Indiquer que des modifications ont été apportées
  };

  useEffect(() => {
    const editItemModal = document.querySelector("#edit-item-modal");
    const modalContent = editItemModal.querySelector(".modal-content");

    const handleAnimationEnd = () => {
      modalContent.classList.remove("modal-content-animating");
    };

    if (modalContent) {
      modalContent.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      if (modalContent) {
        modalContent.removeEventListener("animationend", handleAnimationEnd);
      }
    };
  }, []);

  const textareaRef = useRef(null);
  useEffect(() => {
    const adjustTextareaHeight = () => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };
    adjustTextareaHeight();
  }, [currentItem[`description_${activeLanguageTab}`], activeLanguageTab]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleCloseMouseDown = (e) => {
    if (e.target.id === "edit-item-modal") {
      closeEditModal();
      setActiveLanguageTab(settings.language[0].value); // Réinitialiser l'onglet de langue actif
    }
  };

  const displayOptions = useMemo(() => {
    const options = [{ value: "menu", label: "Menu" }];
    if (
      settings.shopSettings?.delivery?.activation ||
      settings.shopSettings?.takeAway?.activation
    ) {
      options.push({ value: "shop", label: "Shop" });
    }
    return options;
  }, [
    settings.shopSettings?.delivery?.activation,
    settings.shopSettings?.takeAway?.activation,
  ]);

  const [selectedDisplayOptions, setSelectedDisplayOptions] = useState([]);

  const handleDisplayChange = (selectedOptions) => {
    setCurrentItem((prevCurrentItem) => ({
      ...prevCurrentItem,
      display: selectedOptions.map((option) => option.value),
      variations: prevCurrentItem.variations, // Explicitement préserver les variations existantes
    }));
    setHasChanges(true); // Indiquer que des modifications ont été apportées
  };

  useEffect(() => {
    if (currentItem.display) {
      const initialDisplayOptions = displayOptions.filter((option) =>
        currentItem.display.includes(option.value)
      );
      setSelectedDisplayOptions(initialDisplayOptions);
    }
  }, [currentItem.display]);

  const isShopSelected = selectedDisplayOptions.some(
    (option) => option.value === "shop"
  );

  const handleCloseModal = () => {
    closeEditModal();
    setTimeout(() => {
      setActiveLanguageTab(mainLanguage);
    }, 1000); // Délai de 1 seconde
  };
  
  const handleSave = (e) => {
    handleSubmit(e, productType, variations, selectedAllergenes, selectedTags);
    setTimeout(() => {
      setActiveLanguageTab(mainLanguage);
    }, 2000);
  };
  


  const renderLanguageFields = (languageValue) => {
    return (
      <div className="fieldset">
        <div className="field">
          <input
            type="text"
            name={`name_${languageValue}`}
            id={`name_${languageValue}`}
            className="nameInput"
            placeholder="Item name"
            value={currentItem[`name_${languageValue}`] || ""}
            onChange={handleInputChange}
            required
          />
        </div>
        <textarea
          ref={textareaRef}
          name={`description_${languageValue}`}
          placeholder="Description"
          id={`description_${languageValue}`}
          className="descInput"
          value={currentItem[`description_${languageValue}`] || ""}
          onChange={handleInputChange}
        />
        {languageValue === settings.language[0].value ||
        currentItem[`note_${settings.language[0].value}`] ? (
          <div className="field">
            <label htmlFor={`note_${languageValue}`}>
              Note ({languageValue})
            </label>
            <input
              type="text"
              name={`note_${languageValue}`}
              id={`note_${languageValue}`}
              placeholder="Note"
              value={currentItem[`note_${languageValue}`] || ""}
              onChange={handleInputChange}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderCommonFields = () => {
    return (
      <>
        <div className="fieldset">
          <div className="row price-row">
            <div className="field">
              <label htmlFor="productType">Pricing type</label>
              <select
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                name="productType"
                id="productType">
                <option value="simple">Simple</option>
                <option value="variable">Variable</option>
              </select>
            </div>

            {productType === "simple" && (
              <>
                <div className="field price">
                  <label htmlFor="price">Price</label>
                  <input
                    type="text"
                    name="price"
                    id="price"
                    placeholder="Price"
                    value={currentItem.price}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}
          </div>
          {productType === "variable" && (
            <>
              {variations.map((variation, index) => (
                <Flex key={index} className="variation" flex={1} direction="row" alignItems={"center"} gap={4}>
                    <Flex className="field">
                      <label htmlFor={`variation${index}Name`}>
                        Variation {index + 1}
                      </label>
                      <Input
                        type="text"
                        value={variation.name ?? ""}
                        onChange={(e) =>
                          handleVariationChange(index, "name", e.target.value)
                        }
                        placeholder="Variation name"
                        id={`variation${index}Name`}
                        required
                      />
                    </Flex>
                    <Flex className="field price">
                      <label htmlFor={`variation${index}Price`}>Price</label>
                      <Input
                        type="text"
                        value={variation.price}
                        onChange={(e) =>
                          handleVariationChange(index, "price", e.target.value)
                        }
                        placeholder="Price"
                        id={`variation${index}Price`}
                        required
                      />
                    </Flex>
                    <Button
                      size="xs"
                      onClick={() => handleDeleteVariation(index)}>
                      <X size={16} />
                    </Button>
                </Flex>
              ))}
              <Button
                size="md"
                type="button"
                onClick={addVariation}
                className="btn secondary">
                Add variation
              </Button>
            </>
          )}

          </div><div className="fieldset">


          <div className="field">
            <label htmlFor="categorie" name="categorie">
              Category
            </label>
            <select
              value={selectedCategory}
              onChange={(e) => handleCategoryChange(e.target.value)}
              name="categorieId"
              id="categorie">
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {capitalizeFirstLetter(category[`name_${mainLanguage}`])}
                </option>
              ))}
            </select>
          </div>

          <div className="field">
            <label htmlFor="allergenMode">Allergens</label>
            <select
              value={allergenMode}
              onChange={(e) => handleAllergenModeChange(e.target.value)}
              name="allergenMode"
              id="allergenMode">
              <option value="Ask Waiter">Ask Waiter</option>
              <option value="Define Allergens">Define Allergens</option>
            </select>
          </div>

          {allergenMode === "Define Allergens" && (
            <div className="allergenes">
              {allergenesWithUndefined
                .filter((allergene) => allergene.name !== "ask waiter")
                .map((allergene) => (
                  <div key={allergene.id} className="allergene">
                    <input
                      type="checkbox"
                      id={allergene.id}
                      name={allergene.id}
                      checked={selectedAllergenes.includes(allergene.id)}
                      onChange={() => toggleAllergene(allergene.id)}
                    />
                    <label className="tag" htmlFor={allergene.id}>
                      <img
                        src={allergene.colorIcon}
                        alt={allergene.id}
                        className="icon"
                      />
                      {allergene.id}
                    </label>
                  </div>
                ))}
            </div>
          )}

          <Flex className="field tagsField">
            <label htmlFor="tags">Tags</label>
            <TagSelect
              key={currentItem.id || "new-item"}
              setHasChanges={setHasChanges}
              mainLanguage={mainLanguage}
            />
          </Flex>

          <Flex className="field displayField">
            <label htmlFor="display">Display</label>
            <Select
              id="display"
              isMulti
              options={displayOptions}
              value={selectedDisplayOptions}
              onChange={handleDisplayChange}
              className="customSelect-container"
              classNamePrefix="customSelect"
              isSearchable={false}
            />
          </Flex>

          {isShopSelected && (
            <div className="field vatField">
              <label htmlFor="vat">VAT</label>
              <Select
                id="vat"
                options={[
                  { value: "0.03", label: "3%" },
                  { value: "0.17", label: "17%" },
                ]}
                value={
                  currentItem.vat
                    ? {
                        label: `${currentItem.vat * 100}%`,
                        value: currentItem.vat,
                      }
                    : null
                }
                onChange={handleVATChange}
                className="customSelect-container"
                classNamePrefix="customSelect"
                isSearchable={false}
                required={isShopSelected}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <Flex
      direction="column"
      id="edit-item-modal"
      className="modal"
      style={{ display: "none" }}
      onMouseDown={handleCloseMouseDown}>
      <div
        className="modal-content"
        style={{ height: "calc(var(--vh, 1vh) * 100)" }}>
        <form
          className="itemForm"
          onSubmit={handleSave}>

          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={handleCloseModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>

            <div className="modalTitle">
              {currentItem.id
                ? `Edit ${currentItem[`name_${mainLanguage}`]}`
                : "Add an item"}
            </div>

            <div className="actionButtons">
              {hasChanges && (
                <button className="simple" type="submit">
                  {currentItem.id ? "Save changes" : "Save"}
                </button>
              )}

              {currentItem.id ? (
                <div
                  className="btn simple itemActions subMenuToggle"
                  onClick={toggleSubMenu("itemActions")}>
                  <EllipsisVertical size={20} color="var(--grey500)" />
                  {openSubMenu === "itemActions" && (
                    <div className="submenu">
                      <ul>
                      <li onClick={() => toggleAvailability(currentItem)}>
                                              {currentItem.availability ? 'Mark as unavailable' : 'Mark as available'}
                                            </li>
                      <li onClick={() => onDuplicate(currentItem)}>
                          Duplicate item
                        </li>
                        <li
                          onClick={() => confirmAndDelete(currentItem.id)}
                          style={{ color: "var(--error)" }}>
                          Delete item
                        </li>
                      </ul>
                      <button onClick={closeSubMenu} className="closeSubmenu">
                        Cancel
                      </button>
                    </div>
                  )}
                  {showOverlay && openSubMenu === "itemActions" && (
                    <div className="overlay" onClick={closeSubMenu}></div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {(currentMainCategory?.imageDisplay ||
            selectedCategoryObject?.style === "Cards") && (
            <div
              className="edit-image-dropzone"
              onDragOver={handleDragOver}
              onDrop={handleDrop}>
              <div className="dish-img">
                {currentItem?.photo ? (
                  <img
                    className="absolute-img"
                    src={currentItem.photo}
                    alt={currentItem.name}
                  />
                ) : (
                  <p>Drag an image here</p>
                )}
              </div>
              <Flex
                className="upload-wrap"
                direction="row"
                justifyContent={"center"}>
                <Button
                  className="btn upload primary"
                  type="button"
                  onClick={handleImageUploadClick}>
                  Browse files
                </Button>
                <input
                  type="file"
                  id="image-upload"
                  style={{ display: "none" }}
                  accept=".jpg, .jpeg, .png, .webp, .heic"
                  onChange={handleImageUpload}
                />
                {currentItem.photo && (
                  <Button
                    className="btn error"
                    type="button"
                    onClick={handleImageDelete}>
                    Delete picture
                  </Button>
                )}
              </Flex>
            </div>
          )}

          {combinedLanguages.length > 1 && (
            <div className="languageTabs">
              {combinedLanguages.map((lang, index) => (
                <div
                  key={index}
                  className={`languageTab ${
                    activeLanguageTab === lang.value ? "active" : ""
                  }`}
                  onClick={() => setActiveLanguageTab(lang.value)}>
                  {lang.label}
                </div>
              ))}
            </div>
          )}

          {activeLanguageTab === settings.language[0].value && (
            <>
              {renderLanguageFields(settings.language[0].value)}
              {renderCommonFields()}
            </>
          )}

          {settings.secondaryLanguages
            .map((lang) => lang.value)
            .includes(activeLanguageTab) &&
            renderLanguageFields(activeLanguageTab)}
        </form>
      </div>
    </Flex>
  );
};

export default ItemModal;
