import { Flex } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { MenuProvider } from "../contexts/MenuContext";
import { RecipeProvider } from "../contexts/RecipeContext";
import { useAppContext } from "../contexts/AppContext";

function RecipesLayout({ children }) {
  const { selectedRestaurantId, userRole, menuAdminBarZIndex, settings } = useAppContext();

  return (
    <MenuProvider>
      <RecipeProvider>
        {settings.modules.ratio === true ? (
        <><Flex
          justifyContent="space-between"
          direction="row"
          w="100%"
          alignItems="center"
          position="sticky"
          top="0"
          bg="white"
          pl={8}
          pr={8}
          maxWidth="100vw"
          borderBottom="1px solid var(--borders)"
          minHeight="45px"
          style={{ zIndex: menuAdminBarZIndex }}>
          <Flex className="tabLinks">
            <NavLink
              className="tabLink"
              end
              to={`/${selectedRestaurantId}/recipes`}>
              Recipes
            </NavLink>
            {userRole === "superAdmin" && 
            <NavLink
              className="tabLink"
              to={`/${selectedRestaurantId}/recipes/stock`}>
              Stock
            </NavLink>
            }
          </Flex>
        </Flex>

        <Flex
        padding={{ desktop: "3vw 3vw 124px 3vw", mobile: "0 0 172px 0" }}
        gap={8}
        maxW="1320px"
        direction={{ desktop: "row", mobile: "column" }}
      >
        
        {children}
        </Flex>
        </>
        ) : ( 
          null
        )}
      </RecipeProvider>
    </MenuProvider>
  );
}
export default RecipesLayout;
