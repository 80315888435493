import { useAppContext } from "../../../contexts/AppContext";
import {
  Flex,
  Text,
  Button,
  Link,
  Input,
  Switch
} from "@chakra-ui/react";

const ReservationsSettings = ({ onChange }) => {
  
  
  const { selectedRestaurantId, settings, setSettings, userRole } = useAppContext();

  return (
    <>
      {userRole === "superAdmin" ? (
        <>
          <Flex
            direction="column"
            flexGrow="1"
            minW="0"
            gap={{ desktop: 8, mobile: 0 }}>
            <Flex direction="column" gap={4} className="widget">
              <h5>General</h5>

              <Flex className="field">
                <label>Service duration (in minutes)</label>
                <Input
                  type="number"
                  name="reservationsSettings.serviceDuration"
                  value={settings.reservationsSettings?.serviceDuration}
                  onChange={onChange}>
                  </Input>
              </Flex>

              <Flex
                    directionm="row"
                    alignItems="center"
                    gap={2}
                    >
                    <h6>Auto Confirm reservations</h6>
                    <Switch
                      id="autoconfirm-switch"
                      isChecked={settings.reservationsSettings?.autoConfirm || false} // Assurez-vous que `settings.modules` est défini
                      onChange={(e) => {
                        setSettings((prevSettings) => ({
                          ...prevSettings,
                          reservationsSettings: {
                            ...prevSettings.reservationsSettings,
                            autoConfirm: e.target.checked,
                          },
                        }));
                      }}
                    />
                  </Flex>
              
            </Flex>
           
          </Flex>

          {/* SIDEBAR */}
         

          <Flex
            flex={{ desktop: "0 0 320px", mobile: "1" }}
            direction="column"
            gap={{ desktop: 8, mobile: 0 }}>
            
              
            
          </Flex>
        </>
      ) : (
        <Flex>
          <Text>Reservations module is not activated.</Text>
          <Link
            href={`mailto:it@goodrobot.lu?subject=Reservations module activation request for ${selectedRestaurantId}`}>
            <Button size="lg" className="primary">
              Contact us to activate it.
            </Button>
          </Link>
        </Flex>
      )}
    </>
  );
};

export default ReservationsSettings;
