import React, { useState, useEffect } from "react";
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from "firebase/firestore";
import { useAppContext } from "../contexts/AppContext";
import { Flex, Table } from '@chakra-ui/react';

const Customers = ({ isLoggedIn, userRole }) => {
    const { selectedRestaurantId } = useAppContext();
    const [customers, setCustomers] = useState([]);

    console.log(selectedRestaurantId);

    useEffect(() => {
        if (!selectedRestaurantId) return;

        const fetchCustomersAndOrders = async () => {
            const ordersRef = query(collection(db, `restaurants/${selectedRestaurantId}/orders`), where("status", "==", "completed"));

            const ordersSnapshot = await getDocs(ordersRef);
            const fetchedOrders = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Grouper les commandes par email du client
            const ordersByCustomerEmail = fetchedOrders.reduce((acc, order) => {
                const email = order.customerDetails.email; // Ajusté pour accéder à l'email correctement
                (acc[email] = acc[email] || []).push(order);
                return acc;
            }, {});
            

            // Transformer le groupement en tableau d'objets client avec leurs commandes
            const enrichedCustomers = Object.entries(ordersByCustomerEmail).map(([email, orders]) => {
                const totalSpent = orders.reduce((sum, order) => sum + parseFloat(order.total.replace(',', '.')), 0);
                const averageSpent = orders.length > 0 ? (totalSpent / orders.length).toFixed(2) : "0";

                return {
                    email: orders[0].customerDetails.email,
                    numberOfOrders: orders.length,
                    totalSpent: totalSpent.toFixed(2).replace('.', ','),
                    averageSpent: averageSpent.replace('.', ','),
                    // On suppose que les infos du client sont cohérentes à travers les commandes
                    firstName: orders[0].customerDetails.firstName,
                    lastName: orders[0].customerDetails.lastName,
                    phone: orders[0].customerDetails.phone
                };
            });

            setCustomers(enrichedCustomers);
        };

        fetchCustomersAndOrders();
    }, [selectedRestaurantId]);

    return (
        <Flex direction="column" padding={{ desktop: "3vw 3vw 124px 3vw", mobile: "0 0 172px 0" }} gap={8} maxW="1320px">
           
            <Flex w='100%' className="widget" direction="column" gap={8}>
            <h3>Customers</h3>
                {customers.length > 0 ? (
                    <Table w='100%'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Number of orders</th>
                                <th>Total spent</th>
                                <th>Average</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customers.map(({ firstName, lastName, email, phone, numberOfOrders, totalSpent, averageSpent }, index) => (
                                <tr key={index}>
                                    <td>{firstName} {lastName}</td>
                                    <td>{email}</td>
                                    <td>{phone}</td>
                                    <td>{numberOfOrders}</td>
                                    <td>{totalSpent}€</td>
                                    <td>{averageSpent}€</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <p>No customers found.</p>
                )}
            </Flex>
        </Flex>
    );
};

export default Customers;
