import React, { useState } from 'react';
import Modal from 'react-modal';
import { db, storage } from '../../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import useModal from '../../hooks/useModal';
import Select from 'react-select';
import useLanguages from '../../hooks/useLanguages';


Modal.setAppElement('#root');

const CreateRestaurantModal = ({ isOpen, onRequestClose, onRestaurantAdded }) => {

  const [restaurantName, setRestaurantName] = useState('');
  const [menuLogo, setMenuLogo] = useState('');
  const [shopLogo, setShopLogo] = useState('');
  const [imageDisplay, setImageDisplay] = useState('false'); // valeur par défaut à 'false'
  const {customStyles} = useModal();
  
  const {primaryLanguage, setPrimaryLanguage, secondaryLanguages, setSecondaryLanguages, languages} = useLanguages('');
  const secondaryLanguageOptions = languages.filter(lang => lang.value !== primaryLanguage?.value);



    const handleImageUpload = async (e, setImage) => {
      const file = e.target.files[0];
      if (file) {
          const storageRef = ref(storage, `logos/${file.name}`);
          const uploadResult = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadResult.ref);
          setImage(imageUrl);
      }
  };


  const canSubmit = restaurantName.trim() !== '' && primaryLanguage;
  
  const handleRestaurantSubmit = async (e) => {
    e.preventDefault();
  
      if (!primaryLanguage || !restaurantName.trim()) {
      toast.error('Please fill in all required fields');
      return;
    }
  
    try {
      const restaurantId = restaurantName.trim().toLowerCase().replace(/\s+/g, '-');
      const defaultOpeningHours = [
        { day: 'Monday', slots: [{ start: '12:00', end: '14:00' }, { start: '19:00', end: '22:00' }] },
        { day: 'Tuesday', slots: [{ start: '12:00', end: '14:00' }, { start: '19:00', end: '22:00' }] },
        { day: 'Wednesday', slots: [{ start: '12:00', end: '14:00' }, { start: '19:00', end: '22:00' }] },
        { day: 'Thursday', slots: [{ start: '12:00', end: '14:00' }, { start: '19:00', end: '22:00' }] },
        { day: 'Friday', slots: [{ start: '12:00', end: '14:00' }, { start: '19:00', end: '22:00' }] },
        { day: 'Saturday', slots: [{ start: '12:00', end: '14:00' }, { start: '19:00', end: '22:00' }] },
        { day: 'Sunday', slots: [{ start: '12:00', end: '14:00' }, { start: '19:00', end: '22:00' }] },
      ];
  
      const restaurantRef = doc(db, `restaurants/${restaurantId}`);
      await setDoc(restaurantRef, { 
      name: restaurantName,
      openingHours: defaultOpeningHours,
      address: '',
      phoneNumber:'',
      website: '',
      });
  
      const settingsData = {
        logo: [{
          menu: menuLogo,
          shop: shopLogo,
        }],
        imageDisplay,
        language: [primaryLanguage],
        secondaryLanguages,
        brandColor: '', 
        backgroundColor: '', 
        textColor: '', 
        backgroundImage: '', 
        menuUrl: '',
        modules: {
          ratio: false,
          reservations: false,
          staff: false,
        },
        shopSettings: {
          takeAway: {
            activation: false,
            minimumOrderAmount: 0,
          },
          delivery: {
            activation: false,
            minimumOrderAmount: 0,
            zone: [],
          },
          contact: {
            shopManagerEmail: '',
            phoneNumber:'',
            address: '',
            companyName: '',
            vat:'',
            rcs: '',
          },
          paymentMethods: {
            till: {
              id: "till",
              label: "Pay upon pickup",
              activation: false,
              order: 0
            },  
            online: {
              id: "online",
              label: "Pay online by credit card",
              activation: false,
              order: 1
            },
          },
          fees: {
            commissionFee: '',
          },
        },
      };
  
      const settingsRef = doc(db, `restaurants/${restaurantId}/settings`, 'config');
      await setDoc(settingsRef, settingsData);
  
      const names = {
        [`name_${primaryLanguage.value}`]: 'Menu',
        ...secondaryLanguages.reduce((acc, lang) => ({
          ...acc,
          [`name_${lang.value}`]: 'Menu'
        }), {})
      };

      const mainCategoryData = {
        ...names,
        imageDisplay: true,
        published: false,
        menuIcon: '🍝',
        order: 0,
      };
      const mainCategoryRef = doc(db, `restaurants/${restaurantId}/menu/food`);
      await setDoc(mainCategoryRef, mainCategoryData);
  
      if (onRestaurantAdded) {
        onRestaurantAdded(restaurantId);
      }
  
      toast.success('Restaurant created successfully');
      onRequestClose();
    } catch (error) {
      toast.error('Error creating restaurant');
    }
  };
  
  


  const handleChange = (setterFunction) => (e) => {
    setterFunction(e.target.value);
  };




  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className={'modal'} style={customStyles}>
            <div className="modal-content">

      <form onSubmit={handleRestaurantSubmit}>
        <div className="modalTopBar"> 
          <div className="closeModal">
          <span className="close" onClick={onRequestClose}>
  
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z" fill="black"/>
          </svg>  
          </span>
          </div>

          <div className="modalTitle">Add restaurant</div>

          <div className="actionButtons">
          
          {canSubmit && (
            <button className="simple" type="submit">Save</button>
          )}
          
          </div>
        </div>
                  
                  <div className='modalInner'>
                    <div className='field'>
                      <label>Name</label>
                      <input 
                        type="text" 
                        value={restaurantName} 
                        onChange={handleChange(setRestaurantName)}
                        required 
                      />
                    </div>
                    <div className='field'>
                      <label>Menu logo</label>
                      <input type="file" onChange={(e) => handleImageUpload(e, setMenuLogo)} />
                    </div>
                    <div className='field'>
                      <label>Shop logo (if different)</label>
                      <input type="file" onChange={(e) => handleImageUpload(e, setShopLogo)} />
                    </div>
                    <div className='field'>
                    <label>Image Display</label>
                    <select 
                        value={imageDisplay} 
                        onChange={handleChange(setImageDisplay)}>
                        <option value="true">True</option>
                        <option value="false">False</option>
                    </select>
                </div>                
                <div className='field'>
        <label>Primary language</label>
        <Select
          value={primaryLanguage}
          onChange={newValue => setPrimaryLanguage(newValue)}
          options={languages}
        />
      </div>
      <div className='field'>
        <label>Secondary languages</label>
        <Select
          isMulti
          value={secondaryLanguages}
          onChange={setSecondaryLanguages}
          options={secondaryLanguageOptions}
          />
      </div>
                  
                    </div>
                  </form>
                  </div>
       
    </Modal>
  );
};

export default CreateRestaurantModal;
