import { useAppContext } from "../contexts/AppContext";
import { NavLink } from "react-router-dom";
import { Flex } from '@chakra-ui/react';

function ShopLayout({ children, userRole }) {
    
  const {selectedRestaurantId, menuAdminBarZIndex, settings } = useAppContext();

  
    return (
      <>
      {settings.shopSettings.delivery.activation === true || settings.shopSettings.takeAway.activation === true ? (


      <>
      
      <Flex
        justifyContent="space-between"
        direction="row"
        w="100%"
        alignItems="center"
        position="sticky"
        top="0"
        bg="white"
        pl={8}
        pr={8}
        borderBottom="1px solid var(--borders)"
        style={{ zIndex: menuAdminBarZIndex }}  
      >
       <Flex direction="row" className="tabLinks">
             <NavLink to={`/${selectedRestaurantId}/shop/orders`} className="tabLink">Orders</NavLink>
             <NavLink to={`/${selectedRestaurantId}/shop/customers`} className="tabLink">Customers</NavLink>
             {settings?.shopSettings?.fees?.commissionFee > 0 &&
             <NavLink to={`/${selectedRestaurantId}/shop/invoicing`} className="tabLink">Invoicing</NavLink>
              }
      </Flex>
      </Flex>
      <Flex direction="column" gap={8}  >
        
      {children}
      </Flex> </>
      ): (null)}
      </>
    );
  }
export default ShopLayout  