import { useEffect } from "react";
import { db } from "../../../firebaseConfig";
import { getDocs, collection, query, where } from 'firebase/firestore';
import UserModal from "./UserModal";
import useUsers from '../../../hooks/useUsers';
import Spinner from "../../icons/Spinner";
import { Flex, Box, Button, Table, Thead, Th, Tr, Td } from "@chakra-ui/react";
import { useAppContext } from "../../../contexts/AppContext";


const Users = ({ selectedRestaurantId }) => {
    const {
      userRole
    } = useAppContext();

    const { 
        users,
        setUsers,
        currentUser,
        setCurrentUser,
        addUserFromCloud,
        handleSaveUser,
        deleteUserFromCloud,
        isUserModalOpen,
        setIsUserModalOpen,
        isDeletingUser,
        isAddingUser,
        closeUserModal,
        userHasChanges,
        setUserHasChanges
      } = useUsers(selectedRestaurantId);


      
      useEffect(() => {
        const fetchUsers = async () => {
            if (selectedRestaurantId) {
                const q = query(collection(db, 'users'), where("restaurantIds", "array-contains", selectedRestaurantId));
                const usersSnapshot = await getDocs(q);
                const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersList);
            }
        };
        fetchUsers();
    }, [selectedRestaurantId]);
    


    const handleUserEdit = (user) => {
      setCurrentUser(user);
      setIsUserModalOpen(true);
  };

    const handleAddUser = () => {
      setCurrentUser(null);
      setIsUserModalOpen(true);
    };



  
return (
    
<>
    {isDeletingUser && (
      <div>
        <Spinner />
      </div>
    )}
    {isAddingUser && (
      <div>
        <Spinner />
      </div>
    )}
          <Flex direction='column' gap={8} className="widget" flex={1} h="fit-content">
            <Flex direction="row" gap={4}>
            <h2>Users</h2>
              <div className='buttons'>
              <Button size="sm" className="btn secondary" onClick={handleAddUser}>Add a user</Button>
              </div>
            </Flex>
              
              <Table>
                <Thead>
                  <Th>Name</Th>
                  <Th>Email address</Th>
                  {userRole === 'superAdmin' && <Th>Last Login</Th>} {/* Nouvelle colonne */}
                </Thead>
                {users.map(user => (
                  <Tr direction="row" p={2} className='user' key={user.id} onClick={() => handleUserEdit(user)}>
                    <Td>{user.name} {user.lastName}</Td>
                    <Td>{user.email}</Td>
                    {userRole === 'superAdmin' && (
                    <Td>
                      <Box className="tag" textTransform="initial">
                        {user.lastLogin ? 
                          new Date(user.lastLogin.seconds * 1000).toLocaleString("en-GB", {
                            year: 'numeric', month: 'short', day: 'numeric', 
                            hour: '2-digit', minute: '2-digit', hour12: false
                          }).replace(',', ' at') : 'N/A'}
                      </Box>

                    </Td>
                  )}

                  </Tr>
                ))}
                </Table>
          </Flex>
          <UserModal 
                user={currentUser} 
                isOpen={isUserModalOpen} 
                onClose={closeUserModal} 
                onSave={currentUser ? handleSaveUser : addUserFromCloud}
                onDelete={deleteUserFromCloud}
                addUser={addUserFromCloud}
                userHasChanges={userHasChanges}
                setUserHasChanges={setUserHasChanges}
          />
        </>
  );
};

export default Users;

