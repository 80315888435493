import React, { useState, useEffect } from 'react';
import { Flex, Button, Table, Tbody, Tr, Td, Box, Image, useDisclosure } from "@chakra-ui/react";
import useOrders from "../../hooks/useOrders";
import { useAppContext } from "../../contexts/AppContext";
import { useNavigate } from 'react-router-dom';
import CancellationModal from './CancellationModal';


const PendingOrders = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectedRestaurantId, mainLanguage } = useAppContext(); // Assurez-vous que ce contexte fournit `selectedRestaurantId`
  const { pendingOrders, updateOrderStatus, cancellationNote, setCancellationNote } = useOrders();
  const { formatScheduledTime } = useAppContext();
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const handleOrderClick = (orderId) => {
    navigate(`/${selectedRestaurantId}/shop/orders`, { state: { orderId } });
  };
  

  const promptCancelOrder = (orderId) => {
    setSelectedOrderId(orderId);
    setCancellationNote(''); // Réinitialiser la note d'annulation
    onOpen();
  };

  const handleCancelOrder = () => {
    if (selectedOrderId) {
      updateOrderStatus(selectedOrderId, "cancelled", null, cancellationNote);
      onClose();
    }
};

  
  const handleAddTimeToOrder = (orderId, minutes) => {
    const order = pendingOrders.find(order => order.id === orderId);
    if (order && order.scheduledTime) {
      const updatedTime = new Date(order.scheduledTime.toDate().getTime() + minutes * 60000);
      updateOrderStatus(orderId, "confirmed", updatedTime);
    }
  };
  
  
  const handleConfirmOrder = (orderId) => {
    updateOrderStatus(orderId, "confirmed");
  };
  

  return (
    <Flex
      className="ordersNotifications"
      direction="column"
      position={"fixed"}
      top="65px"
      right="0"
      height="calc(100vh - 65px)"
      zIndex="10"
      p={{ desktop: 4, mobile: 0 }}
      gap={2}
      overflowY={"scroll"}
      
      >
      {pendingOrders.length > 0 &&
        pendingOrders.map((order) => (
          <Flex
            onClick={() => handleOrderClick(order.id)} // Attacher ici
            direction="column"
            key={order.id}
            w="320px"
            bg="white"
            borderRadius="8px"
            border="1px solid var(--borders)"
            p={4}
            gap={4}
            boxShadow= "rgba(149, 157, 165, 0.2) 0px 8px 24px">
            <Flex direction="row" justifyContent="space-between" alignItems="baseline">
            <h6>New order #{order.id}</h6>
            <Box className="tag">{order.scheduledTime ? formatScheduledTime(order.scheduledTime.toDate()) : "N/A"}</Box>
            </Flex>
            <Table size="sm">
                <Tbody>
                  {order.cartItems.map((item, index) => (
                    <Tr key={index}>
                      <Td pl={0}>
                        <Flex gap={4} alignItems="flex-start">
                          <Box background="var(--grey100)" borderRadius={8} overflow="hidden" w="32px" h="32px" minWidth="32px" flex="0 0 32px">
                            {item.photo && 
                            <Image src={item.photo} aspectRatio="1/1" objectFit="cover" objectPosition="center" w="32px" h="32px"/>
                            }
                          </Box>
                          <Flex direction="column" gap={1}>
                          <Box fontWeight={"500"}>{item[`name_${mainLanguage}`]}</Box>
                          {item.extras && item.extras.length > 0 && (
                          <Box className="extrasList" fontSize={"12px"}>
                            <p>Extras</p>
                            {item.extras.map((extra, extraIndex) => (
                              <span key={extraIndex} className="extra">
                                {extra.name}
                                {extraIndex < item.extras.length - 1 && (
                                  <span> - </span>
                                )}
                              </span>
                            ))}
                          </Box>
                        )}
                        {item.note && (
                          <>
                            <p>Note</p> <mark>{item.note}</mark>
                          </>
                        )}
                          </Flex>
                        </Flex>
                      </Td>
                      <Td isNumeric pr={0}>{item.quantity}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            <Flex gap="2">
            <Button size="sm" bg="var(--error)" color="white" _hover={{ backgroundColor: "var(--error700)" }} onClick={(e) => { e.stopPropagation(); promptCancelOrder(order.id); }}>Decline</Button>
            <Button size="sm" bg="var(--success)" color="white" _hover={{ backgroundColor:"var(--success700)" }} onClick={(e) => { e.stopPropagation(); handleConfirmOrder(order.id)}}>Accept</Button>
            <Button size="sm" colorScheme="blue" onClick={(e) => { e.stopPropagation(); handleAddTimeToOrder(order.id, 15)}}>+15</Button>
            <Button size="sm" colorScheme="blue" onClick={(e) => { e.stopPropagation(); handleAddTimeToOrder(order.id, 30)}}>+30</Button>
          </Flex>
          </Flex>
        ))}
        <CancellationModal 
            isOpen={isOpen} 
            onClose={onClose}
            cancellationNote={cancellationNote}
            setCancellationNote={setCancellationNote}
            handleConfirmCancellation={handleCancelOrder}
        />
    </Flex>
  );
};

export default PendingOrders;
